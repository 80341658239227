import { CLEAR_ERRORS, CREATE_PRODUCT_FAIL, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, EDIT_PRODUCT_FAIL, EDIT_PRODUCT_REQUEST, EDIT_PRODUCT_SUCCESS, GET_PRODUCT_BY_ID_REQUEST, GET_PRODUCT_BY_ID_SUCCESS, GET_PRODUCT_BY_ID_FAIL, SEARCH_PRODUCT_QUERY_REQUEST, SEARCH_PRODUCT_QUERY_SUCCESS, SEARCH_PRODUCT_QUERY_FAIL } from "../constants/productConstants";

export const loadProductByIdReducer = (state = { product: {} }, action: any) =>{
    switch(action.type){
        case GET_PRODUCT_BY_ID_REQUEST:
            return{
                ...state,
                productByIdLoading: true,
                productByIdError: null
            }
        case GET_PRODUCT_BY_ID_SUCCESS:
            return{
                ...state,
                productByIdLoading: false,
                product: action.payload
            }
        case GET_PRODUCT_BY_ID_FAIL:
            return{
                ...state,
                productByIdLoading: false,
                productByIdError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createProductReducer = (state = { createProductMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_PRODUCT_REQUEST:
            return{
                ...state,
                createProductLoading: true,
                createProductError: null
            }
        case CREATE_PRODUCT_SUCCESS:
            return{
                ...state,
                createProductLoading: false,
                createProductMessage: action.payload
            }
        case CREATE_PRODUCT_FAIL:
            return{
                ...state,
                createProductLoading: false,
                createProductError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const editProductReducer = (state = { editProductMessage: {} }, action: any) =>{
    switch(action.type){
        case EDIT_PRODUCT_REQUEST:
            return{
                ...state,
                editProductLoading: true,
                editProductError: null
            }
        case EDIT_PRODUCT_SUCCESS:
            return{
                ...state,
                editProductLoading: false,
                editProductMessage: action.payload
            }
        case EDIT_PRODUCT_FAIL:
            return{
                ...state,
                editProductLoading: false,
                editProductError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteProductReducer = (state = { deleteProductMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_PRODUCT_REQUEST:
            return{
                ...state,
                deleteProductLoading: true,
                deleteProductError: null
            }
        case DELETE_PRODUCT_SUCCESS:
            return{
                ...state,
                deleteProductLoading: false,
                deleteProductMessage: action.payload
            }
        case DELETE_PRODUCT_FAIL:
            return{
                ...state,
                deleteProductLoading: false,
                deleteProductError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const searchProductQueryReducer = (state = { searchProductQueryMessage: {} }, action: any) =>{
    switch(action.type){
        case SEARCH_PRODUCT_QUERY_REQUEST:
            return{
                ...state,
                searchProductQueryLoading: true,
                searchProductQueryError: null
            }
        case SEARCH_PRODUCT_QUERY_SUCCESS:
            return{
                ...state,
                searchProductQueryLoading: false,
                searchProductQueryMessage: action.payload
            }
        case SEARCH_PRODUCT_QUERY_FAIL:
            return{
                ...state,
                searchProductQueryLoading: false,
                searchProductQueryError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}