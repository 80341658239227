import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import IntelikartDashboardLogo from '../../images/IntelikartDashboardLogo.png';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { IonMenu, 
  IonHeader,
  IonIcon, 
  IonImg, 
  IonToolbar, 
  IonTitle, 
  IonContent, 
  IonList, 
  IonItem, 
  IonLabel, 
  IonMenuToggle,
  IonListHeader,
  IonAlert
} from '@ionic/react';
import {
  home as homeIcon, 
  cart as cartIcon, 
  albums as albumsIcon, 
  people as peopleIcon,
  pricetags as pricetagsIcon,
  image as imageIcon,
  print as printIcon,
  peopleCircle as peopleCircleIcon,
  settings as settingsIcon,
  helpCircle as helpCircleIcon,
  logOut as logOutIcon,
  tv as tvIcon,
  fileTrayFull as fileTrayFullIcon,
  calendarOutline,
  peopleOutline,
  mapOutline,
  informationCircleOutline,
  person,
  help,
  logOut,
  logIn,
  personAdd,
  homeOutline,
  schoolOutline,
  personOutline,
  helpOutline,
  logOutOutline,
  pricetagOutline,
  imageOutline,
  printOutline,
  helpCircleOutline,
  settingsOutline,
  appsSharp,
  listOutline
} from 'ionicons/icons';
import "./Menu.css"
const DashboardMenu = () => {
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const [showAlert,setShowAlert]=useState(false)
    const location = useLocation();
    const history = useHistory();

    const routes = {
      appPages: [
        { title: 'Home', path: '/my/userdashboard', icon: homeOutline },
        { title: 'Customers', path: '/my/customer', icon: peopleOutline },
        { title: 'Catalogue', path: '/my/catalogue', icon: appsSharp },
        { title: 'Orders', path: '/my/orders', icon: listOutline },
        { title: 'Coupons', path: '/my/coupons', icon: pricetagOutline },
        { title: 'Banners', path: '/my/banners', icon: imageOutline },
        { title: 'Marketing', path: '/my/marketing', icon: printOutline },
        { title: 'Staff', path: '/my/staffs', icon: peopleOutline }
      ]
      ,
      loggedInPages: [
        { title: 'Subscriptions', path: '/my/subscriptions', icon: personOutline },
        { title: 'Settings', path: '/my/settings', icon: settingsOutline },
        { title: 'IK Academy', path: '/my/ikacademy', icon: schoolOutline },
        { title: 'Help & Support', path: '/my/help&support', icon: helpCircleOutline }
      ]
      ,
      loggedOutPages: [
        { title: 'Logout', path: '/logout', icon: logOutOutline },
      ]
    };
    
    interface Pages {
      title: string,
      path: string,
      icon: string,
      routerDirection?: string
    }

    function renderlistItems(list: Pages[]) {
      return list
        .filter(route => !!route.path)
        .map(p => (
          <IonMenuToggle key={p.title} auto-hide="false">
            <IonItem detail={false} routerLink={p.path} routerDirection="none" className={location.pathname.startsWith(p.path) ? 'selected' : undefined}>
              <IonIcon slot="start" icon={p.icon} />
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
          </IonMenuToggle>
        ));
    }
    return (
      <IonMenu  type="overlay" contentId="main">
          <IonHeader>
          <IonToolbar>
            <IonTitle>
            <h5>{currentUser && currentUser.data && currentUser.data[0] ? `${currentUser.data[0].email}` : ""}</h5>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      <IonContent>
        <IonList lines="none">
          <IonListHeader>Store</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Settings</IonListHeader>
          {renderlistItems(routes.loggedInPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Others</IonListHeader>
          <IonMenuToggle key='LOGOUT' auto-hide="false">
            <IonItem detail={false} onClick={e=>setShowAlert(true)}  style={{cursor:'pointer'}} routerDirection="none" className={location.pathname.startsWith('/logout') ? 'selected' : undefined} >
                <IonIcon slot="start" icon={logOutOutline} />
                <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
          {/* {renderlistItems(routes.loggedOutPages)} */}
        </IonList>
      </IonContent>
      <IonAlert
        isOpen={showAlert === true}
        onDidDismiss={() => setShowAlert(false)}
        header={'Confirm Log Out'}
        message={`Are you sure you want to log out?`}
        buttons={[
            {
                text: 'NO',
                role: 'cancel'
            },
            {
                text: 'YES',
                handler: () => { history.push('/logout') }
            }
        ]}
      />
    </IonMenu>
    )
}

export default DashboardMenu
