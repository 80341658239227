import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonSegment, IonSegmentButton, IonLabel, IonText, IonCard, IonPage } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getAcademyContent } from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { useHistory } from 'react-router';

const IKAcademy = () => {
  const [content, setContent] = useState<string | undefined>("VIDEOS");
  const dispatch = useDispatch();
  const history = useHistory();
  const { academyContentLoading, academyContent, academyContentError } = useSelector((state: any) => state.academyContent);

  useEffect(() => {
    (async function () {
      const accessToken = await getAccessToken();
      if(accessToken){
        await dispatch(getAcademyContent(accessToken));
      }else{
        await dispatch(authenticateUserWhenLogout())
        await localStorage.clear();
        history.push('/')
      }
    })();
    if (academyContentLoading === false && academyContentError)
      console.log(academyContentError);
  }, [content])

  const getVideoId = (url: string) => {
    console.log(url);
    let index = url.indexOf("?v=");
    console.log('index = ' + index);

    if (index !== -1) {
      let ind = url.lastIndexOf("&");
      if (ind !== -1) {
        console.log(url.slice(index + 3, ind));
        return url.slice(index + 3, ind);
      }
      console.log(url.slice(index + 3, url.length));
      return url.slice(index + 3, url.length);
    } else {
      let i = url.lastIndexOf("/");
      let ind = url.lastIndexOf("?");
      if (ind !== -1) {
        console.log(url.slice(i + 1, ind));
        return url.slice(i + 1, ind);
      }
      console.log(url.slice(i + 1, url.length));
      console.log('i = ' + i);
      return url.slice(i + 1, url.length);
    }
  }
  return (
    <IonPage>
      <div className="ion-page" id="main-content">
        <Header name="IK Academy" />
        <IonContent className="ion-padding">
          <IonSegment onIonChange={e => setContent(e.detail.value)} value={content}>
            <IonSegmentButton value="VIDEOS">
              <IonLabel>VIDEOS</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="BLOGS">
              <IonLabel>BLOGS</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {academyContent && academyContent.data && content === 'VIDEOS' ?
            academyContent.data.map((value: any) => {
              if (value.type === 'YOUTUBE_EMBED')
                return (<IonCard key={value.id} className="ion-text-center ion-no-padding" >
                  <iframe src={`https://www.youtube.com/embed/${getVideoId(value.link)}?enablejsapi=1&origin=http%3A%2F%2Flocalhost%3A8100&widgetid=2`} height="250" width="100%" title="Iframe Example"></iframe>
                </IonCard>);
            }) : null
          }

          {academyContent && academyContent.data && content === 'BLOGS' ?
            academyContent.data.map((value: any) => {
              if (value.type === 'LINKS')

                return (<IonCard key={value.id} className="ion-padding">
                  <IonText>
                    <h5 className="ion-no-margin ion-margin-bottom">{value.text}</h5>
                    {value.description}
                  </IonText>
                  <br /><br />
                  <a href={value.link} target="_blank">Read more</a>
                </IonCard>);
            }) : null
          }
        </IonContent>
      </div>
    </IonPage>
  )
}

export default IKAcademy
