import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from './services/auth/userLocalStorage';
import { authenticateUserWhenLogin, getCurrentUser } from './actions/authActions';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Home from './auth/Home';
import GetBusinessDetails from './auth/GetBusinessDetails';
import DashboardMenu from './components/dashboard/DashboardMenu';
import MainTabs from './MainTabs';
import SignUpWithEmailOTP from './auth/SignUpWithEmailOTP';
import RedirectToLogin from './RedirectToLogin';
import SSO from './pages/SSO';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state : any)=> state.currentUser);
  //const { isUserAuthenticate } = useSelector((state : any)=> state.userAuthenticate);
  let showMenu = false;
  if(getAccessToken()){
    showMenu = true;
  }
  useEffect(() => {console.log("app"); loadCurrentUser() }, []);
  const loadCurrentUser = async () => {
    const token = await getAccessToken();
    
    if(token)
      await dispatch(getCurrentUser(token));
  }
  useEffect(() => {
    if(currentUser && currentUser.data){
      dispatch(authenticateUserWhenLogin());
    }
  }, [ currentUser ]);
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {showMenu === true && <DashboardMenu />}
          <IonRouterOutlet id="main">
            <Route path="/" component={Home} exact/>
            <Route path="/sso" component={SSO} exact />
            <Route path="/signupwithgmail" component={SignUpWithEmailOTP} />
            <Route path="/getbusinessdetails" component={GetBusinessDetails} />
            <Route path="/logout" render={() => {
                  return <RedirectToLogin/>;
                }} />
            <Route path="/my" render={() => <MainTabs />} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;