export const GET_TOKENS_LOGIN_REQUEST = 'GET_TOKENS_LOGIN_REQUEST'
export const GET_TOKENS_LOGIN_SUCCESS = 'GET_TOKENS_LOGIN_SUCCESS'
export const GET_TOKENS_LOGIN_FAIL = 'GET_TOKENS_LOGIN_FAIL'

export const GET_EMAIL_OTP_REQUEST = 'GET_EMAIL_OTP_REQUEST'
export const GET_EMAIL_OTP_SUCCESS = 'GET_EMAIL_OTP_SUCCESS'
export const GET_EMAIL_OTP_FAIL = 'GET_EMAIL_OTP_FAIL'

export const LOAD_CURRENT_USER_REQUEST = 'LOAD_CURRENT_USER_REQUEST'
export const LOAD_CURRENT_USER_SUCCESS = 'LOAD_CURRENT_USER_SUCCESS'
export const LOAD_CURRENT_USER_FAIL = 'LOAD_CURRENT_USER_FAIL'

export const LOAD_BUSINESS_TYPE_LIST_REQUEST = 'LOAD_BUSINESS_TYPE_LIST_REQUEST'
export const LOAD_BUSINESS_TYPE_LIST_SUCCESS = 'LOAD_BUSINESS_TYPE_LIST_SUCCESS'
export const LOAD_BUSINESS_TYPE_LIST_FAIL = 'LOAD_BUSINESS_TYPE_LIST_FAIL'

export const USER_ONBOARDING_REQUEST = 'USER_ONBOARDING_REQUEST'
export const USER_ONBOARDING_SUCCESS = 'USER_ONBOARDING_SUCCESS'
export const USER_ONBOARDING_FAIL = 'USER_ONBOARDING_FAIL'

export const USER_LOGGEDIN_AND_AUTHENTICATE = 'USER_LOGGEDIN_AND_AUTHENTICATE'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'