import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import { getTaxes, deleteTax, clearErrors } from '../../../actions/dashboardActions';
import {getAccessToken} from '../../../services/auth/userLocalStorage';
import {
    trash as trashIcon,
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { Geolocation } from '@capacitor/geolocation';

const Taxes = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const { taxesLoading, taxes, taxesError } = useSelector((state : any)=> state.taxes);
    const { deleteTaxMessageLoading, deleteTaxMessageError } = useSelector((state : any)=> state.deleteTaxData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { createTaxMessageLoading, createTaxMessage, createTaxMessageError } = useSelector((state : any)=> state.createTaxData);

    useEffect(() => {
        Geolocation.getCurrentPosition().then((val)=>{
            if(currentUser && currentUser.data)
                dispatch(getTaxes(getAccessToken(), currentUser.data[0].store_id, val.coords.latitude, val.coords.longitude));
        })
    }, [currentUser]);

    useEffect(() => {
        Geolocation.getCurrentPosition().then((val)=>{
            if(currentUser && currentUser.data && deleteTaxMessageLoading === false)
                dispatch(getTaxes(getAccessToken(), currentUser.data[0].store_id, val.coords.latitude, val.coords.longitude));
        })
    }, [deleteTaxMessageLoading]);

    useEffect(() => {
        Geolocation.getCurrentPosition().then((val)=>{
            if(currentUser && currentUser.data && createTaxMessageLoading === false)
                dispatch(getTaxes(getAccessToken(), currentUser.data[0].store_id, val.coords.latitude, val.coords.longitude));
        })
    }, [createTaxMessageLoading]);

    const onTaxDeleteClick = async (value: any)=>{
        if(currentUser && currentUser.data && value)
            await dispatch(deleteTax(getAccessToken(), currentUser.data[0].store_id, value.id));
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/settings" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Taxes and Charges</IonTitle>
                </IonToolbar>
            </IonHeader>
                <IonContent className="ion-padding">
                    {
                        taxesLoading === false && taxes && taxes.data && taxes.data.length !== 0 ? 
                        taxes.data.map((value: any)=>{
                            return (
                                <IonCard key={value.id}>
                                    <IonItem>
                                        <IonText>
                                            <h5>{value.name}</h5>
                                            {`${value.method === "PERCENT" ? `${value.value} %` : `${value.range.length === 0 ? `INR ${value.value}` : ""}`}`}
                                            {value.range.length !== 0 ? value.range.map((obj, ind)=>{
                                                return ind === value.range.length-1 ? (
                                                    <>
                                                        <IonText key={obj.start}>ABOVE {obj.start} Kms: INR {obj.value}</IonText>
                                                        <br/>
                                                    </>
                                                ) :
                                                (
                                                <>
                                                    <IonText>{obj.start} - {obj.end} kms: INR {obj.value}</IonText>
                                                    <br/>
                                                </>
                                                )}):""}
                                        </IonText>
                                        <IonIcon slot="end" size="large" icon={trashIcon} onClick={(e)=> {setAlertData(value); setShowAlert(true)}}></IonIcon>
                                    </IonItem>
                                </IonCard>
                            );
                        }) : null
                    }
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/my/settings/createtax">ADD Tax/Charge</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Coupon'}
                        message={`You are about to delete tax/charge ${alertData && alertData.code ? alertData.code : ""}`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { onTaxDeleteClick(alertData); }
                            }
                        ]}
                    />

                    <IonLoading isOpen={taxesLoading === true || deleteTaxMessageLoading === true} message="Please Wait"></IonLoading>
                </IonContent>
        </IonPage>
    )
}

export default Taxes
