import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import {
  arrowBack as arrowBackIcon, logoYoutube
} from 'ionicons/icons'; 
import React, { useState, useEffect, useRef } from 'react';
import { Document, Page ,pdfjs} from 'react-pdf';
import { useHistory, useParams } from 'react-router'; 
import { useDispatch, useSelector } from 'react-redux'; 
import { getCurrentUser } from '../actions/authActions';
import { setTokensObject } from '../services/auth/userLocalStorage';

const Invoice: React.FC = (props: any) => { 
    const history = useHistory();
    const dispatch = useDispatch(); 
    const { currentUserLoading, currentUser, currentUserError } = useSelector((state : any)=> state.currentUser);
    
     const {id}=useParams<any>();
    
    pdfjs.GlobalWorkerOptions.workerSrc = 
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
     const [numPages, setNumPages] = useState(null);
      const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
    return (
      <IonPage> 
       < IonHeader>
                <IonToolbar> 
                <IonButtons slot="start">
                <IonBackButton defaultHref="/my/orders" />
                </IonButtons>
                <IonTitle>Invoice #{id}</IonTitle> 
                </IonToolbar>
            </IonHeader>
            <IonContent scrollX={true}>
          <Document
          file={`https://api.intelikart.com/api/orders-invoices/${id}`} 
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        </IonContent>
      </IonPage>
    );
};

export default Invoice;