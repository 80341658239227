import React from 'react';
import { IonBadge, IonCard,IonLabel,IonButton, IonIcon, IonItem, IonText, IonImg } from '@ionic/react';
import { call as callIcon } from 'ionicons/icons';
import { formatDate } from '../../services/formatters';
import { Link } from 'react-router-dom';

const ShortInfo = props => {
    const { order } = props;
    const color={
        "New":"#08da4e",
        "Processing":"#f68c09",
        "Delivered":"#2db6fa",
        "Returned":"#e9222c",
        "Cancelled":"#e9222c"
    }
    const paymentStatusColor={
        "PAID" : "warning",
        "COD" : "danger"
    }

    return (
        <div key={ order.id }>
            <IonCard key={order.id}>
                <IonItem lines="none">
                    <IonText slot="start"><h5>Order #{order.id}</h5></IonText>
                    <IonText slot="end"><h5>{formatDate(order.created_at.date,'DD/MM/YY h:mm A')}</h5></IonText>
                </IonItem>
                <IonItem>
                    {order.products[0] && order.products[0].variant_image_url_small && <IonImg slot="start" src={order.products[0].variant_image_url_small} style={{width: 15+'%', height: 15+'vh'}}></IonImg>}
                    <IonItem lines="none" className="ion-no-padding" style={{width: 100+'%'}}>
                        <IonText className="ion-no-padding">
                            {order.products[0] && <h5>{order.products[0].variant_name}</h5>}
                            <h6>Rs. {order.order_final_price}</h6>
                        </IonText>
                        <IonBadge slot="end" color={`${paymentStatusColor[order.payment_status]}`}>{order.payment_status}</IonBadge>
                    </IonItem>
                </IonItem>
                <IonItem lines="none">
                    <IonText slot="start"><b>{order.status}</b></IonText>
                    <IonItem lines="none" slot="end"  className="ion-no-padding">
                        <IonButton href={ `tel:${ order.user_phone }` } ><IonIcon icon={callIcon}></IonIcon></IonButton>
                        <Link to={{ pathname: `/my/orders/orderdetails`, state: order.id }} >
                            <IonButton>Details &gt;</IonButton>
                        </Link>
                    </IonItem>
                </IonItem>
            </IonCard>
        </div>
    );
}

export default ShortInfo;