import { GET_TOKENS_LOGIN_REQUEST, GET_TOKENS_LOGIN_SUCCESS, GET_TOKENS_LOGIN_FAIL,
    LOAD_CURRENT_USER_REQUEST, LOAD_CURRENT_USER_SUCCESS, LOAD_CURRENT_USER_FAIL,
    LOAD_BUSINESS_TYPE_LIST_REQUEST, LOAD_BUSINESS_TYPE_LIST_SUCCESS, LOAD_BUSINESS_TYPE_LIST_FAIL,
    USER_ONBOARDING_REQUEST, USER_ONBOARDING_SUCCESS, USER_ONBOARDING_FAIL,
    CLEAR_ERRORS,
    USER_LOGGEDIN_AND_AUTHENTICATE,
    USER_LOGGED_OUT
} from '../constants/authConstants';

export const loadUserTokensReducer = (state = { userAPITokens: undefined }, action: any) =>{
switch(action.type){

    case GET_TOKENS_LOGIN_REQUEST:
        return{
            ...state,
            userAPITokensLoading: true,
            userAPITokensError: null
        }
    case GET_TOKENS_LOGIN_SUCCESS:
        return{
            ...state,
            userAPITokensLoading: false,
            userAPITokens: action.payload
        }
    case GET_TOKENS_LOGIN_FAIL: 
        return{
            ...state,
            userAPITokensLoading: false,
            userAPITokensError: action.payload
        }
    case CLEAR_ERRORS:
        return{
            ...state,
            error: null
        }
    default:
        return state
}
}

export const loadCurrentUserReducer = (state = { currentUser: undefined }, action: any) =>{
    switch(action.type){
    
        case LOAD_CURRENT_USER_REQUEST:
            return{
                ...state,
                currentUserLoading: true,
                currentUserError: null
            }
        case LOAD_CURRENT_USER_SUCCESS:
            return{
                ...state,
                currentUserLoading: false,
                currentUser: action.payload
            }
        case LOAD_CURRENT_USER_FAIL:
            return{
                ...state,
                currentUserLoading: false,
                currentUserError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadBusinessTypeListReducer = (state = { businessTypeList: {} }, action: any) =>{
    switch(action.type){

        case LOAD_BUSINESS_TYPE_LIST_REQUEST:
            return{
                ...state,
                businessTypeListLoading: true,
                businessTypeListError: null
            }
        case LOAD_BUSINESS_TYPE_LIST_SUCCESS:
            return{
                ...state,
                businessTypeListLoading: false,
                businessTypeList: action.payload
            }
        case LOAD_BUSINESS_TYPE_LIST_FAIL:
            return{
                ...state,
                businessTypeListLoading: false,
                businessTypeListError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const userOnboardingReducer = (state = { userOnboardingMessage: {} }, action: any) =>{
    switch(action.type){

        case USER_ONBOARDING_REQUEST:
            return{
                ...state,
                userOnboardingLoading: true,
                userOnboardingError: null
            }
        case USER_ONBOARDING_SUCCESS:
            return{
                ...state,
                userOnboardingLoading: false,
                userOnboardingMessage: action.payload
            }
        case USER_ONBOARDING_FAIL:
            return{
                ...state,
                userOnboardingLoading: false,
                userOnboardingError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const userAuthenticateReducer = (state = { isUserAuthenticate : true }, action: any) =>{
    switch(action.type){
        case USER_LOGGEDIN_AND_AUTHENTICATE:
            return{
                ...state,
                isUserAuthenticate: true
            }
        case USER_LOGGED_OUT:
            return{
                ...state,
                isUserAuthenticate: false
            }
        default:
            return state
    }
}

