import React,{useEffect,useState} from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import IntelikartDashboardLogo from '../images/IntelikartDashboardLogo.png';
import YouTube from 'react-youtube';
import MyAccordion from "../components/accordian-list/MyAccordion";
import { IonMenu, 
  IonHeader,
  IonIcon, 
  IonImg, 
  IonMenuButton, 
  IonButton, 
  IonToolbar, 
  IonTitle, 
  IonContent, 
  IonList, 
  IonItem, 
  IonApp, 
  IonLabel, 
  IonButtons, 
  IonBackButton,
  IonSegmentButton,
  IonSegment,
  IonText,
  IonPage,
  IonListHeader,
  IonAlert,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonThumbnail,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLoading,
} from '@ionic/react';
import {
    addOutline,
  arrowBack,
  create,
  createOutline,
  logoYoutube,
  searchOutline,
  trash,
  trashOutline,
  tvOutline,
  
} from 'ionicons/icons';
import { deleteStoresCategory, getStoresCategory } from '../actions/categoryAction';
import { getAccessToken } from '../services/auth/userLocalStorage';
import DashboardMenu from '../components/dashboard/DashboardMenu'; 
import Title from '../components/dashboard/Header';
import ProductsHomeScreen from '../components/products/ProductsHomeScreen';


const Catalogue  = () => {
    const history=useHistory();
    const dispatch = useDispatch();
    const [loading,setLoading]=useState(false)
    const [data, setData] = useState<Array<any>>([]);
    const [selectedSegment,setSelectedSegment]=useState('categories')
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);
    const { deleteCategoryLoading, deleteCategory, deleteCategoryError } = useSelector((state : any)=> state.categories);
    
    const { createCategoryLoading, createCategory, createCategoryError } = useSelector((state : any)=> state.categories);

    const opts = {
        height: '390',
        width: '100%'
    };
    useEffect(()=>{
        const accessToken = getAccessToken();
        if(accessToken && currentUser && currentUser.data)
            dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id));
    },[currentUser]);   
    
    // useEffect(()=>{
    //     const accessToken = getAccessToken();
    //     if(accessToken && currentUser && currentUser.data)
    //         dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id));
    // },[createCategoryLoading]);   
    
    // useEffect(()=>{
    //     const accessToken = getAccessToken();
    //     if(accessToken && currentUser && currentUser.data)
    //         dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id));
    // },[deleteCategoryLoading]);   
    
    
    // console.log(categories)
    // useEffect(()=>{
    //     if(deleteCategoryError){
    //         alert(deleteCategoryError.message)
    //     }
    //     else if(deleteCategory){
    //        alert(deleteCategory.message)
    //    }

    // },[deleteCategory,deleteCategoryError])
    return(
      <IonPage>
            <IonHeader>
                <IonToolbar> 
                <IonButtons slot="start">
                    <IonMenuButton></IonMenuButton>
                </IonButtons>
                <IonTitle>Catalogue</IonTitle>
                <IonButtons slot="end">
                    <IonButton  size="small" target="_blank" href="https://www.youtube.com/watch?v=dkOvPjF41YA" color='medium'>
                        <IonIcon slot="start" icon={logoYoutube}></IonIcon>
                            How to Use</IonButton>
                </IonButtons> 
                </IonToolbar>
            </IonHeader>
            <IonLoading isOpen={loading}/>
            <IonSegment className="fixed" value="categories">
                    <Link style={{textDecoration: 'none'}} to="#">
                    <IonSegmentButton value="categories">
                        <IonLabel>Categories</IonLabel>
                    </IonSegmentButton>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="/my/products"><IonSegmentButton>
                        <IonLabel>Products</IonLabel>
                    </IonSegmentButton></Link>
            </IonSegment>
            <IonContent>
                <div className='ion-text-center'>
                     
                    {/* <IonButton  size="small" target="_blank" href="https://www.youtube.com/watch?v=dkOvPjF41YA" color='medium'>
                    <IonIcon slot="start" icon={logoYoutube}></IonIcon>
                      Watch - How To Add Category</IonButton> */}
          
                </div>
                
                {selectedSegment==="categories" ? <div>
                    <IonItem className="ion-text-center">
                        <IonLabel color="tertiary">
                            <b>Manage categories and subcategories</b>
                            <br/>
                            we create products in subcategories
                        </IonLabel>
                    </IonItem>
                    <IonRow>
                        <IonCol className="ion-text-center" >
                            <Link to={`/my/catalogue/category/add/${false}`} style={{textDecoration: 'none'}}>
                                <IonButton>
                                    <IonIcon icon={addOutline}/>
                                    Add New Category
                                </IonButton> 
                            </Link>
                        </IonCol>
                    </IonRow>
                     {categories ?<MyAccordion items={categories}/>:""}
                     <IonCard className="ion-text-center ion-no-padding" >
                        <IonCardHeader>
                            <IonCardTitle>Watch video to understand categories and products.</IonCardTitle>
                        </IonCardHeader>
                        <YouTube videoId='dkOvPjF41YA' opts={opts}/> 
                    </IonCard>

                </div>
            :
            ""}
             <IonLoading isOpen={fetchCategoriesLoading === true } message="Please Wait"></IonLoading>
            </IonContent>
      
      </IonPage>
  
    )
}

export default Catalogue



