import React from 'react'
import { IonToolbar, IonFooter } from '@ionic/react';
import { Link } from 'react-router-dom';

const LoginFooter = () => {
    return (
        <IonFooter class=" ion-text-center">
            <IonToolbar>
                <h5 style={{fontSize:14+'px', paddingLeft:10+"%", paddingRight:10+"%"}}>By using this application, you agree to our <Link to="https://www.intelikart.com/privacy-policy.html">Terms of service</Link> and <Link to="https://www.intelikart.com/privacy-policy.html">Privacy policy</Link></h5>
            </IonToolbar>
        </IonFooter>
    )
}

export default LoginFooter
