import React, {useEffect, useRef, useState} from 'react'
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { 
    IonContent, 
    IonPage, 
    IonItem, 
    IonLabel, 
    IonButton, 
    IonInput,
    IonText,
    IonSelectOption,
    IonSelect,
    IonLoading,
    IonIcon,
    IonCol,
    IonGrid,
    IonRow,
    IonModal,
    IonImg,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    useIonViewDidEnter
} from '@ionic/react';
import LogoImage from '../components/auth/LogoImage';
import { getBusinessTypeList, onboardingUser, clearErrors } from '../actions/authActions';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { caretDown as caretDownIcon,
    checkmarkSharp as checkmarkSharpIcon,
    closeOutline
} from 'ionicons/icons';
import LoginFooter from '../components/auth/LoginFooter';
import { isDesktop } from 'react-device-detect';

interface businesstypeItem{
    id: number,
    name: string,
    icon: string,
    description: string,
}

const GetBusinessDetails = () => {
    const [showModal, setShowModal] = useState(false);
    const [phone, setPhone] = useState<undefined | string | null>('');
    const [storeType, setStoreType] = useState<any>();
    const [name, setName] = useState<undefined | string | null>('');
    const [businessURL, setBusinessURL] = useState<undefined | string | null>('');
    const [isBusinessTypeEmpty, setIsBusinessTypeEmpty] = useState<boolean | undefined>(false);

    //required fields
    const [isPhoneEmpty, setIsPhoneEmpty] = useState<undefined  | boolean>(false);
    const [isNameEmpty, setIsNameEmpty] = useState<undefined  | boolean>(false);
    const [isBusinessURLEmpty, setIsBusinessURLEmpty] = useState<undefined  | boolean>(false);

    //vallidation
    const [isPhoneValidate, setIsPhoneValidate] = useState<undefined  | boolean>(true);
    const [isNameValidate, setIsNameValidate] = useState<undefined  | boolean>(true);
    const [isBusinessURLValidate, setIsBusinessURLValidate] = useState<undefined  | boolean>(true);
    const [isBusinessURLAvailable, setIsBusinessURLAvailable] = useState<undefined  | boolean>(true);

    //create account loading
    const [loading, setLoading] = useState<undefined  | boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const { businessTypeListLoading, businessTypeList, businessTypeListError } = useSelector((state : any)=> state.businessTypeList);
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { userOnboardingLoading, userOnboardingMessage, userOnboardingError } = useSelector((state : any)=> state.userOnboarding);

    useEffect(()=>{
        (async function() {
            await dispatch(getBusinessTypeList());
        })();
        if(!businessTypeListLoading && businessTypeList){
        }
        if(!businessTypeListLoading && businessTypeListError){
        }
    },[]);

    useEffect(()=>{
        if(userOnboardingLoading === false){
            if(userOnboardingMessage && userOnboardingMessage.data && userOnboardingMessage.data.id && userOnboardingMessage.data.subdomain){
                history.push('/my/userdashboard');
            }
            if(userOnboardingError){
                console.log(userOnboardingError);
            }
        }
    },[userOnboardingLoading, userOnboardingMessage, userOnboardingError]);

    const phoneValidation = ()=>{
        let result = true;
        if(!phone){
            setIsPhoneEmpty(true);
            result = false;
        }else{
            setIsPhoneEmpty(false);
            if(/\D/.test(phone)){
                setIsPhoneValidate(false);
                result = false;
            }
            else{
                setIsPhoneValidate(true);
                if(phone.length !== 10){
                    setIsPhoneValidate(false);
                    result = false;
                }else
                    setIsPhoneValidate(true);
            }
        }
        return result;
    }

    const storeIdValidation = ()=>{
        let result = true;
        if(!storeType){
            setIsBusinessTypeEmpty(true);
            result = false;
        }else{
            if(!storeType.id){
                setIsBusinessTypeEmpty(true);
                result = false;
            }
        }
        return result;
    }

    const nameValidation = ()=>{
        let result = true;
        if(!name){
            setIsNameEmpty(true);
            result = false;
        }else{
            setIsNameEmpty(false);
            if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)){
                setIsNameValidate(false);
                result = false;
            }
            else
                setIsNameValidate(true);
        }
        return result;
    }

    const businessURLValidation = async (accessToken: string)=>{
        let result = true;
        if(!businessURL){
            setIsBusinessURLEmpty(true);
            result = false;
        }else{
            setIsBusinessURLEmpty(false);
            if(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(businessURL)){
                setIsBusinessURLValidate(false);
                result = false;
            }
            else{
                setIsBusinessURLValidate(true);
                const response = await fetch(`https://api.intelikart.com/api/subdomain-availability/${businessURL}.intelikart.com`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${accessToken}`
                    }
                });
                const message = await response.json();
                let isAvailable = message.data.result === "available" ? true : false;
                if(isAvailable === false){
                    setIsBusinessURLAvailable(false);
                    result = false;
                }else
                    setIsBusinessURLAvailable(true);
            }
        }
        return result;
    }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const accessToken = await getAccessToken();
        let res1 = await phoneValidation();
        const res2 = await nameValidation();
        const res3 = await businessURLValidation(accessToken);
        const res4 = await storeIdValidation();
        if( res1 && res2 && res3 && res4){
            await dispatch(onboardingUser(accessToken, currentUser.data[0].store_id, {
                phone: `${phone}`,
                storetype_id: storeType.id,
                subdomain: `${businessURL}.intelikart.com`,
                name: `${name}`
            }));
        }
        setLoading(false);
    }

    const firstNameInputRef = useRef<HTMLIonInputElement>(null);
    useIonViewDidEnter(() => {
      firstNameInputRef.current?.setFocus();
    });

    let classOfGrid = "";
    if(isDesktop){
        classOfGrid = "center-grid";
    }

    return (
        <IonPage>
            <IonHeader>
            <IonToolbar>
            <IonButtons slot="start">
          <IonBackButton defaultHref="/getbusinessdetails" />
        </IonButtons>
            <IonTitle>Enter Business Details</IonTitle>
            </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ion-text-center">
                <IonGrid  className={classOfGrid}>
                    <IonRow>
                        <IonCol size="12">
            <div className="iklogo">
                <LogoImage/>
                </div>
                <IonTitle className="ion-padding ion-text-center">Enter business details</IonTitle>
                <form className="ion-padding" onSubmit={submitHandler}>
                <IonItem className="ion-margin-bottom">
                    <IonLabel position="stacked">Enter your Mobile No. </IonLabel>
                    <IonInput ref={firstNameInputRef} placeholder="Mobile Number" type="tel" inputmode="numeric" value={phone} name="phone" onIonChange={(e)=>{ setPhone(e.detail.value)}}/>
                </IonItem>
                <IonText color="danger" className={`${isPhoneEmpty === false && isPhoneValidate === true ? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isPhoneEmpty ? "" : "ion-hide"}`}>Mobile number is required.</h5>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isPhoneValidate ? "ion-hide" : ""}`}>Mobile number is incorrect.</h5>
                </IonText>

                <IonItem className="ion-margin-bottom">
                    <IonLabel position="stacked">Select Your Business Type</IonLabel>
                    <div style={{width: 100+'%'}} onClick={e=> setShowModal(true)}>
                        <IonGrid className="ion-no-padding ion-padding-top">
                            <IonRow className="ion-align-items-center">
                                <IonCol className="ion-no-padding" size="9">
                                    { storeType && storeType.id && storeType.icon && storeType.name && storeType.description ? <>
                                        {/* <IonImg slot="start" src={storeType.icon} style={{width: 50+'px', height: 50+'px'}}/> */}
                                        {/* <IonText>
                                            <p className="storeTypeName">{storeType.name}</p>
                                        </IonText> */}
                                        <IonInput value={storeType.name} readonly></IonInput>
                                    </> : <IonInput placeholder="Type of Business" readonly></IonInput>}
                                </IonCol>
                                <IonCol size="3" className="ion-no-padding">
                                    <IonItem lines="none"><IonIcon slot="end" icon={caretDownIcon}/></IonItem>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                    <IonModal isOpen={showModal} onDidDismiss={e=>setShowModal(false)} >
                        <IonHeader>
                            <IonItem lines="none">
                                <IonTitle>Select Business Type</IonTitle>
                                <IonButton fill='clear' size='large'  slot='end' onClick={(e) => setShowModal(false)} >
                                  <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon> 
                                </IonButton>
                                {/* <IonText style={{cursor:'pointer'}}  color="primary" slot="end" onClick={e=> setShowModal(false)}>
                                    <h5>CLOSE</h5>
                                </IonText> */}
                            </IonItem>
                        </IonHeader>
                        <IonContent className="ion-padding">
                        {businessTypeList && businessTypeList.data ? 
                            (
                                businessTypeList.data.map((element:businesstypeItem) =>(
                                    <IonItem key={element.id} style={{cursor:'pointer'}} onClick={e=>{setStoreType(element); setShowModal(false)}}>
                                        <IonImg slot="start" src={element.icon} style={{width: 50+'px', height: 50+'px'}}/>
                                        <IonText>
                                            <h5>{element.name}</h5>
                                            <p className="ion-no-margin ion-margin-bottom">{element.description}</p>
                                        </IonText>
                                        {storeType && storeType.id && element.id === storeType.id ? <IonIcon slot="end" color="primary" size="large" icon={checkmarkSharpIcon}/> : ""}
                                    </IonItem>
                                )))
                            : null
                        }
                        </IonContent>
                    </IonModal>
                    {/* <IonSelect placeholder="Type of Business" onIonChange={(e) => { setStoreType(e.detail.value) }}>
                        {businessTypeList && businessTypeList.data ? 
                            (
                                businessTypeList.data.map((element:businesstypeItem) =>(
                                        <IonSelectOption key={element.id} value={element.id}>{element.name}</IonSelectOption>
                                )))
                            : null
                        }
                    </IonSelect> */}
                </IonItem>
                <IonText color="danger" className={`${isBusinessTypeEmpty ? "": "ion-hide" }`}><h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Business Type can't be blank.</h5></IonText>

                <IonItem className="ion-margin-bottom">
                    <IonLabel position="stacked">Business Name</IonLabel>
                    <IonInput placeholder="App name / Business name" type="text" value={name} name="name" onIonChange={(e)=>{ setName(e.detail.value);  setBusinessURL(e.detail.value ?(e.detail.value).replace(/ /g,'').toUpperCase() : e.detail.value) }}/>
                </IonItem>
                <IonText color="danger" className={`${isNameEmpty === false && isNameValidate === true ? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isNameEmpty ? "" : "ion-hide"}`}>Business name is required.</h5>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isNameValidate ? "ion-hide" : ""}`}>Business name can't contain special characters.</h5>
                </IonText>

                <IonItem className="ion-margin-bottom" lines="none">
                    <IonLabel position="stacked">Choose A URL For Store</IonLabel>
                    <br/>
                    <IonItem className="ion-no-padding" style={{width: 100+'%'}}>
                        <IonInput type="text" value={businessURL} name="businessURL" onIonChange={(e)=>{ setBusinessURL(e.detail.value.toUpperCase())}}/>
                        <IonText item-right>.intelikart.com</IonText>
                    </IonItem>
                </IonItem>
                <IonText color="danger" className={`${isBusinessURLEmpty === false && isBusinessURLValidate === true && isBusinessURLAvailable === true ? "ion-hide": "" }`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isBusinessURLEmpty ? "" : "ion-hide"}`}>Business URL is required.</h5>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isBusinessURLValidate ? "ion-hide" : ""}`}>Business URL can't contain special characters.</h5>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isBusinessURLAvailable ? "ion-hide" : ""}`}>This business URL isn't available.</h5>
                </IonText>

                <IonButton expand="block" type="submit">Finish</IonButton>
                </form>
                <IonLoading isOpen={ businessTypeListLoading === true || loading === true } message={'Please Wait...'}/>
                </IonCol>
                </IonRow>
                </IonGrid>
            </IonContent>
            {/* <LoginFooter/> */}
        </IonPage>
    )
}

export default GetBusinessDetails
