import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonInput, IonButton, IonPage, IonRow, IonGrid, IonCol, isPlatform, IonTextarea } from '@ionic/react';
import { getBasicInfo, setBasicInfo, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { Camera, CameraResultType } from '@capacitor/camera';

async function savePicture(file) {
    if(!file)return ""
    try{
        const formData = new FormData();
        formData.append("file",file)
        const response = await fetch(`https://api.intelikart.com/api/upload/logo-image`, {
        method: 'POST',
        body:formData
        });
        const data = await response.json();
        return data.platform_path;
    }
    catch(error){
        console.log(error)
        return ""
    }
  }

const BasicInfo = () => {
    const [businessName, setBusinessName] = useState<any>();
    const [description, setDescription] = useState<any>();
    const [isGSTValid, setIsGSTValid] = useState(true);
    const [pictureUrl, setPictureUrl] = useState('https://cdn.intelikart.com/uploads/new-images/default-store.png');
    const[imageFile,setImageFile]=useState(null)
    const[loading,setLoading]=useState(false)
    const [GSTIN, setGSTIN] = useState<any>();

    const dispatch = useDispatch();
    const history = useHistory();
    const { basicInfoLoading, basicInfo, basicInfoError } = useSelector((state : any)=> state.basicInfo);
    const { setBasicInfoResponseLoading, setBasicInfoResponse, setBasicInfoResponseError } = useSelector((state : any)=> state.setBasicInfo);
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const fileInputRef = useRef<HTMLInputElement>();
    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getBasicInfo(getAccessToken(), currentUser.data[0].store_id));
        }
    }, [currentUser]);

    useEffect(() => {
        if(basicInfoLoading === false && basicInfo && basicInfo.data){
            setBusinessName(basicInfo.data.name);
            setDescription(basicInfo.data.business_description);
            setGSTIN(basicInfo.data.gst);
            if(basicInfo.data.logo_platform_path!==null){
                setPictureUrl(basicInfo.data.logo_internet_url)
            }
        }
    }, [basicInfo])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
          const file = event.target.files.item(0);
          setImageFile(file)
          const pictureUrl = URL.createObjectURL(file);
          setPictureUrl(pictureUrl);
        }
      };
      const handlePictureClick= async()=>{
        
        if(isPlatform('capacitor')){
          try{
            const photo=await Camera.getPhoto({
              resultType:CameraResultType.Uri,
            })
            setPictureUrl(photo.webPath);
          }
          catch(error){
            console.log(error);
          }
        }
        else{
          fileInputRef.current.click();
        }
        
      }
      const GSTValidation = ()=>{
        let result = true;
        if(GSTIN){  
            if(GSTIN.length !== 15){
                setIsGSTValid(false);
                result = false;
            }else{
                setIsGSTValid(true);
            }   
        }
        return result;
    }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        
        if(currentUser && currentUser.data && GSTValidation()){
            setLoading(true)
            let platform_path= await savePicture(imageFile)
            if(!platform_path)platform_path=basicInfo.data.logo_platform_path
            Promise.all([await dispatch(setBasicInfo(getAccessToken(), currentUser.data[0].store_id, {
                name: `${businessName}`,
                business_description: `${description}`,
                gst: `${GSTIN}`,
                logo :platform_path
            })),await dispatch(getBasicInfo(getAccessToken(), currentUser.data[0].store_id))
            ])
        setLoading(false)
        }
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/settings" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Basic Info</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Business name</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput placeholder={businessName} type="text" value={businessName} name="businessName" onIonChange={(e)=>{ setBusinessName(e.detail.value)}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Description</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonTextarea placeholder={description}  value={description} name="description" onIonChange={(e)=>{ setDescription(e.detail.value)}}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>GSTIN</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput placeholder={GSTIN} type="text" value={GSTIN} name="GSTIN" onIonChange={(e)=>{ setGSTIN(e.detail.value)}}/>
                            </IonItem>
                            <IonText className={`${isGSTValid ? "ion-hide": ""}`} color="danger">
                                <h5 className="ion-margin-start" style={{fontSize: 12+'px'}}>GSTIN is not valid.</h5>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Website</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput value={basicInfoLoading === false && basicInfo && basicInfo.data ? basicInfo.data.subdomain : "" } disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="4">
                            <IonLabel>Store Type</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput value={basicInfoLoading === false && basicInfo && basicInfo.data ? basicInfo.data.store_type : "" } disabled />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="4">
                            <IonLabel>Logo</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="6" >
                        <div className="image-upload">  
                        <input hidden type='file' accept="image/*" ref={fileInputRef} onChange={handleFileChange}/>
                            <img width="90%" height="225" src={pictureUrl} alt=""></img>
                        </div>
                        </IonCol>
                        <IonCol>
                        <IonButton size='small' shape="round"  onClick={handlePictureClick}>Update Image</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                    <div className="ion-text-center">
                        <IonButton shape="round" type="submit">Save</IonButton>
                    </div>
                </form>
                <IonLoading isOpen={loading===true || basicInfoLoading === true || setBasicInfoResponseLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default BasicInfo
