import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';

import {
arrowBack as arrowBackIcon, logoYoutube
} from 'ionicons/icons';
import { Camera,CameraResultType } from '@capacitor/camera';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { createStoresCategory, createStoresSubCategory, getStoresCategory} from '../actions/categoryAction';

interface RouteParams{
  id:string;
  name:string;
  fp:string;
}
async function savePicture(file) {
  if(!file)return "";
  const formData = new FormData();
  formData.append("file",file)
  try{
    const response = await fetch(`https://api.intelikart.com/api/upload/category-image`, {
      method: 'POST',
      body:formData
    });
    const data = await response.json();
    return data.platform_path;
  }
  catch(error){
    return "";
  }
}

const AddSubCategoryPage: React.FC = (props: any) => {
  //const { userId } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {id}=useParams<RouteParams>();
  const {name}=useParams<RouteParams>();
  const {fp}=useParams<RouteParams>();
  const { currentUser } = useSelector((state : any)=> state.currentUser);
  const { fetchCategoriesLoading, categories,  createSubCategoryLoading, createSubCategory, } = useSelector((state : any)=> state.categories);
  const [date, setDate] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [parentCategory, setParentCategory] = useState(Number(id));
  const [pictureUrl, setPictureUrl] = useState('/assets/placeholder.svg');
  const[status,setStatus]=useState('active')
  const[imageFile,setImageFile]=useState(null)
  const fileInputRef = useRef<HTMLInputElement>();
  const categoryNameInputRef = useRef(null);
  const[loading,setLoading]=useState(false);
  // useEffect( () => {
  //       console.log("res",createCategory)
  //       if(!createCategoryLoading && createCategory){
  //         //dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id))
  //         history.push(`/subcategory/add/${createCategory.id}`)
  //       }
  // },[createCategoryLoading,createCategory])]
  useEffect(()=>{
      const accessToken = getAccessToken();
      if(accessToken && currentUser && currentUser.data && !categories)
        dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id));
      },[currentUser]); 

      useEffect(() => {
        if(createSubCategoryLoading === false && createSubCategory){
          console.log("runnong");
        if(fp === 'false'){
          history.push('/my/catalogue');
        }else if(fp === 'true'){
            history.push({
              pathname: '/my/products/create/${true}',
              state: { id: createSubCategory.id, name: createSubCategory.name }
            });
        }
      }
      }, [createSubCategoryLoading]);
  useIonViewDidEnter(() => {
    setTimeout(() => categoryNameInputRef.current.setFocus(), 100)
  });
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      
      const file = event.target.files.item(0);
      setImageFile(file)
      console.log(file)
      const pictureUrl = URL.createObjectURL(file);
      setPictureUrl(pictureUrl);
    }
  };
  const handlePictureClick= async()=>{
    
    if(isPlatform('capacitor')){
      try{
        const photo=await Camera.getPhoto({
          resultType:CameraResultType.Uri,
        })
        setPictureUrl(photo.webPath);
      }
      catch(error){
        console.log(error);
      }
    }
    else{
      fileInputRef.current.click();
    }
    
  }
  
  
  const handleSave=async ()=>{
      
      const accessToken = await getAccessToken();
      if(!imageFile ){
        alert("Image is mandatory for child categories")
      }
      else if(accessToken && currentUser && currentUser.data){
        setLoading(true)
        const platform_path = await savePicture(imageFile);
        await Promise.all([await dispatch(createStoresSubCategory(accessToken, currentUser.data[0].store_id, {
          "id": 0,
          "parent_id":id,
          "name": categoryName,
          "store_id": `${currentUser.data[0].store_id}`,
          "priority": '',
          "icon_code": platform_path,
          "status": status
        })),await dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id))]);
        setCategoryName("")
        setParentCategory(0)
        setPictureUrl('/assets/placeholder.svg')
        setImageFile(null)
        setLoading(false)
        fileInputRef.current.value=null;
    }
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonIcon size="large" onClick={e=> history.goBack()} icon={arrowBackIcon}/>
          </IonButtons>
          <IonTitle>Add SubCategory</IonTitle>
          <IonButtons slot="end">
            <IonButton  size="small" target="_blank" href="https://www.youtube.com/watch?v=dkOvPjF41YA" color='medium'>
                  <IonIcon slot="start" icon={logoYoutube}></IonIcon>
                    How to Use</IonButton>
          </IonButtons> 
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
         <div className="ion-text-center ion-padding">
        <IonText >Add Subcategory in <span style={{color:'#3880ff'}}>{name}</span></IonText>
        </div>
          <IonItem >
            <IonLabel position="stacked">{ 'SubCategory Name'}</IonLabel>
            <IonInput required value={categoryName}  ref={(ref) => categoryNameInputRef.current = ref} placeholder={ 'Add SubCategory Example Organic Fruits'}
              onIonChange={(event) => setCategoryName(event.detail.value)}
            />
          </IonItem>
          {/* <IonItem>
          <IonLabel>Choose Parent Category</IonLabel>
            <IonSelect value={parentCategory} placeholder="Select One" onIonChange={e=>setParentCategory(e.detail.value)}>
              <IonSelectOption  value={0}>Parent Category</IonSelectOption>
              {categories && categories.map(op=>
                <IonSelectOption key ={op.id} value={op.id}>{op.name} </IonSelectOption>
              )}
              
            </IonSelect>
          </IonItem> */}
          <IonItem lines='none' className='ion-padding-top'>  
              <IonLabel className='ion-padding-bottom' position="stacked">{'SubCategory Image'}</IonLabel>
              <input   hidden type='file' accept="image/*" ref={fileInputRef}
              onChange={handleFileChange}/>
            
              <img style={{maxHeight: 250+'px', maxWidth: 100+'%'}} src={pictureUrl} onClick={handlePictureClick} alt="" ></img>
          </IonItem>
        <div className="ion-text-center ion-padding">
        <IonButton shape="round" disabled={categoryName==""?true:false} onClick={handleSave}> Save</IonButton>
        </div>
        
      
      <IonLoading isOpen={ loading} message={ 'Adding SubCategory'}/>
      </IonContent>
    </IonPage>
  );
};

export default AddSubCategoryPage;