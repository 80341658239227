import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonInput, IonButton, IonPage, IonRow, IonGrid, IonCol, isPlatform, IonTextarea, IonCheckbox, IonIcon } from '@ionic/react';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon,
    checkmarkCircleOutline as checkmarkCircleOutlineIcon,
    closeCircleOutline as closeCircleOutlineIcon
} from 'ionicons/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { createPage, isURLAvailableFunction } from '../../actions/pageActions';

const modules = {
    toolbar:[
        [{header: "1"}, {header: "2"}, {header: [3,4,5,6]}],
        ["bold", "italic", "underline", "sttrike", "blockquote"],
        [{list:"ordered"}, {list:"bullet"}],
        ["link","image"],
        ["clean"],
    ]
}

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image"
]

const CreatePage = () => {
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [content, setContent] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(false);

    //validation
    const [isTitleEmpty, setIsTitleEmpty] = useState<any>(false);
    const [isLinkEmpty, setIsLinkEmpty] = useState<any>(false);
    const [isContentEmpty, setIsContentEmpty] = useState<any>(false);
    const [isDescriptionEmpty, setIsDescriptionEmpty] = useState<any>(false);
    const [isLinkAvailable, setIsLinkAvailable] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { createPageLoading, createPageMessage, createPageError } = useSelector((state : any)=> state.createPageData);
    const { checkAvailabilityLoading, isURLAvailable, checkAvailabilityError } = useSelector((state : any)=> state.checkURLAvailability);

    useEffect(() => {
        if(checkAvailabilityLoading === false && link !== '' && link !== undefined && link !== null){
            if(isURLAvailable === false){
                setIsLinkAvailable(false);
            }else{
                setIsLinkAvailable(true);
            }
        }
    }, [checkAvailabilityLoading, isURLAvailable]);

    useEffect(() => {
        if(createPageLoading === false && createPageMessage && createPageMessage.data && createPageMessage.data.slug === link){
            setTitle('');
            setLink('');
            setContent('');
            setDescription('');
            setIsLinkAvailable(null);
            setStatus(false);
            history.goBack();
        }
    }, [createPageLoading, createPageMessage]);

    const titleValidation = ()=>{
        let result = true;
        if(!title){
            setIsTitleEmpty(true);
            result = false;
        }else{
            setIsTitleEmpty(false);
        }
        return result;
    }

    const linkValidation = ()=>{
        let result = true;
        if(!link){
            setIsLinkEmpty(true);
            result = false;
        }else{
            setIsLinkEmpty(false);
        }
        return result;
    }

    const descriptionValidation = ()=>{
        let result = true;
        if(!description){
            setIsDescriptionEmpty(true);
            result = false;
        }else{
            setIsDescriptionEmpty(false);
        }
        return result;
    }

    const contentValidation = ()=>{
        let result = true;
        if(!content){
            setIsContentEmpty(true);
            result = false;
        }else{
            setIsContentEmpty(false);
        }
        return result;
    }

    const createPageDispatcher = async () => {
        const res1 = titleValidation();
        const res2 = linkValidation();
        const res3 = contentValidation();
        const res4 = descriptionValidation();
        if(res1 && res2 && res3 && res4){
            if(currentUser && currentUser.data && isLinkAvailable === true){
                await dispatch(createPage({
                    store_id: currentUser.data[0].store_id,
                    slug: `${link}`,
                    name: `${title}`,
                    description: `${description}`,
                    content: `${content}`,
                    status: `${status === true ? 'ACTIVE' : 'INACTIVE'}`
                }, currentUser.data[0].store_id, getAccessToken()));
            }
        }
    }

    const changeLink = async(value)=>{
        const valueWithoutSpace = await value.replace(/\s+/g, '-');
        const stringWithoutSpecial = await valueWithoutSpace.replace(/[^a-zA-Z -]/g, "");
        var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        let finalString = await stringWithoutSpecial.replace(regex, "");
        if(finalString.endsWith('-')){
            finalString = finalString.slice(0, -1)
        }
        setLink(finalString.toLowerCase());
    }

    const checkURLAvailability = async()=>{
        if(currentUser && currentUser.data && currentUser.data[0].store_id && link){
            await dispatch(isURLAvailableFunction({store_id: currentUser.data[0].store_id, slug: `${link}`}, currentUser.data[0].store_id, getAccessToken()));
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/pages" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Create Page</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem>
                    <IonLabel position="stacked">Page Title</IonLabel>
                    <IonInput type="text" value={title} placeholder="Enter page title" onIonChange={e => {setTitle(e.detail.value); changeLink(e.detail.value)}} onIonBlur={checkURLAvailability}/>
                </IonItem>
                <IonText color="danger" className={`${isTitleEmpty === false? "ion-hide":""}`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}}>Title is required.</h5>
                </IonText>
                <br/>
                <IonItem>
                    <IonLabel position="stacked">Page Link</IonLabel>
                    <IonInput type="text" placeholder="Enter page link" value={link} onIonChange={e=> changeLink(e.detail.value)} onIonBlur={checkURLAvailability}/>
                </IonItem>
                <IonText color={`${isLinkAvailable === true ? "success" : "danger"}`}>
                    {isLinkAvailable === true ? <h5 style={{fontSize:12+'px', marginTop: 0+'px'}}><IonIcon icon={checkmarkCircleOutlineIcon}/> This link is available.</h5> : null}
                    {isLinkAvailable === false ? <h5 style={{fontSize:12+'px', marginTop: 0+'px'}}><IonIcon icon={closeCircleOutlineIcon}/> This link is already available.</h5> : null}
                </IonText>
                <IonText color="danger" className={`${isLinkEmpty === false? "ion-hide":""}`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}}>Link is required.</h5>
                </IonText>
                <IonText><h6>Page URL: <IonText color='primary'>https://{currentUser && currentUser.data[0] && currentUser.data[0].store && currentUser.data[0].store[28]}/{link}</IonText></h6></IonText>
                <IonItem lines='none'>
                    <IonLabel position="stacked">Page Content</IonLabel>
                </IonItem>
                <ReactQuill 
                placeholder="Write page content here."
                modules={modules}
                formats={formats}
                value={content}
                onChange={e=> setContent(e)}
                />
                <IonText color="danger" className={`${isContentEmpty === false? "ion-hide":""}`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}}>Content is required.</h5>
                </IonText>
                <br/>
                <IonItem>
                    <IonLabel position="stacked">Page Description</IonLabel>
                    <IonTextarea placeholder="Enter page description" value={description} onIonChange={e=>setDescription(e.detail.value)}/>
                </IonItem>
                <IonText color="danger" className={`${isDescriptionEmpty === false? "ion-hide":""}`}>
                    <h5 style={{fontSize:12+'px', marginTop: 0+'px'}}>Description is required.</h5>
                </IonText>
                <IonGrid>
                    <IonRow className="ion-align-items-center">
                        <IonCol size="2">
                            <IonText>Status:</IonText>
                        </IonCol>
                        <IonCol>
                            <IonItem lines="none">
                                <IonLabel>Published</IonLabel>
                                <IonCheckbox slot="start" checked={status} onIonChange={e=>setStatus(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="ion-text-center">
                    <IonButton shape="round" onClick={e=> createPageDispatcher()}>Create Page</IonButton>
                </div>
                <IonLoading isOpen={createPageLoading === true} message="Please wait"></IonLoading>
                <IonLoading isOpen={checkAvailabilityLoading === true} message="Checking Link Availability"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default CreatePage
