import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView } from "react-device-detect";
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonRow, IonGrid, IonCol, IonCard, IonIcon,  IonItem, IonSelect, IonSelectOption, IonToast, IonLoading, IonPopover, IonImg, IonButton, IonCardHeader, IonText, IonCardTitle, IonCardContent, IonTitle, IonHeader } from '@ionic/react';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import {
    cartOutline as cartIcon,
    people as peopleIcon,
    basketOutline as basketIcon,
    copyOutline as copyIcon,
    shareSocialOutline as shareIcon,
    globeOutline as globeIcon,
    logoAndroid as androidIcon,
    bicycleOutline as cycleIcon,
    fileTrayFull as fileTrayFullIcon,
    eyedrop,
    eyeOutline,
    informationCircleOutline,
    bagAddOutline,
    appsOutline,
    globeOutline,
    gridOutline,
    manOutline,
    colorPaletteOutline,
    copyOutline,
} from 'ionicons/icons';
import { getHomeScreenData, getHomeScreenDataWithDays } from '../../actions/dashboardActions';
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { ShareButtonMobile } from '../ShareButtonMobile';
import { ShareButtonWeb } from '../ShareButtonWeb';
import AiImage from '../../images/ai.gif';

const HomeScreen = () => {
    const [showWebToast, setShowWebToast] = useState(false);
    const [showAppToast, setShowAppToast] = useState(false);
    const [selectedValue, setSelectedValue] = useState(1000);

    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser } = useSelector((state: any) => state.currentUser);
    const { homeScreenLoading, homeScreen, homeScreenError } = useSelector((state: any) => state.homeScreenData);
    const { homeScreenWithDaysLoading, homeScreenWithDays, homeScreenWithDaysError } = useSelector((state: any) => state.homeScreenDataWithDays);

    useEffect(() => { initialValues(); }, [currentUser])

    const initialValues = async () => {
        const accessToken = await getAccessToken();
        const storeId = currentUser && currentUser.data ? currentUser.data[0].store_id : null;
        if(storeId){
            if(accessToken){
                await dispatch(getHomeScreenData(accessToken, storeId));
                await dispatch(getHomeScreenDataWithDays(accessToken, storeId, 1000));
            }else{
                await dispatch(authenticateUserWhenLogout())
                await localStorage.clear();
                history.push('/');
            }
        }
    }
    const map={'SCREEN.NONE':'/my/userdashboard',
        'SCREEN.HELP':'/my/help&support',
        'SCREEN.CUSTOMERS':'/my/customer',
        'SCREEN.LEARNGROW':'/my/ikacademy',
        'SCREEN.BANNERS':'/my/banners',
        'SCREEN.COUPONS':'/my/coupons',
        'SCREEN.CATALOG':'/my/catalogue',
        'SCREEN.SETTINGS.LOCALISATION':'/my/settings/localization',
        'SCREEN.SETTINGS.BASICINFO':'/my/settings/basicinfo'}
    useEffect(() => {
        changeStatusHelper();
    }, [selectedValue])

    const changeStatusHelper = async () => { 
        const storeId = currentUser && currentUser.data ? currentUser.data[0].store_id : null;
        if(currentUser && currentUser.data && storeId ){
            
            await dispatch(getHomeScreenDataWithDays(getAccessToken(), storeId, selectedValue)); 
        }  
    }

    const onSelectChange = async (e: any) => {
        setSelectedValue(e.detail.value);
        // const accessToken = await getAccessToken();
        // const storeId = currentUser && currentUser.data ? currentUser.data[0].store_id : null;
        // if (e.detail.value !== 0 && storeId) {
        //     if(accessToken){
        //         console.log('0')
        //         await dispatch(getHomeScreenDataWithDays(accessToken, storeId, e.detail.value));
        //     }else{
        //         await dispatch(authenticateUserWhenLogout())
        //         await localStorage.clear();
        //         history.push('/');
        //     }
        // }
    }

    const copyToClipboard = async (data: string) => {
        await Clipboard.write({
            string: `${data}`
        });
    };

    const shareWebsite = async () => {
        await Share.share({
            
        });
    }

    const shareBusinessApp = async () => {
        await Share.share({
            title: 'Intelikart Business App',
            text: `${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app ? homeScreenWithDays.data.app.message : "My Business App"}`,
            url: `${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app ? homeScreenWithDays.data.app.URL : ""}`
        });
    }
    return (
        <>
        <div className="dashboard-website">
                <IonCard className="">
                    <IonCardContent>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className="ion-no-padding ion-no-margin"  size="10">
                                    <IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">Your Business Website</IonText>
                                </IonCol>
                                <IonCol size="2">
                                    <IonButton fill='clear'color='secondary'   onClick={e => { copyToClipboard(homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : "Not Available"); setShowWebToast(true); }} >
                                        <IonIcon  size="large" slot="icon-only" icon={copyIcon}  ></IonIcon> 
                                    </IonButton>
                                    {/* <IonIcon size="large" icon={copyIcon} onClick={(e) => { copyToClipboard(homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : "Not Available"); setShowWebToast(true); }}></IonIcon> */}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-no-padding ion-no-margin"  size="10">
                                    <IonText className="websitelink dashboard-fnt-sz">
                                        <a href={`${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : ""}`} target="_blank">
                                            {homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : ""}
                                        </a>
                                    </IonText>
                                </IonCol>
                                <IonCol size="2">
                                    <MobileView>
                                        <ShareButtonMobile title='Intelikart Business Website'
                                        text={`${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.message : "My Business Website"}`}
                                        url={`${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : ""}`}/>
                                    </MobileView>
                                    <BrowserView>
                                        <ShareButtonWeb url={homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : ""} text={`Please check out website for my business build using Intelikart.com ${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.website ? homeScreenWithDays.data.website.URL : ""}`}/>
                                    </BrowserView>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </div>
            {homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app && homeScreenWithDays.data.app.URL &&
            <div className="dashboard-website">
                <IonCard className="">
                    <IonCardContent>
                        <IonGrid className="ion-no-padding ion-no-margin">
                            <IonRow>
                                <IonCol className="ion-no-padding ion-no-margin"  size="10">
                                    <IonText className="websitelabel dashboard-fnt-sz ion-no-padding ion-no-margin">Your Branded App</IonText>
                                </IonCol>
                                <IonCol size="2">
                                    <IonIcon size="large" icon={copyIcon} onClick={(e) => { copyToClipboard(homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app ? homeScreenWithDays.data.app.URL : "Not Available"); setShowWebToast(true); }}></IonIcon>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="ion-no-padding ion-no-margin"  size="10">
                                    <IonText className="websitelink dashboard-fnt-sz">
                                        <a href={`${homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app ? homeScreenWithDays.data.app.URL : ""}`} target="_blank">
                                            <BrowserView>
                                            {homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app ? homeScreenWithDays.data.app.URL: ""}
                                            </BrowserView>
                                            <MobileView>
                                            {homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data && homeScreenWithDays.data.app ? homeScreenWithDays.data.app.URL.substring(0, 37)+".." : ""}

                                            </MobileView>
                                        </a>
                                    </IonText>
                                </IonCol>
                                <IonCol size="2">
                                    <MobileView>
                                        <IonIcon size="large" icon={shareIcon} onClick={(e) => shareBusinessApp()}></IonIcon>
                                    </MobileView>
                                    <BrowserView>
                                        <IonIcon size="large" icon={globeIcon}></IonIcon>
                                    </BrowserView>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
            </div>}
            
            {/* <div>
                <IonGrid>
                    <IonRow>
                        <IonCol size="6">
                            <IonButton color="warning" className="dashboard-cta">Get Custom Domain</IonButton>
                        </IonCol>
                        <IonCol size="6">
                            <IonButton  color="warning" className="dashboard-cta">Get Android App</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div> */}
            <IonItem lines="none">
                <IonText>Your store status</IonText>
                <IonSelect slot="end" onIonChange={(e) => onSelectChange(e)} value={selectedValue}>
                    <IonSelectOption value={1000}>Anytime</IonSelectOption>
                    <IonSelectOption value={1}>Today</IonSelectOption>
                    <IonSelectOption value={7}>Weekly</IonSelectOption>
                    <IonSelectOption value={30}>Monthly</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonCard className="ion-no-margin ion-no-padding" routerLink="/my/orders">
                            <IonCardHeader>
                                <IonText className="dashboard-fnt-sz">Your Orders</IonText>
                                <IonCardTitle className="fnt-sz28">{homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.orders : null}</IonCardTitle>

                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                    <IonCol>
                        <IonCard className="ion-no-margin ion-no-padding" routerLink="/my/customer">
                            <IonCardHeader>
                                <IonText className="dashboard-fnt-sz">Your Customers</IonText>
                                <IonCardTitle className="fnt-sz28">{homeScreenWithDaysLoading === false && homeScreenWithDays && homeScreenWithDays.data ? homeScreenWithDays.data.users : null}</IonCardTitle>

                            </IonCardHeader>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonGrid>
                <IonRow>
                    <IonCol>
                   {homeScreen.data && homeScreen.data.touch_target && <IonCard style={{margin: 0}} routerLink={`${map[homeScreen.data.touch_target]}`}>
                            <IonGrid style={{backgroundColor: 'rgba(56, 128, 255, 0.14)'}}>
                                <IonRow>
                                    <IonCol size="2">
                                    <IonImg  src={AiImage} className="aiimage"/>
                                        </IonCol>
                                    <IonCol>
                                        <IonText color='primary'>
                                            <h6 style={{margin: 4+'px', marginLeft: 0}}>{homeScreenLoading === false && homeScreen && homeScreen.data ? homeScreen.data.heading : ""}</h6>
                                            {homeScreenLoading === false && homeScreen && homeScreen.data ? homeScreen.data.subheading : ""}
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </IonCard>}
                    </IonCol>
                </IonRow>
            </IonGrid>
            <div className="dashborad-grid-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="4">
                            <IonCard className="ion-no-margin ion-no-padding ion-text-center" routerLink='/my/products'>
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon size="large" icon={eyeOutline}></IonIcon></IonCardTitle>
                                    <IonText>View Your Products</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="ion-no-margin ion-no-padding ion-text-center" routerLink="/my/products/create/true">
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon size="large" icon={bagAddOutline}></IonIcon></IonCardTitle>
                                    <IonText>Add Products +</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="ion-no-margin ion-no-padding ion-text-center" routerLink="/my/catalogue/category/add/false">
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon size="large" icon={gridOutline}></IonIcon></IonCardTitle>
                                    <IonText>Add Category +</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <div className="dashborad-grid-container">
                <IonGrid>
                    <IonRow>
                        <IonCol size="4">
                            <IonCard className="ion-no-margin ion-no-padding ion-text-center" href='https://manage.intelikart.com' target="_blank">
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon size="large" icon={globeOutline}></IonIcon></IonCardTitle>
                                    <IonText>Web Store Manager</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="ion-no-margin ion-no-padding ion-text-center" href='https://play.google.com/store/apps/details?id=com.intelikart.deliverydriverapp' target="_blank">
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon size="large" icon={manOutline}></IonIcon></IonCardTitle>
                                    <IonText>Driver Application</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                        <IonCol size="4">
                            <IonCard className="ion-no-margin ion-no-padding ion-text-center" routerLink="/my/settings">
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon size="large" icon={colorPaletteOutline}></IonIcon></IonCardTitle>
                                    <IonText>Change Theme</IonText>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            {/* <IonGrid className="ion-text-center">
                <IonRow>
                    <IonCol className="ion-padding">
                        <IonText  color="primary">More Settings</IonText>
                    </IonCol>
                </IonRow>
            </IonGrid> */}
            

            <IonToast
                isOpen={showWebToast}
                onDidDismiss={() => setShowWebToast(false)}
                message="Website URL copied"
                duration={500}
            />
            <IonToast
                isOpen={showAppToast}
                onDidDismiss={() => setShowAppToast(false)}
                message="App URL copied"
                duration={500}
            />
            <IonLoading
                isOpen={homeScreenLoading === true || homeScreenWithDaysLoading === true}
                message={'Please Wait...'}
            />
        </>
    )
}

export default HomeScreen
