import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonAlert,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { Camera,CameraResultType } from '@capacitor/camera';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { getAccessToken } from '../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import {deleteStoresCategory,editStoresCategory, createStoresCategory, getStoresCategory } from '../actions/categoryAction';
import { setPriority } from 'os';

interface RouteParams{
  id:string;
}

async function savePicture(file) {
  if(!file)return null;
  try{
    const formData = new FormData();
    formData.append("file",file)
    const response = await fetch(`https://api.intelikart.com/api/upload/category-image`, {
      method: 'POST',
      body:formData
    });
  const data = await response.json();
  return data.platform_path;
  }
  catch(error){
    return "";
  }
}

const UpdateCategoryPage: React.FC = () => {
  //const { userId } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const {id}=useParams<RouteParams>();
  const { currentUser } = useSelector((state : any)=> state.currentUser);
  const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);
  const { editCategoryLoading, editCategory, editCategoryError } = useSelector((state : any)=> state.categories);
  const { deleteCategoryLoading, deleteCategory, deleteCategoryError } = useSelector((state : any)=> state.categories);
  const [date, setDate] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [parentCategory, setParentCategory] = useState(0);
  const [pictureUrl, setPictureUrl] = useState('https://cdn.intelikart.com/uploads/new-images/default-store.png');
  const[iconCode,setIconCode]=useState('')
  const[status,setStatus]=useState('active')
  const[imageFile,setImageFile]=useState(null)
  const fileInputRef = useRef<HTMLInputElement>();
  const[loading,setLoading]=useState(false);
  const categoryNameInputRef = useRef(null);

  const [present] = useIonAlert();
  useIonViewWillEnter(()=>{
    let category
    if(categories && categories[0]){
      category=categories.find(it=>it.id==id)
      if(!category){
        for(let i=0;i<categories.length;i++){
          category=categories[i].subCategory.find(el=>el.id==id)
          if(category)break
        }
      }
    } 
    if(!category)history.push('/my/catalogue');
    else{
      setCategoryName(category.name)
      if(category.icon_code){
        setPictureUrl(category.icon_code_internet_url)
      }
      setIconCode(category.icon_code)
      setParentCategory(category.parent_id)
    }
    
  }) 
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length > 0) {
      const file = event.target.files.item(0);
      setImageFile(file)
      const pictureUrl = URL.createObjectURL(file);
      setPictureUrl(pictureUrl);
    }
  };
  const handlePictureClick= async()=>{
    
    if(isPlatform('capacitor')){
      try{
        const photo=await Camera.getPhoto({
          resultType:CameraResultType.Uri,
        })
        setPictureUrl(photo.webPath);
      }
      catch(error){
        console.log(error);
      }
    }
    else{
      fileInputRef.current.click();
    }
    
  }

  
  const handleSave=async ()=>{
      setLoading(true)
      const accessToken = await getAccessToken();
      if(accessToken && currentUser && currentUser.data){
          let platform_path= await savePicture(imageFile)
          if(!platform_path)platform_path=iconCode
          await dispatch(editStoresCategory(accessToken, currentUser.data[0].store_id, {
          "id": id,
          "parent_id":parentCategory ,
          "name": categoryName,
          "store_id": `${currentUser.data[0].store_id}`,
          "priority": '',
          "icon_code": platform_path,
          "status": status
        }));
        await dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id))
        setLoading(false)
        history.goBack();
        
    }
  }
  const onClickDeleteCategory=async (id)=>{
    let category=categories.find(it=>it.id==id)
    if(!category){
      for(let i=0;i<categories.length;i++){
        category=categories[i].subCategory.find(el=>el.id==id)
        if(category)break
      }
    }
    
    if(category.subCategory[0]){
     alert("Please delete sub categories first")
    }
    else{
      const accessToken = await getAccessToken();
      setLoading(true)
      await dispatch(deleteStoresCategory(id,accessToken,currentUser.data[0].store_id))
      await dispatch(getStoresCategory(accessToken,currentUser.data[0].store_id))
      
      setLoading(false)
      history.goBack();
    }

}
   
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            
            <IonBackButton  defaultHref="/my/catalogue"/>
          </IonButtons>
          <IonTitle>Update Category</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding"> 
         
          <IonItem>
            <IonLabel position="stacked" >Category Name</IonLabel>
            <IonInput required value={categoryName} ref={categoryNameInputRef}
              onIonChange={(event) => setCategoryName(event.detail.value)}
            />
          </IonItem>
          {/* <IonItem>
          <IonLabel>Choose Parent Category</IonLabel>
            <IonSelect value={parentCategory} placeholder="Select One" onIonChange={e=>setParentCategory(e.detail.value)}>
              <IonSelectOption  value={0}>Parent Category</IonSelectOption>
              {categories && categories.map(op=>
                <IonSelectOption key={op.name} value={op.id}>{op.name} </IonSelectOption>
              )}
              
            </IonSelect>
          </IonItem> */}
          <IonItem lines='none' className='ion-padding-top'>
          
            <input  hidden type='file' accept="image/*" ref={fileInputRef}
            onChange={handleFileChange}/>
            <IonCol >
              <img width="100%" height="200px"  src={pictureUrl} alt="" ></img>
            </IonCol>
            <IonCol>
              <IonButton  shape="round"  onClick={handlePictureClick}>Update Image</IonButton>
            </IonCol>
            </IonItem>

         
        <div className="ion-text-center ion-padding">
        <IonButton className="ion-padding-horizontal" onClick={() =>
                    present({
                    cssClass: 'my-css',
                    header: 'Delete Category',
                    message: 'Are you sure you want to delete category?',
                    buttons: [
                      'Cancel',
                      { text: 'Yes', handler: (d) => onClickDeleteCategory(id) },
                    ]
                    })
                  } shape="round" color='danger' >Delete</IonButton>
      
      <IonButton shape="round" disabled={categoryName==""?true:false} onClick={handleSave}>Save</IonButton>
      <IonLoading isOpen={ loading } message={'Updating category'}/>
      </div>
      </IonContent>
      
    </IonPage>
  );
};

export default UpdateCategoryPage;

