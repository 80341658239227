import React, { useEffect, useState } from 'react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTube from 'react-youtube';
import { IonAlert, IonAvatar ,IonButton, IonCard, IonCardHeader, IonCardTitle, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import {
    closeCircleOutline,
    search as serachIcon,
    add as addIcon,
    createSharp as createSharpIcon,
    trashSharp as trashSharpIcon,
    addOutline as addOutlineIcon,
    addCircle as addCircleIcon
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { getStoresCategory } from '../../actions/categoryAction';
import { getProductsList } from '../../actions/dashboardActions';
import { Link } from 'react-router-dom';
import { deleteProduct, searchQueryProduct } from '../../actions/productActions';
import Title from '../dashboard/Header';
// install Swiper modules
SwiperCore.use([Navigation]);

const ProductsHomeScreen = (props:any) => {
    //states for search product by name as query
    const [query, setQuery] = useState<any>("");
    const [isQueryValidate, setIsQueryValidate] = useState(true);


    //states for displaying initial products and load more products.
    const [filterArrayObjectChange, setFilterArrayObjectChange] = useState(1)
	const[filterArrayObject,setFilterArrayObject]=useState({});
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(true);
    const [subcategoryId, setSubcategoryId] = useState<any>();
    const [subcategoryName, setSubcategoryName] = useState<any>();
    const [page, setPage] = useState<any>({});

    //states for product short info
    const [checkedProducts, setCheckedProducts] = useState<any>({});
    const [isAnyProductChecked, setisAnyProductChecked] = useState(false);
    const [checkedProductCount, setCheckedProductCount] = useState(0);
    const [showAlertForMulti, setShowAlertForMulti] = useState(false);
    const [showAlertForSingle, setShowAlertForSingle] = useState(false);
    const [deletedProductId, setDeletedProductId] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isProductDeleted, setIsProductDeleted] = useState(1);
    const [isProductAdded, setIsProductAdded] = useState(1);
    const [isProductUpdate, setIsProductUpdate] = useState(1);
    const [searchResults,setSearchResults]=useState<any>()

    var idFromProps;
    var cateIdFromProps;
    if(props && props.location && props.location.state){
        idFromProps = props.location.state;
        if(idFromProps.id){
            cateIdFromProps = idFromProps.id;
        }
    }

    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { fetchCategoriesLoading, categories, fetchCategoriesError } = useSelector((state : any)=> state.categories);

    const { productsBySubcategoryLoading, productsBySubcategory, productsBySubcategoryError } = useSelector((state : any)=> state.productListBySubcategory);

    const {deleteProductLoading} = useSelector((state : any)=> state.deleteProductData);

    const { createProductLoading, createProductMessage, createProductError } = useSelector((state : any)=> state.createProducData);

    const { editProductLoading, editProductMessage, editProductError } = useSelector((state : any)=> state.editProductData);

    const {searchProductQueryLoading, searchProductQueryMessage, searchProductQueryError} = useSelector((state : any)=> state.searchQueryProductData);

    const dispatch = useDispatch();

    //youtube option
    const opts = {
        height: '390',
        width: '100%'
    };

    //for reloading when new product is added
    useEffect(() => {
        if(createProductLoading === false && createProductMessage && createProductMessage[0] && createProductMessage[0].category_id){
            page[`${createProductMessage[0].category_id}`] = 1;
            let temp = filterArrayObject;
            temp[`${createProductMessage[0].category_id}`] = [];
            setFilterArrayObject(temp);
            setFilterArrayObjectChange(filterArrayObjectChange + 1);
            setIsProductAdded(isProductAdded + 1)
        }
    }, [createProductLoading]);

    //for reloading when any product is edited
    useEffect(() => {
        if(Boolean(editProductLoading === false && editProductMessage && editProductMessage[0] && editProductMessage[0].category_id)){
            page[`${editProductMessage[0].category_id}`] = 1;
            let temp = filterArrayObject;
            temp[`${editProductMessage[0].category_id}`] = [];
            setFilterArrayObject(temp);
            setFilterArrayObjectChange(filterArrayObjectChange + 1);
            setIsProductUpdate(isProductUpdate + 1)
        }
    }, [editProductLoading]);

    useEffect(() => {
        if(searchProductQueryLoading === false){
            setSearchResults(searchProductQueryMessage.data)
        }
    }, [searchProductQueryLoading]) 

    useEffect(() => {
        if(fetchCategoriesLoading === false && categories[0] && categories[0].subCategory && categories[0].subCategory[0]){
            setSubcategoryId(categories[0].subCategory[0].id);
            setSubcategoryName(categories[0].subCategory[0].name);
        }
    }, [fetchCategoriesLoading]);

    useEffect(()=>{
        if(!categories && getAccessToken() && currentUser && currentUser.data){
            dispatch(getStoresCategory(getAccessToken(), currentUser.data[0].store_id));
        }
    },[currentUser]);

    //dynamic content
    //when subcategory change then get their product for first time.
    useEffect(() => {
        if(currentUser){
        setCheckedProducts({});
        setisAnyProductChecked(false);
        setCheckedProductCount(0);
        fetchData();
        }
    }, [subcategoryId, isProductDeleted, isProductAdded, isProductUpdate, currentUser]);

    useEffect(()=>{
		if(productsBySubcategoryLoading === false){
			if(productsBySubcategory && productsBySubcategory.data && productsBySubcategory.data.length > 0){
				setDisableInfiniteScroll(Boolean(productsBySubcategory.data.length < 20));
			}else{
				setDisableInfiniteScroll(true);
			}
		}
        if(productsBySubcategoryLoading === false && productsBySubcategory && productsBySubcategory.data){
            if(productsBySubcategory.data.length > 0){
                const op = productsBySubcategory.data.reduce(function(acc, curr){
                    if(acc[subcategoryId]){
                        acc[subcategoryId].push(curr)
                    }
                    else{
                        acc[subcategoryId]=[]
                        acc[subcategoryId].push(curr)
                    }
                    return acc;
                },filterArrayObject)
                setFilterArrayObjectChange(filterArrayObjectChange+1);
                setFilterArrayObject(op);
            }
            if(productsBySubcategory.data.length === 0){
                let temp = filterArrayObject;
                temp[subcategoryId] = [];
                setFilterArrayObjectChange(filterArrayObjectChange+1);
                setFilterArrayObject(temp);
            }
        }
	},[productsBySubcategoryLoading])

    useEffect(() => {
        if(idFromProps && props.location.state){
            setSubcategoryId(idFromProps.id);
            setSubcategoryName(idFromProps.name);
        }
    }, [idFromProps, cateIdFromProps])
	
	const fetchData = async()=>{
        if(subcategoryId && currentUser && currentUser.data[0] && !(subcategoryId in page)){
            setDisableInfiniteScroll(false);
            await dispatch(getProductsList(subcategoryId, currentUser.data[0].store_id));
            let temp = page;
            temp[`${subcategoryId}`] = 2;
            setPage(temp);
        }
        if(subcategoryId && currentUser && currentUser.data[0] && (subcategoryId in page) && page[`${subcategoryId}`] === 1){
            setDisableInfiniteScroll(false);
            await dispatch(getProductsList(subcategoryId, currentUser.data[0].store_id));
            let temp = page;
            temp[`${subcategoryId}`] = 2;
            setPage(temp);
        }
	}

    useEffect(() => {}, [filterArrayObjectChange]);
    useIonViewWillEnter(() => { 
        setSearchResults(null)
        setQuery("")
    });

    async function searchNext($event: CustomEvent<void>) {
		await fetchNextData();
		($event.target as HTMLIonInfiniteScrollElement).complete();
	}

	const fetchNextData = async()=>{
        if((subcategoryId in filterArrayObject) && filterArrayObject[`${subcategoryId}`].length === 20){
        let res = await fetch(`https://api.intelikart.com/api/search/products?page=${page.subcategoryId}&categoryId=${subcategoryId}&storeId=${currentUser.data[0].store_id}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json"
            }
        });

		res.json().then(async (res) => {
            if (res && res.data && res.data.length > 0) {
                const op = res.data.reduce(function(acc,curr){
                    if(acc[subcategoryId]){
                        acc[subcategoryId].push(curr)
                    }
                    else{
                        acc[subcategoryId]=[]
                        acc[subcategoryId].push(curr)
                    }
                    return acc;
                },filterArrayObject);
                setFilterArrayObject(op);
                setFilterArrayObjectChange(filterArrayObjectChange+1);
                setDisableInfiniteScroll(res.data.length < 20);
                let temp = page;
                temp[`${subcategoryId}`] = temp[`${subcategoryId}`] + 1;
                setPage(temp);
            } else {
                setDisableInfiniteScroll(true);
            }
		}).catch(err => console.error(err));
    }
	}

    //functions for product short info
    const onCheckedClicked = async(productId) =>{
        if((`${productId}` in checkedProducts)){
            let temp = checkedProducts;
            temp[`${productId}`] = !(temp[`${productId}`]);
            if((temp[`${productId}`])){
                await setCheckedProductCount(checkedProductCount + 1);
            }else{
                await setCheckedProductCount(checkedProductCount - 1);
            }
            await setCheckedProducts(temp);
        }else{
            let temp = checkedProducts;
            temp[`${productId}`] = true;
            await setCheckedProductCount(checkedProductCount + 1);
            await setCheckedProducts(temp);
        }
        for (const value in checkedProducts) {
            if(checkedProducts[value]){
                setisAnyProductChecked(true);
                break;
            }else{
                setisAnyProductChecked(false);
            }
        }
    }

    const deleteAllSelected = async ()=>{
        if(getAccessToken() && currentUser){
            setLoading(true);
            for(const value in checkedProducts){
                if(checkedProducts[value]){
                    await dispatch(deleteProduct(getAccessToken(), currentUser.data[0].store_id, value));
                }
            }
            page[`${subcategoryId}`] = 1;
            let temp = filterArrayObject;
            temp[`${subcategoryId}`] = [];
            setFilterArrayObject(temp);
            setFilterArrayObjectChange(filterArrayObjectChange + 1);
            setCheckedProducts({});
            setisAnyProductChecked(false);
            setCheckedProductCount(0);
            setLoading(false);
            setIsProductDeleted(isProductDeleted+1);
        }
    }

    const deleteSingleProduct = async ()=>{
        if(getAccessToken() && currentUser && deletedProductId){
            await dispatch(deleteProduct(getAccessToken(), currentUser.data[0].store_id, deletedProductId));
            page[`${subcategoryId}`] = 1;
            let temp = filterArrayObject;
            temp[`${subcategoryId}`] = [];
            setFilterArrayObject(temp);
            setFilterArrayObjectChange(filterArrayObjectChange + 1);
            setIsProductDeleted(isProductDeleted+1);
        }
    }

    const searchProductByName = async (e)=>{
        setQuery(e.detail.value)
        setSearchResults(null)
        if(e.detail.value.length ==0){
            return
        }
        else  {
            setIsQueryValidate(true);
            if( currentUser && currentUser.data[0]){
               await dispatch(searchQueryProduct(e.detail.value, currentUser.data[0].store_id))
            }
        } 
    }
    const onSearchCancel=e=>{
        setSearchResults(null)
        setQuery("")
    }

    return (
        <IonPage>
            <Title name="Catalogue"/>
            <IonSegment className="fixed" value="products">
                    <Link style={{textDecoration: 'none'}} to="/my/catalogue">
                        <IonSegmentButton>
                            <IonLabel>Categories</IonLabel>
                        </IonSegmentButton>
                    </Link>
                    <Link style={{textDecoration: 'none'}} to="#">
                    <IonSegmentButton value="products">
                        <IonLabel>Products</IonLabel>
                    </IonSegmentButton>
                    </Link>
            </IonSegment>
            <IonContent>
            {fetchCategoriesLoading === false && categories[0] && categories[0].subCategory && categories[0].subCategory[0] ? <>
            <IonItem className="ion-margin">
                <IonLabel><IonIcon icon={serachIcon}></IonIcon></IonLabel>
                <IonInput placeholder="Search Product" value={query} onIonChange={e => searchProductByName(e)}></IonInput>
                {searchResults   ?<IonIcon icon={closeCircleOutline} onClick={e=>onSearchCancel(e)}></IonIcon>:""}
            </IonItem> 
            <IonList> 
                {searchResults ? searchResults[0] ? searchResults.map((item,idx)=>(
                    <IonItem key={idx}>
                        <Link key={idx} style={{textDecoration:'none'}} to={{ pathname:`/my/products/update/${item.id}`, state: {id: subcategoryId, name: subcategoryName}  }}>                            <IonItem lines="none">
                        <IonAvatar slot="start">
                            <img src={item.variants[0].image_url_thumb}/>
                        </IonAvatar>
                        <div className=" ion-margin-top  " >
                            <IonText>{item.name} </IonText>
                            <IonText color="medium">({item.variants[0].quantity} {item.variants[0].quantity_unit_name})</IonText>
                            <br/>
                            {item.variants[0].mrp != 0 &&  item.variants[0].mrp != null  ?  <b>Rs. {item.variants[0].mrp}&nbsp;</b>:"" } 
                            {item.variants[0].display_mrp != 0   && item.variants[0].display_mrp != null ? <del>Rs. {item.variants[0].display_mrp}</del>:""}
                            {/* {item.variants[0].display_mrp == 0 || item.variants[0].display_mrp == null &&  <b>Rs. {item.variants[0].mrp}</b> } */}

                        </div>
                        </IonItem>
                        </Link>
                    </IonItem>
                    
                )     
                )
                :
                <IonText color="danger" className={`${searchProductQueryLoading === true ? "ion-hide": "" }`}>
                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">No product found</h5>
                </IonText>:''}
            </IonList>
              
            <IonText color="danger" className={`${isQueryValidate === true ? "ion-hide": "" }`}>
                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Product name must have minimum 3 characters.</h5>
            </IonText>

            <div className="ion-text-center">
                <Link to={`/my/products/create/${true}`} style={{textDecoration: 'none'}}><IonButton shape="round"><IonIcon icon={addIcon}></IonIcon> Add Products</IonButton></Link>
            </div>
            
            {fetchCategoriesLoading === false && categories && categories[0].subCategory && categories[0].subCategory[0]  && <IonText><h5 className="ion-no-margin ion-margin-horizontal ion-margin-top"><b>Select Subcategory</b></h5></IonText>}
            <Swiper navigation={true} spaceBetween={0} slidesPerView={'auto'}>
                {fetchCategoriesLoading === false && categories ? categories.map((parent)=> parent.subCategory.map((child)=>{
                    return (<SwiperSlide style={{width: 130+'px', height: 190+'px'}}>
                        <IonCard style={{ cursor:'pointer',width: 120+'px', height: 150+'px', backgroundColor: `${child.id === subcategoryId || child.name === subcategoryName ? 'rgba(56, 128, 255, 0.14)': ''}`}} key={child.id} onClick={e=> {setSubcategoryId(child.id); setSubcategoryName(child.name);}}>
                            <IonItem>
                                <IonImg style={{width:100+'%', height:90+'px'}} src={child.icon_code_internet_url}/>
                            </IonItem>
                                <div className="ion-text-center ion-margin-top" style={{color: `${child.id === subcategoryId || child.name === subcategoryName ? '#0804f9': ''}` }} ><span>{child.name.length > 23 ? `${(child.name).substring(0,24)}...` : `${child.name}`}</span></div>
                        </IonCard>
                    </SwiperSlide>)
                }))
                : "" }
            </Swiper>
            {fetchCategoriesLoading === false && categories && categories[0].subCategory && categories[0].subCategory[0] && <IonText><h5 className="ion-no-margin ion-margin-horizontal"><b>{subcategoryName} Products </b></h5></IonText>}

            { filterArrayObject[subcategoryId] && filterArrayObject[subcategoryId].length > 0 ?
            <IonGrid>
                {/* <IonButton   onClick={e=>setShowAlertForMulti(true)} expand="full" color="danger" className={`${isAnyProductChecked ? '' : 'ion-hide'}`}><IonIcon icon={trashSharpIcon}></IonIcon> &nbsp; Delete all selected</IonButton> */}
                <IonRow>
                { filterArrayObject[subcategoryId].map((value)=>{
                    return (
                        <IonCol size="6" sizeSm="5" sizeMd="4" sizeLg="3" key={value.id}>
                            <IonCard  >
                            <Link to={{ pathname: `/my/products/update/${value.id}`, state: {id: subcategoryId, name: subcategoryName} }}>
                                <IonImg src={value.variants[0].image_url_original} style={{width: 100+'%', height: 110+'px'}} className="ion-margin-vertical"/>
                            </Link>
                                        
                                <div className="ion-margin-top ion-text-center" >
                                    <b>{value.name}</b>
                                    <br/>
                                    <IonText color="medium">({value.variants[0].quantity} {value.variants[0].quantity_unit_name})</IonText>
                                    <br/>
                   
                                    {value.variants[0].mrp != 0 &&  value.variants[0].mrp != null  ?  <b>Rs. {value.variants[0].mrp}&nbsp;</b>:"" } 
                                    {value.variants[0].display_mrp != 0   && value.variants[0].display_mrp != null ? <del>Rs. {value.variants[0].display_mrp}</del>:""} 
                            </div>
                                <br/>
                                <div className={`${!isAnyProductChecked ? '' : 'ion-hide'}`}>
                                    <Link to={{ pathname: `/my/products/update/${value.id}`, state: {id: subcategoryId, name: subcategoryName} }}>
                                        <IonButton style={{marginLeft: 10+'px'}}><IonIcon icon={createSharpIcon}/> </IonButton>
                                    </Link>
                                    <IonButton className="ion-float-end" style={{marginRight: 10+'px'}} onClick={e=>{ setShowAlertForSingle(true); setDeletedProductId(`${value.id}`)}} color="danger"><IonIcon icon={trashSharpIcon}/> </IonButton>
                                </div>
                                <div className="ion-text-center ion-margin-bottom ion-margin-top">
                                    <IonCheckbox checked = {checkedProducts ? ((value.id in checkedProducts) ? checkedProducts[`${value.id}`] : false) : false } onClick={e=> onCheckedClicked(value.id)}/>
                                </div>
                            </IonCard>
                        </IonCol>
                    )
                    })}
                    <IonCol size="6" sizeSm="5" sizeMd="4" sizeLg="3" key="000001">
                            <IonCard style={{height: 300+'px'}}>
                                <Link to={{ pathname: `/my/products/create/${true}`, state: {id: subcategoryId, name: subcategoryName} }} style={{textDecoration: 'none'}}>
                                    <div style={{width: 100+'%', height: 100+'%', border: '5px dashed #3880ff'}} className="ion-text-center">
                                        <IonIcon style={{height: 100+'px', width: 100+'px', marginTop:40+'px'}} icon={addCircleIcon} />
                                        <h5 className="ion-margin">Add Product in {subcategoryName}</h5>
                                    </div>
                                </Link>
                            </IonCard>
                        </IonCol>
                </IonRow>
            </IonGrid>
            : <IonGrid>
                <IonRow>
                    <IonCol size="6" sizeSm="5" sizeMd="4" sizeLg="3" key="000001">
                        <IonCard style={{height: 300+'px'}}>
                            <Link to={{ pathname: `/my/products/create/${true}`, state: {id: subcategoryId, name: subcategoryName} }} style={{textDecoration: 'none'}}>
                                <div style={{width: 100+'%', height: 100+'%', border: '5px dashed #3880ff'}} className="ion-text-center">
                                    <IonIcon style={{height: 100+'px', width: 100+'px', marginTop:40+'px'}} icon={addCircleIcon} />
                                    <h5 className="ion-margin">Add Product in {subcategoryName}</h5>
                                </div>
                            </Link>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid> }


            <IonAlert
                isOpen={showAlertForMulti === true}
                onDidDismiss={() => setShowAlertForMulti(false)}
                header={'Delete Selected Products'}
                message={`You are about to delete ${checkedProductCount} products. Are you sure?`}
                buttons={[
                    {
                        text: 'CANCEL',
                        role: 'cancel'
                    },
                    {
                        text: 'DELETE',
                        handler: () => { deleteAllSelected(); }
                    }
                ]}
            />
            <IonAlert
                isOpen={showAlertForSingle === true}
                onDidDismiss={() => setShowAlertForSingle(false)}
                header={'Delete Product'}
                message={`Are you sure?`}
                buttons={[
                    {
                        text: 'CANCEL',
                        role: 'cancel'
                    },
                    {
                        text: 'DELETE',
                        handler: () => { deleteSingleProduct(); }
                    }
                ]}
            />

            <IonInfiniteScroll disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                <IonInfiniteScrollContent
                    loadingText="Loading more products...">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>
            </>:
            <div>
            <IonRow>
                <IonCol className="ion-text-center" >
                    <Link to={`/my/products/create/${true}`}>
                        <IonButton>
                            <IonIcon  icon={addOutlineIcon}/>
                            Add New Product
                        </IonButton> 
                    </Link>
                </IonCol>
            </IonRow>
             <IonCard className="ion-text-center ion-no-padding" >
                <IonCardHeader>
                    <IonCardTitle>Watch video to understand how to add products.</IonCardTitle>
                </IonCardHeader>
                <YouTube videoId='dkOvPjF41YA' opts={opts}/> 
            </IonCard>

        </div>
            }
            <IonLoading isOpen={productsBySubcategoryLoading === true || fetchCategoriesLoading === true || loading === true || deleteProductLoading === true  } message="Please Wait"/>
        </IonContent>
        <IonButton   onClick={e=>setShowAlertForMulti(true)}  expand="full" color="danger" className={`${isAnyProductChecked ? '' : 'ion-hide'}`}><IonIcon icon={trashSharpIcon}></IonIcon> &nbsp; Delete all selected</IonButton>

    </IonPage>
    )
}

export default ProductsHomeScreen
