import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonRadioGroup, IonItem, IonRadio, IonInput, IonButton, IonGrid, IonRow, IonCol, IonPage } from '@ionic/react';
import { createStaff, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';

const CreateStaffPage = () => {
    const [name, setName] = useState<string | null | undefined>("");
    const [email, setEmail] = useState<string | null | undefined>("");
    
    //validation
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isNameValidate, setIsNameValidate] = useState(true);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isEmailValidate, setIsEmailValidate] = useState(true);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { createStaffMessageLoading, createStaffMessage, createStaffMessageError } = useSelector((state : any)=> state.createStaffData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    const emailValidation = ()=>{
        let result = true;
        if(!email){
            setIsEmailEmpty(true);
            result = false;
        }else{
            setIsEmailEmpty(false);
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                setIsEmailValidate(false)
                result = false;
            }else{
                setIsEmailValidate(true)
            }
        }
        return result;
    }

    const nameValidation = ()=>{
        let result = true;
        if(!name){
            setIsNameEmpty(true);
            result = false;
        }else{
            setIsNameEmpty(false);
            if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~01234567899]/.test(name)){
                setIsNameValidate(false);
                result = false;
            }
            else
                setIsNameValidate(true);
        }
        return result;
    }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const accessToken = await getAccessToken();
        const res1 = await nameValidation();
        const res2 = await emailValidation();
        if( res1 && res2 ){
            console.log(res2);
            if(currentUser && currentUser.data)
            await dispatch(createStaff(accessToken, currentUser.data[0].store_id,
                {
                name: `${name}`,
                email: `${email}`,
            }));
            setLoading(false);
            if(loading === false && createStaffMessage){
                history.goBack();
            }
        }
        setLoading(false);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/staffs" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Create Staff</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Name</h1></IonLabel>
                        <br/>
                        <IonInput placeholder="Enter Staff Name" type="text" value={name} name="name" onIonChange={(e)=>{ setName(e.detail.value)}}/>
                    </IonItem>
                    <IonText color="danger" className={`${isNameEmpty === false && isNameValidate === true ? "ion-hide": "" }`}>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isNameEmpty ? "" : "ion-hide"}`}>Name is required.</h5>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isNameValidate ? "ion-hide" : ""}`}>Name can't contain special characters or numbers.</h5>
                    </IonText>
                    <br/>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Email</h1></IonLabel>
                        <br/>
                        <IonInput placeholder="Enter Staff Email" type="text" value={email} name="email" onIonChange={(e)=>{ setEmail(e.detail.value)}}/>
                    </IonItem>
                    <IonText color="danger" className={`${isEmailEmpty === false && isEmailValidate === true ? "ion-hide": "" }`}>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isEmailEmpty ? "" : "ion-hide"}`}>Email is required.</h5>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isEmailValidate ? "ion-hide" : ""}`}>Email is invalid.</h5>
                    </IonText>
                    <br/>
                    <IonButton type="submit">Create Staff</IonButton>
                </form>
                <IonLoading isOpen={loading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default CreateStaffPage
