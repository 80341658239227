import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonImg, IonInput, IonPage, isPlatform } from '@ionic/react';
import { getBanners,createBanner, deleteBanner, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import {
    trash as trashIcon,
    shareSocial as shareSocialIcon
} from 'ionicons/icons';
import 'react-image-crop/dist/ReactCrop.css';
import { Share } from '@capacitor/share';
import 'react-image-crop/dist/ReactCrop.css';
import { Link } from 'react-router-dom';

const Banners = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const { bannersLoading, banners, bannersError } = useSelector((state : any)=> state.banners);
    const { deleteBannerMessageLoading, deleteBannerMessage, deleteBannerMessageError } = useSelector((state : any)=> state.deleteBannerData);
    const { addBannerLoading, addBanner, addBannerError } = useSelector((state : any)=> state.addBannerData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    useEffect(() => {
        if(currentUser && currentUser.data)
            dispatch(getBanners(getAccessToken(), currentUser.data[0].store_id));
    }, [currentUser]);

    useEffect(() => {
        if(currentUser && currentUser.data && deleteBannerMessageLoading==false)
            dispatch(getBanners(getAccessToken(), currentUser.data[0].store_id));
    }, [deleteBannerMessageLoading]);

    useEffect(() => {
        if(currentUser && currentUser.data && addBannerLoading==false)
            dispatch(getBanners(getAccessToken(), currentUser.data[0].store_id));
    }, [addBannerLoading]);

    const onBannerDeleteClick = async (value: any)=>{
        if(currentUser && currentUser.data && value)
            await dispatch(deleteBanner(getAccessToken(), currentUser.data[0].store_id, value.id));
    }

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Banners"/>
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center ion-margin-bottom">
                        <h5 className="ion-no-margin"><strong>Manage banners for your app and website</strong></h5>
                        <p>Images of 1440 * 450 px are recommended</p>
                    </IonText>
                    {
                        bannersLoading === false && banners && banners.data && banners.data.length !== 0 ? 
                        banners.data.map((value: any)=>{
                            return (
                                <IonCard key={value.id} className="ion-no-padding">
                                    <IonItem style={{paddingTop: 16+'px'}} lines='none'>
                                        <IonImg src={value.image_link}/>
                                    </IonItem>
                                    <IonItem className="ion-no-margin ion-no-padding">
                                        <IonIcon slot="end" size="large" icon={trashIcon} onClick={(e)=> {setAlertData(value); setShowAlert(true)}}></IonIcon>
                                    </IonItem>
                                </IonCard>
                            );
                        }) : null
                    }
                    <div className="ion-text-center">
                        <Link to="/my/addbanner">
                        <IonButton shape="round">ADD BANNER</IonButton>
                        </Link>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Banner'}
                        message={`Are you sure you want to delete this banner?`}
                        buttons={[
                            {
                                text: 'NO',
                                role: 'cancel'
                            },
                            {
                                text: 'YES',
                                handler: () => { onBannerDeleteClick(alertData); }
                            }
                        ]}
                    />

                    <IonLoading isOpen={loading || bannersLoading === true || deleteBannerMessageLoading === true || addBannerLoading === true} message="Please Wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default Banners
