interface TokensObject{
    access_token: string,
    expires_in: number,
    refresh_token: string,
    token_type: string
}
export const setTokensObject = (tokensObject: TokensObject) => {
    localStorage.setItem("Token_object", JSON.stringify(tokensObject));
}

export const getAccessToken = ()=> {
    const itemStr = localStorage.getItem("Token_object");
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr);
    return item.access_token;
}

export const getTokenObject = ()=> {
    const itemStr = localStorage.getItem("Token_object");
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr);
    return item;
}

export const deleteTokenObject = ()=>{
    localStorage.removeItem("Token_object");
}