import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getOrdersByStatus, createAdjustments, editAdjustments, deleteAdjustments, getOrdersById, changeOrderStaffFunction, changeOrderStatusFunction } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon,
    shareSocial as shareSocialIcon,
    callOutline as callOutlineIcon,
    navigateOutline as navigateOutlineIcon,
    documentText as documentTextIcon,
    pencil as pencilIcon,
    trash as trashIcon,
    closeOutline
} from 'ionicons/icons';
import { IonLoading,IonIcon,IonCol, IonHeader, IonPage, IonToolbar, IonTitle, IonContent, IonCard, IonCardContent, IonList, IonItem, IonButton, IonSelect, IonSelectOption, IonButtons, IonBackButton, IonLabel, IonGrid, IonRow, IonText, IonImg, IonModal, IonInput, useIonViewDidEnter, useIonViewWillEnter, useIonViewDidLeave, IonRadioGroup, IonRadio, IonAlert } from '@ionic/react';
import { formatDate } from '../../services/formatters';
import { Share } from '@capacitor/share';
import { useParams } from 'react-router';
import { caretDown as caretDownIcon
} from 'ionicons/icons';
interface RouteParams{
    id:string;
}
const OrderDetails = props => {
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState(null);
    const [type, setType] = useState("ADD");
    const [value, setValue] = useState(null);
    const[statusId, setStatusId] = useState('');
    const[customMessage, setCustomMessage] = useState('');
    const[driver, setDriver] = useState('');
    const [adjustmentEdited, setAdjustmentEdited] = useState<any>();
    const [showModalForStatus, setShowModalForStatus] = useState(false);
    const {id}=useParams<RouteParams>();
    const dispatch = useDispatch();
    const { setAdjustmentMessageLoading, setAdjustmentMessage, setAdjustmentMessageError } = useSelector((state : any)=> state.setAdjustmentData);
    const { editAdjustmentMessageLoading, editAdjustmentMessage, editAdjustmentMessageError } = useSelector((state : any)=> state.editAdjustmentData);
    const { deleteAdjustmentMessageLoading, deleteAdjustmentMessage, deleteAdjustmentMessageError } = useSelector((state : any)=> state.deleteAdjustmentData);
    const { orderLoading, order, orderError } = useSelector((state : any)=> state.orderById);
    const { changeOrderStaffLoading, changeOrderStaff, changeOrderStaffError } = useSelector((state : any)=> state.changeOrderStaffData);
    const { changeOrderStatusLoading, changeOrderStatus, changeOrderStatusError } = useSelector((state : any)=> state.changeOrderStatusData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    
    useEffect(() => {
        if(props && props.location && props.location.state )
            dispatch(getOrdersById(getAccessToken(), props.location.state));
    }, []) 
    // useEffect(() => {
    //     if(props && props.location && setAdjustmentMessageLoading===false)
    //         dispatch(getOrdersById(getAccessToken(), props.location.state));
    // }, [setAdjustmentMessageLoading])
    // useEffect(() => {
    //     if(props && props.location && editAdjustmentMessageLoading===false)
    //         dispatch(getOrdersById(getAccessToken(), props.location.state));
    // }, [  editAdjustmentMessageLoading])
    useEffect(() => {
        if(props && props.location && props.location.state && deleteAdjustmentMessageLoading===false)
            dispatch(getOrdersById(getAccessToken(), props.location.state));
    }, [deleteAdjustmentMessageLoading])
    // useEffect(() => {
    //     if(props && props.location && changeOrderStaffLoading===false)
    //         dispatch(getOrdersById(getAccessToken(), props.location.state));
    // }, [changeOrderStaffLoading ])
    // useEffect(() => {
    //     if(props && props.location && changeOrderStatusLoading===false)
    //         dispatch(getOrdersById(getAccessToken(), props.location.state));
    // }, [changeOrderStatusLoading])

    // console.log(order);
    // useEffect(() => {
    //     changeStatusHelper();
    // }, [statusId])
    useEffect(() => {
        changeDriverHelper();
    }, [driver])

    const changeStatusHelper = async () => { 
        if(currentUser && currentUser.data && order && statusId && statusId!=order.data[0].status){
            await Promise.all([await dispatch(changeOrderStatusFunction(getAccessToken(), order.data[0].id, statusId,customMessage  ))
            ,await dispatch(getOrdersById(getAccessToken(), props.location.state))]);
        }  
    }
    const changeDriverHelper = async () => { 
        if(currentUser && currentUser.data && order && driver  && driver!=order.data[0].assigned_driver_id){ 
            await Promise.all([await dispatch(changeOrderStaffFunction(getAccessToken(), order.data[0].id, driver, currentUser.data[0].store_id))
            ,await dispatch(getOrdersById(getAccessToken(), props.location.state))]);
        }  
    }
    const changeStatus = async(e :any)=>{ 
        e.preventDefault();
        setShowModalForStatus(false);
        setStatusId('')
        if(currentUser && currentUser.data && order && statusId && statusId!=order.data[0].status){
            await Promise.all([await dispatch(changeOrderStatusFunction(getAccessToken(), order.data[0].id, statusId,customMessage  ))
            ,await dispatch(getOrdersById(getAccessToken(), props.location.state))]);
        } 

         
    }

    const changeStaff = async(e: any)=>{
        e.preventDefault();
        setDriver(e.detail.value)
        // if(currentUser && currentUser.data && order)
        //     // await dispatch(changeOrderStaffFunction(getAccessToken(), order.data[0].id, e.detail.value, currentUser.data[0].store_id));
    }

    const addOrEditAdjustment = async()=>{
        if(adjustmentEdited){
            await Promise.all([await dispatch(editAdjustments(getAccessToken(), order.data[0].id, {
                id: adjustmentEdited.id,
                store_id: currentUser.data[0].store_id,
                name: `${message}`,
                value: `${value}`,
                method: `${type}`
            })),await dispatch(getOrdersById(getAccessToken(), props.location.state))]);
        }else{
            if(currentUser && currentUser.data[0])
                await Promise.all([await dispatch(createAdjustments(getAccessToken(), order.data[0].id, {
                    store_id: currentUser.data[0].store_id,
                    name: `${message}`,
                    value: `${value}`,
                    method: `${type}`
                })),await dispatch(getOrdersById(getAccessToken(), props.location.state))]);
        }
    }

    const deleteAdjustment = async(adjustmentId)=>{ 
         await dispatch(deleteAdjustments(getAccessToken(), order.data[0].id, adjustmentId));
    }

    const color={
        "NEW":"#08da4e",
        "PROCESSING":"warning",
        "Delivered":"#2db6fa",
        "Returned":"#e9222c",
        "Cancelled":"#e9222c"
    }

    const shareLocation = async()=>{
        await Share.share({
            title: 'Delivery address',
            text: `Delivery address: ${order.data[0].delivery_address}, ${order.data[0].delivery_city}, ${order.data[0].delivery_zip} \n Map Links: https://maps.google.com/?q=${order.data[0].delivery_latitude},${order.data[0].delivery_longitude}`
        });
    }

    const shareOrder = async()=>{
        await Share.share({
            title: 'Order Details',
            text: `${order.data[0].share_order_detail}`
        });
    }
    const invoiceDownload = ()=>{
        
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/orders" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle className="ion-no-padding ">
                    <IonItem lines="none">
                        <IonText>
                            <h4>Order #{order && order.data ? order.data[0].id :""}</h4>
                        </IonText>
                        { order && order.data ?
                            <IonButton fill='clear' color='secondary' routerLink={`/my/orders/orderdetails/invoice/${order.data[0].id}`} slot="end" >
                            <IonIcon  slot='start' size="large" icon={documentTextIcon}></IonIcon>
                                <h4>Invoice</h4>
                            </IonButton>
                        :''}
                    </IonItem>
                </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonLoading isOpen={ setAdjustmentMessageLoading === true ||  editAdjustmentMessageLoading === true || deleteAdjustmentMessageLoading === true || orderLoading === true || changeOrderStaffLoading === true || changeOrderStatusLoading === true } /> 
                { order && order.data ? 
                    <> 
                        {/* <IonItem lines="none">
                            <IonLabel><h5>Change Order Status</h5></IonLabel>
                            <IonSelect slot="end" value={statusId?statusId:order.data[0].status} onIonChange={ changeStatus}>
                                {order.data[0].available_order_statuses.map((element) => (
                                    <IonSelectOption key={element.status} value={element.status}>{element.display_name}</IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem> */}
                        <IonItem lines="none" onClick={e=> setShowModalForStatus(true)}>
                            <IonLabel>Change Order Status</IonLabel>
                        <IonText slot="end">{ order.data[0].status}</IonText>
                        <IonIcon slot="end" icon={caretDownIcon}/>
                        </IonItem>
                        
                        <IonModal id='statusmodal' isOpen={showModalForStatus} onDidDismiss={e=>{setShowModalForStatus(false);setCustomMessage("");setStatusId('') }} >
                        <IonHeader>
                            <IonItem lines="none">
                                <IonTitle>Select Order Status</IonTitle>
                                <IonButton fill='clear' size='large'  slot='end' onClick={(e) => setShowModalForStatus(false)} >
                                  <IonIcon slot="icon-only" icon={closeOutline}  ></IonIcon> 
                                </IonButton> 
                            </IonItem>
                        </IonHeader>
                        <IonContent className="ion-padding"> 
                                <IonRadioGroup value={statusId?statusId:order.data[0].status} onIonChange={e=> setStatusId(e.detail.value)}>
                                {order.data[0].available_order_statuses.map((element) => (
                                    <IonItem key={element.status} lines="none" className="ion-padding-start">{element.display_name}
                                        <IonRadio slot="start" value={element.status} />
                                    </IonItem>
                                        ))}
                                </IonRadioGroup>
                                <IonItem className="ion-padding-horizontal"> 
                                    <IonInput placeholder="Add Message" type="text" value={customMessage} onIonChange={e=>setCustomMessage(e.detail.value)} />
                                </IonItem>
                                <IonItem lines='none'> 
                                    <IonButton fill='clear' size='large'  slot='end' onClick={(e) => setShowModalForStatus(false)}>
                                        CANCEL
                                    </IonButton>
                                    <IonButton  fill='clear' size='large'  slot='end' onClick={(e) => changeStatus(e)} >
                                        OK
                                    </IonButton>   
                                </IonItem>  
                        </IonContent>
                    </IonModal> 
                        <IonCard>
                            <IonItem>
                                <IonText slot="start"><h5>#{order.data[0].id}</h5></IonText>
                                <IonText slot="end"><h5>{formatDate(order.data[0].created_at.date,'DD MMM YYYY, ddd')}</h5></IonText>
                            </IonItem>
                        </IonCard>
                        {order.data[0].order_images[0] && <IonCard>
                        <IonCardContent>
                        <h2 style={{marginBottom:"10px"}}><b>Order Image</b></h2>
                        <img src={order.data[0].order_images[0].image_path_public_url} alt=""></img>
                        </IonCardContent>
                        </IonCard>}
                        <IonCard>
                            <IonItem lines="none">
                                <IonText slot="start"><h5>{order.data[0].payment_method}</h5></IonText>
                                <IonButton slot="end" fill='clear'color='secondary' onClick={e=>shareOrder()} >
                                    <IonIcon slot="icon-only" icon={shareSocialIcon}  ></IonIcon> 
                                </IonButton>
                                {/* <IonIcon slot="end" icon={shareSocialIcon} onClick={e=>shareOrder()}></IonIcon> */}
                            </IonItem>
                            {order.data[0].products.map((val)=>{
                                return(
                                <IonItem key={val.variant_name} className="ion-padding-bottom">
                                    <IonImg slot="start" src={val.variant_image_url_small} style={{width: 15+'%', height: 15+'vh'}}></IonImg>
                                    <IonItem lines="none" className="ion-no-padding" style={{width: 100+'%'}}>
                                        <IonText>
                                            <h5>{val.variant_name}</h5>
                                            {val.variant_ordered_units} &#9747; Rs.{val.variant_price}
                                        </IonText>
                                        <IonText slot="end" style={{marginTop: 'auto'}}>Rs.{(val.variant_ordered_units) * (val.variant_price)}</IonText>
                                    </IonItem>
                                </IonItem>
                                )
                            })}
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                            {order.data[0].pricing_breakup.products[0] && <div className="table-holder">
                                <h2 className="ion-margin-bottom"><b>Products</b></h2>
                            <table>
                                <tbody>
                                    { order.data[0].pricing_breakup.products.map((op, ind) => (
                                        <tr key={ op.product_name }>
                                            <td>
                                                { op.product_ordered_units } x { op.product_name }
                                            </td>
                                            <td valign="top">{`₹${op.product_total_price.toFixed(2)}` }</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                        
                        {order.data[0].pricing_breakup.adjustments[0] && <><br/><div className="table-holder">
                                <h2 className="ion-margin-bottom"><b>Adjustments</b></h2>
                            <table>
                                <tbody>
                                    { order.data[0].pricing_breakup.adjustments.map((op, ind) => (
                                        <tr key={ op.adjustment_name }>
                                            <td>
                                                { op.adjustment_name }
                                            </td>
                                            <td valign="top">{`${op.adjustment_value_display.slice(0,1)} ₹${op.adjustment_value_display.slice(1)}` }</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div></>
                        }
                        {order.data[0].pricing_breakup.discounts.coupon_name && <><br/><div className="table-holder">
                                <h2 className="ion-margin-bottom"><b>Discounts</b></h2>
                            <table>
                                <tbody>
                                        <tr key={ order.data[0].pricing_breakup.discounts.coupon_name }>
                                            <td>
                                                {order.data[0].pricing_breakup.discounts.coupon_name }
                                            </td>
                                            <td valign="top">{`${order.data[0].pricing_breakup.discounts.coupon_value_display.slice(0,1)} ₹${order.data[0].pricing_breakup.discounts.coupon_value_display.slice(1)}` }</td>
                                        </tr>
                                </tbody>
                            </table>
                        </div></>}
                        
                        {order.data[0].pricing_breakup.taxesCharges[0]  && <> <br/><div className="table-holder">
                            <table>
                                <tbody>
                                    <tr className="totals">
                                        <td>Sub Total</td>
                                        <td>₹{ order.data[0].pricing_breakup.subTotal.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> </>}
                        
                        {order.data[0].pricing_breakup.taxesCharges[0] && <> <br/> <div className="table-holder">
                            <h2 className="ion-margin-bottom"><b>Taxes and Charges</b></h2>
                                <table>
                                    <tbody>
                                        { order.data[0].pricing_breakup.taxesCharges.map((op, ind) => (
                                            <tr key={ op.tax_name }>
                                                <td>
                                                    { op.tax_name }
                                                </td>
                                                <td valign="top">{`₹${op.tax_value_display}` }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div> </>
                        }
                        <div className="table-holder">
                            <table>
                                <tbody>
                                    <tr className="totals">
                                        <td>Total</td>
                                        <td>₹{ order.data[0].pricing_breakup.total.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                            </IonCardContent>
                        </IonCard>
                        {order.data[0].adjustments && order.data[0].adjustments.length !== 0 ? 
                            order.data[0].adjustments.map((elem)=>{
                                return(
                                    <IonCard key={elem.id}>
                                        <IonItem lines="none">
                                            <IonText slot="start">{elem.name}</IonText>
                                            <IonText slot="end" className='ion-padding-horizontal'>₹{elem.value}</IonText>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonText slot="start">{elem.method}</IonText>
                                            <IonButton slot="end" fill='clear'color='secondary' onClick={e=>{setMessage(elem.name);setValue(elem.value);setType(elem.method); setAdjustmentEdited(elem); setShowModal(true)}} >
                                                <IonIcon slot="icon-only" icon={pencilIcon}  ></IonIcon> 
                                            </IonButton>
                                            <IonButton slot="end" fill='clear'color='secondary' onClick={e=> deleteAdjustment(elem.id)} >
                                                <IonIcon slot="icon-only" icon={trashIcon}  ></IonIcon> 
                                            </IonButton>
                                            {/* <IonIcon slot="end" icon={pencilIcon} onClick={e=>{setMessage(elem.name);setValue(elem.value);setType(elem.method); setAdjustmentEdited(elem); setForStatus(true)}}></IonIcon>
                                            <IonIcon slot="end" icon={trashIcon} onClick={e=> deleteAdjustment(elem.id)}></IonIcon> */}
                                        </IonItem>
                                    </IonCard>
                                )
                            })
                        :""}
                        <IonModal isOpen={showModal}>
                            <IonHeader>
                                <IonToolbar>
                                    <IonTitle>
                                        <IonItem lines="none">
                                            <IonText><h4>{!message && !value ? "Add" : "Edit"} Adjustments</h4></IonText>
                                            <IonText slot="end" color="primary" onClick={e=> setShowModal(false)}><h5>CLOSE</h5></IonText>
                                        </IonItem>
                                    </IonTitle>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                <form>
                                    <br/>
                                    <IonGrid>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="3">
                                                <IonLabel>Message: </IonLabel>
                                            </IonCol>
                                            <IonCol>
                                                <IonItem>
                                                    <IonInput value={message} type="text" placeholder="Eg. Missing Products, Extra Discount etc." onIonChange={e=> setMessage(e.detail.value)}></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <br/>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="3">
                                                <IonLabel>Type</IonLabel>
                                            </IonCol>
                                            <IonCol>
                                                <IonItem>
                                                    <IonSelect value={type} style={{width: 100+'%'}} onIonChange={e=> setType(e.detail.value)}>
                                                        <IonSelectOption key="1" value="ADD">ADD</IonSelectOption>
                                                        <IonSelectOption key="2" value="SUBTRACT">SUBTRACT</IonSelectOption>
                                                    </IonSelect>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                        <br/>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="3">
                                                <IonLabel>Value:</IonLabel>
                                            </IonCol>
                                            <IonCol>
                                                <IonItem>
                                                    <IonInput value={value} type="tel" placeholder="10" onIonChange={e=>setValue(e.detail.value)}></IonInput>
                                                </IonItem>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    <br/>
                                    <IonButton expand="full" onClick={() => {setShowModal(false); addOrEditAdjustment();}}>Save</IonButton>
                                </form>
                            </IonContent>
                        </IonModal>
                        <IonGrid>
                            <IonRow>
                                <IonCol size="6"> 
                                    <IonButton expand="full" onClick={e=>{setMessage(null);setValue(null);setType("ADD"); setAdjustmentEdited(null); setShowModal(true)}}>Add Adjustment</IonButton> 
                                </IonCol>
                                <IonCol size="6">
                                    <IonGrid>
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="6">
                                                <IonText>Assign to</IonText>
                                            </IonCol>
                                            <IonCol size="6">
                                                <IonSelect value={driver ?driver:order.data[0].assigned_driver_id} onIonChange={e => changeStaff(e)}>
                                                    {order.data[0].available_driver.map((val)=>{
                                                        return(<IonSelectOption key={val.id} value={val.id}>
                                                            {val.name}
                                                        </IonSelectOption>)
                                                    })}
                                                </IonSelect>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        {order && order.data && order.data[0].user ? <>
                        <IonText><h5 className="ion-padding-start"><b>Customer Details:</b></h5></IonText>
                        <IonCard>
                            <IonItem lines="none">
                                <IonText slot="start">Name:</IonText>
                                <IonText slot="end">{`${order.data[0].user.first_name ? order.data[0].user.first_name : ""} ${order.data[0].user.last_name ? order.data[0].user.last_name : ""}`}</IonText>
                            </IonItem>
                            <IonItem lines="none">
                                <IonText slot="start">Mobile:</IonText>
                                <IonText slot="end">{`${order.data[0].user.phone ? order.data[0].user.phone : ""}`}</IonText>
                            </IonItem>
                        </IonCard>
                        
                        <IonGrid>
                            <IonRow>
                                <IonCol size="4">
                                    <IonButton expand="full">Share</IonButton>
                                </IonCol>
                                <IonCol size="4">
                                    <IonButton expand="full">Call</IonButton>
                                </IonCol>
                                <IonCol size="4">
                                    <IonButton expand="full">Whatsapp</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        </> :""}
                        {order.data[0].custom_message && <>
                            <IonText><h5 className="ion-padding-start"><b>Order Notes:</b></h5></IonText>
                            <IonCard>
                                <IonCardContent>
                                    <p>{order.data[0].custom_message}</p>
                                </IonCardContent>
                            </IonCard>
                        </>}
                        <IonText><h5 className="ion-padding-start"><b>Address:</b></h5></IonText>
                        <IonCard>
                            <IonItem lines="none">
                                <IonText>
                                    <p>{order.data[0].delivery_address}</p>
                                    <p>{order.data[0].delivery_city}, {order.data[0].delivery_zip}</p>
                                </IonText>
                            </IonItem>                           
                        </IonCard>
                        <IonGrid>
                            <IonRow>
                                { (order.data[0].delivery_latitude == 0 || order.data[0].delivery_longitude == 0) ? 
                                    <IonCol size="12" ><IonButton expand="full">No Location</IonButton></IonCol>: <>
                                        <IonCol size="6"> 
                                            <IonButton expand="full" href={`https://maps.google.com/?q=${order.data[0].delivery_latitude},${order.data[0].delivery_longitude}`} target="_blank">View on Map</IonButton> 
                                        </IonCol>
                                        <IonCol size="6">
                                            <IonButton expand="full" onClick={shareLocation}>Share Location</IonButton>
                                        </IonCol>
                                    </>}
                            </IonRow>
                        </IonGrid>
                        
                    </>
                    : 
                    <IonText className={`ion-align-items-center ion-text-center ${orderLoading === true ? "ion-hide": ""}`}>
                        <h5>No order to display.</h5>
                    </IonText>
                    }
            </IonContent>
        </IonPage>
    )
};

export default OrderDetails;