import React,{useEffect,useRef,useState} from 'react';
import { IonLoading, IonPage, IonContent, IonList, IonChip, useIonViewWillEnter, IonInfiniteScroll, IonInfiniteScrollContent, IonText, IonRefresher, IonRefresherContent, IonCard, IonCardContent } from '@ionic/react';
import { getOrdersByStatus } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import ShortInfo from '../../components/order/ShortInfo';
import Title from '../../components/dashboard/Header';

const Orders = () => {
	const [loading, setLoading] = useState(false);
	const [filterArrayObjectChange, setFilterArrayObjectChange] = useState(1)
    const[filter,setFilter]=useState("ALL");
	const[filterArrayObject,setFilterArrayObject]=useState({});
	const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(true);
	const[availableOrderStatuses, setAvailableOrderStatuses]=useState<any>([
		{status: 'NEW', display_name: 'New'},
		{status: 'PROCESSING', display_name: 'Processing'},
		{status: 'DELIVER', display_name: 'Delivered'},
		{status: 'CANCEL', display_name: 'Cancelled'},
		{status: 'RETURN', display_name: 'Returned'},
		{status: 'DENY', display_name: 'Denied'}
	]);
	const[page, setPage]=useState<any>({});
	//const [isAllApiCall, setIsAllApiCall] = useState(false);
	const [isStatusUpdate, setIsStatusUpdate] = useState(1);

    const dispatch = useDispatch();
    const { ordersLoading, orders, ordersError } = useSelector((state : any)=> state.orders);
	const { changeOrderStatusLoading, changeOrderStatus, changeOrderStatusError } = useSelector((state : any)=> state.changeOrderStatusData);

	useEffect(() => {
        if(changeOrderStatusLoading === false && changeOrderStatus){
			page[`ALL`] = 1;
			page[`${filter}`] = 1;
			if(changeOrderStatus.data.status in page){
				page[`${changeOrderStatus.data.status}`] = 1;
			}
			let temp = filterArrayObject;
			temp[`ALL`] = [];
			temp[`${filter}`] = [];
			if(changeOrderStatus.data.status in filterArrayObject){
				temp[`${changeOrderStatus.data.status}`] = [];
			}
			setFilterArrayObject(temp);
			setFilterArrayObjectChange(filterArrayObjectChange + 1);
			setIsStatusUpdate(isStatusUpdate + 1);
        }
    }, [changeOrderStatusLoading]);

	useEffect(() => {}, [filterArrayObjectChange])
	useEffect(() => {
		setDisableInfiniteScroll(false);
        fetchData();
    }, [filter, isStatusUpdate]);

	useEffect(()=>{
		setLoading(true);
		if(ordersLoading === false){
			if(orders && orders.data && orders.data.length > 0){
				setDisableInfiniteScroll(Boolean(orders.data.length < 10));
			}else{
				setDisableInfiniteScroll(true);
			}
		}
        if(ordersLoading === false && orders && orders.data){
			if(orders.data.length > 0){
				let op;
					if(filter === "ALL"){
						op = orders.data.reduce(function(acc,curr){
							if(acc["ALL"]){
								acc["ALL"].push(curr)
							}
							else{
								acc["ALL"]=[]
								acc["ALL"].push(curr)
							}
							return acc;
						},filterArrayObject);
					}else{
						op = orders.data.reduce(function(acc,curr){
							if(acc[curr.status]){
								acc[curr.status].push(curr)
							}
							else{
								acc[curr.status]=[]
								acc[curr.status].push(curr)
							}
							return acc;
						},filterArrayObject);
					}
				setFilterArrayObject(op)
				setFilterArrayObjectChange(filterArrayObjectChange+1);
			}
			if(orders.data.length === 0){
                let temp = filterArrayObject;
                temp[filter] = [];
                setFilterArrayObjectChange(filterArrayObjectChange+1);
                setFilterArrayObject(temp);
            }
        }
		setLoading(false);
	},[ordersLoading]);
	
	const fetchData = async()=>{
		setLoading(true);
		if(!(filter in page) || ((filter in page) && page[`${filter}`] === 1)){
			setDisableInfiniteScroll(false);
			await dispatch(getOrdersByStatus(getAccessToken(), filter));
			let temp = page;
			temp[`${filter}`] = 2;
			setPage(temp);
			// if(filter === "ALL"){
			// 	setIsAllApiCall(true);
			// }else{
			// 	setIsAllApiCall(false);
			// }
		}
		setLoading(false);
	}

	async function searchNext($event: CustomEvent<void>) {
		await fetchNextData();
		($event.target as HTMLIonInfiniteScrollElement).complete();
	}

	const fetchNextData = async()=>{
		if((filter in page)){
			let res = await fetch(`https://api.intelikart.com/api/orders/stores/${filter}?page=${page[filter]}`, {
				method: 'GET',
				headers: {
					"content-type": "application/json",
					'authorization': `${getAccessToken()}`
				}
			});
			res.json().then(async (res) => {
				if(res && res.data && res.data.length > 0) {
					let op;
					if(filter === "ALL"){
						op = res.data.reduce(function(acc,curr){
							if(acc["ALL"]){
								acc["ALL"].push(curr)
							}
							else{
								acc["ALL"]=[]
								acc["ALL"].push(curr)
							}
							return acc;
						},filterArrayObject);
					}else{
						op = res.data.reduce(function(acc,curr){
							if(acc[curr.status]){
								acc[curr.status].push(curr)
							}
							else{
								acc[curr.status]=[]
								acc[curr.status].push(curr)
							}
							return acc;
						},filterArrayObject);
					}
					setFilterArrayObjectChange(filterArrayObjectChange + 1);
					setFilterArrayObject(op);
					setDisableInfiniteScroll(res.data.length < 10);
					let tempPage = page;
					tempPage[filter] = tempPage[filter] + 1;
					setPage(tempPage);
				}else{
					setDisableInfiniteScroll(true);
				}
			}).catch(err => console.error(err));
		}
	}

	const doRefresh = ev => {
		setFilterArrayObject({});
		setFilterArrayObjectChange(1);
		setPage({});
		setFilter("ALL");  
		fetchData();
		setTimeout(() => {
			ev.detail.complete();
		}, 1500);
	};

	console.log(filterArrayObject);
	return (
		<IonPage>
            <div className="ion-page" id="main-content">
			<Title name="Orders"/>
			<div className="horizontal-scroll ion-padding">
				<IonChip style={{color:filter==="ALL"?"white":"",background:filter==="ALL"?"#4286f5":""}} color={filter==="ALL"?"":"secondary"} key="ALL" onClick={()=>setFilter("ALL")}>All</IonChip>
				{ availableOrderStatuses ? availableOrderStatuses.map(op=> <IonChip style={{color:op.status===filter?"white":"",background:op.status===filter?"#4286f5":""}} color={op.status===filter?"":"secondary"} key={op.status} onClick={()=>setFilter(op.status)}>{op.display_name}</IonChip>
				):""}
			</div>
			<IonContent >
				<IonLoading isOpen={ ordersLoading === true } /> 
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<div>
					{filterArrayObject[filter] && filterArrayObject[filter].length > 0 ?<IonList>
						{ filterArrayObject[filter]?.map(order => (
							<ShortInfo key={ order.id } order={ order }  />
						) ) }	
					</IonList>:<IonCard><IonCardContent><IonText className={`${ordersLoading==true ? "ion-hide": "ion-margin noorderstext" }`}> No Orders in this section.</IonText></IonCardContent></IonCard>}
				</div>
				<IonInfiniteScroll disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
					<IonInfiniteScrollContent
						loadingText="Loading more orders...">
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</IonContent>
            </div>
		</IonPage>
	);
};

export default Orders;
