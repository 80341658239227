import React, {useRef, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonImg, IonSlide, IonSlides } from '@ionic/react';
import { getTipBanners } from '../../actions/dashboardActions';

const TipBanners = () => {
    const mySlides = useRef<HTMLIonSlidesElement>(null);
    const slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        loop: true,
        
        speed: 500
    };
    const dispatch = useDispatch();
    const { tipBannersLoading, tipBanners, tipBannersError } = useSelector((state : any)=> state.tipBanners);
    useEffect(()=>{
        (async function() {
            await dispatch(getTipBanners());
        })();
        if(tipBannersLoading === false && tipBannersError)
            console.log(tipBannersError);
    },[]);
    function slidesDidLoad() {
        if(mySlides.current){
            mySlides.current.startAutoplay();
        }
    }
    return (
        <IonSlides pager={true} options={slideOpts} ref={mySlides} onIonSlidesDidLoad={slidesDidLoad} className="ion-no-padding">
            {tipBannersLoading === false && tipBanners && tipBanners.data ?
            (tipBanners.data.map((value : any)=>{
                return (<IonSlide key={value.id}>
                    <IonImg src={value.name}/>
                </IonSlide>)
            })):null
            }
        </IonSlides>
    )
}

export default TipBanners
