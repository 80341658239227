import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonSegment, IonSegmentButton, IonLabel, IonText, IonCard, IonPage, IonGrid, IonCol, IonRow, IonIcon, IonItem } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getAcademyContent } from '../../actions/dashboardActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { useHistory } from 'react-router';
import { chatboxEllipsesOutline, logoYoutube } from 'ionicons/icons';

const HelpAndSupport = () => {
  const [content, setContent] = useState<string | undefined>("VIDEOS");
  const dispatch = useDispatch();
  const history = useHistory();
  const { academyContentLoading, academyContent, academyContentError } = useSelector((state: any) => state.academyContent);

  useEffect(() => {
    (async function () {
      const accessToken = await getAccessToken();
      if(accessToken){
        await dispatch(getAcademyContent(accessToken));
      }else{
        await dispatch(authenticateUserWhenLogout())
        await localStorage.clear();
        history.push('/')
      }
    })();
    if (academyContentLoading === false && academyContentError)
      console.log(academyContentError);
  }, [content])

  const getVideoId = (url: string) => {
    console.log(url);
    let index = url.indexOf("?v=");
    console.log('index = ' + index);

    if (index !== -1) {
      let ind = url.lastIndexOf("&");
      if (ind !== -1) {
        console.log(url.slice(index + 3, ind));
        return url.slice(index + 3, ind);
      }
      console.log(url.slice(index + 3, url.length));
      return url.slice(index + 3, url.length);
    } else {
      let i = url.lastIndexOf("/");
      let ind = url.lastIndexOf("?");
      if (ind !== -1) {
        console.log(url.slice(i + 1, ind));
        return url.slice(i + 1, ind);
      }
      console.log(url.slice(i + 1, url.length));
      console.log('i = ' + i);
      return url.slice(i + 1, url.length);
    }
  }
  return (
    <IonPage> 
      <div className="ion-page" id="main-content">
        <Header name="Help & Support" />
        <IonContent className="ion-padding">
           <IonGrid>
               <IonRow>
                   <IonCol>
                        <IonCard href='https://wa.me/+918448355548' target="_blank">
                            <IonItem lines="none"> 
                                <IonIcon slot="start" icon={chatboxEllipsesOutline} size="large"/>
                                <IonText >
                                Chat Us
                                </IonText>
                            </IonItem>
                        </IonCard>
                   </IonCol>
                   <IonCol> 
                        <IonCard routerLink="/my/ikacademy" >
                            <IonItem lines="none"> 
                                <IonIcon slot="start" icon={logoYoutube} size="large"/>
                                <IonText  >
                                    IK Academy
                                </IonText>
                            </IonItem>
                        </IonCard>
                   </IonCol>
               </IonRow>
           </IonGrid>
        </IonContent>
      </div>
    </IonPage>
  )
}

export default HelpAndSupport
