import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonSegment, IonSegmentButton, IonLabel, IonText, IonCard, IonIcon, IonItem, IonImg, IonInput, IonSlides, IonSlide, IonButton, IonLoading, IonToast, IonPage, IonRow, IonCardContent, IonCol, IonGrid } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getPrintables, getAnnouncements, createAnnouncements } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import {
    print as printIcon,
    megaphone as megaphoneIcon,
    shareSocial as shareSocialIcon,
    cloudDownload as cloudDownloadIcon
} from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { authenticateUserWhenLogout } from '../../actions/authActions';
import { useHistory } from 'react-router';
import { MobileView } from 'react-device-detect';
const Marketing = props => {
    const [content, setContent] = useState<string | undefined>("PRINTABLES");
    const [downloadingLoader, setDownloadingLoader] = useState(false);
    const [announcement, setAnnouncement] = useState<string | undefined>("");
    const dispatch = useDispatch();
    const history = useHistory();

    const { printablesLoading, printables, printablesError } = useSelector((state : any)=> state.printables);
    const { announcementsLoading, announcements, announcementsError } = useSelector((state : any)=> state.loadAnnouncementsReducer);
    const { createAnnouncementLoading, createAnnouncement, createAnnouncementError } = useSelector((state : any)=> state.createAnnouncementReducer);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    useEffect(() => {
        if(props && props.location && props.location.state){
            setContent("ANNOUNCEMENT");
        }else{
            setContent("PRINTABLES");
        }
    }, [props.location.state])

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getPrintables(getAccessToken(), currentUser.data[0].store_id));
            dispatch(getAnnouncements(getAccessToken(), currentUser.data[0].store_id));
        }
    }, [currentUser]);

    const slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
        loop: true,
        speed: 20
    };

    const sendAnnouncement = async()=>{
        if(currentUser && currentUser.data){
            if(getAccessToken()){
                await dispatch(createAnnouncements(getAccessToken(), {
                    store_id: currentUser.data[0].store_id,
                    text: `${announcement}`
                }));
            }else{
                await dispatch(authenticateUserWhenLogout())
                await localStorage.clear();
                history.push('/')
            }
        }
            
    }

    const sharePrintables = async (value: any)=>{
        await Share.share({
            title: 'Intelikart Printables',
            text: ``
        });
    }

    // const downloadImage = async (value: any) => {
    //     console.log("run");
    //     const response = await fetch(value.image_link);
    //     const blob = await response.blob();
    //     const base64Data = await convertBlobToBase64(blob) as string;
    //     await Filesystem.writeFile({
    //       path: 'text.jpeg',
    //       data: base64Data,
    //       directory: Directory.Data
    //     });
    //   };

    //   const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    //     const reader = new FileReader;
    //     reader.onerror = reject;
    //     reader.onload = () => {
    //       resolve(reader.result);
    //     };
    //    reader.readAsDataURL(blob);
    //   });

    var UrlImageDownloader = async (value) =>  {
        setDownloadingLoader(true);
        await fetch(value, {
            method: 'GET'
        }).then(function (response) {
            response.arrayBuffer().then(function (buffer) {
                var url = window.URL.createObjectURL(new Blob([buffer]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', value.substr(value.lastIndexOf('/') + 1));
                document.body.appendChild(link);
                link.click();
            });
        }).catch(function (error) {
            console.error(error.message);
        });
        setDownloadingLoader(false);
    }

    const mySlides = useRef<HTMLIonSlidesElement>(null);

    function slidesDidLoad() {
        if(mySlides.current){
            mySlides.current.startAutoplay();
        }
    }

    return (
      <IonPage>
      <div className="ion-page" id="main-content">
        <Header name="Marketing"/>
        <IonContent className="ion-padding">
        <IonSegment onIonChange={e => setContent(e.detail.value)} value={content}>
                <IonSegmentButton value="PRINTABLES">
                  <IonItem lines="none" style={{marginBottom: 2+'px'}}>
                      <IonIcon size="large" icon={printIcon}></IonIcon>
                      <IonText className="ion-margin-horizontal">Printables</IonText>
                  </IonItem>
                </IonSegmentButton>
                <IonSegmentButton value="ANNOUNCEMENT">
                <IonItem lines="none" style={{marginBottom: 2+'px'}}>
                      <IonIcon size="large" icon={megaphoneIcon}></IonIcon>
                      <IonText className="ion-margin-horizontal">Announcement</IonText>
                  </IonItem>
                </IonSegmentButton>
              </IonSegment>
              
    { printablesLoading===false && printables && printables.data && content === 'PRINTABLES' ? <IonGrid>
        <IonRow>
    {printables.data.map((elem)=>{
        return(<IonCol key={elem.type} size="12" sizeSm="6">
            <IonCard>
                <IonCardContent className="ion-no-padding">
                <IonItem style={{paddingTop: 16+'px'}}><IonImg src={elem.image_link}></IonImg></IonItem>
                <IonItem lines="none">
                    <MobileView><IonIcon size="large" slot="start" icon={shareSocialIcon} onClick={(e)=> sharePrintables(elem)}></IonIcon></MobileView>
                    <div className="ion-text-center"><h6>{elem.type}</h6></div>
                    <IonIcon slot="end" size="large" icon={cloudDownloadIcon} onClick={e=> UrlImageDownloader(elem.image_link)}></IonIcon>
                </IonItem>
                </IonCardContent>
            </IonCard>
        </IonCol>)
    })}
    </IonRow>
    </IonGrid>: null
} 

{ announcementsLoading===false && announcements && announcements.data && content === 'ANNOUNCEMENT' ? 
    <>
        <IonText className="ion-text-center">
            <h5>Make exciting announcements</h5>
            <p>Will be sent as push notification to your customers</p>
        </IonText>
        <IonItem>
            <IonInput type="text" placeholder="Type your message here or select from the suggestions below." onIonChange={e=> setAnnouncement(e.detail.value)} value={announcement}/>
        </IonItem>
        <br/>
        <IonSlides ref={mySlides} pager={true} options={slideOpts} onIonSlidesDidLoad={slidesDidLoad}>
        {announcements.data.map((elem)=>{
            return(
                <IonSlide key={elem}><IonCard onClick={e=> setAnnouncement(elem)}><IonCardContent><IonText className="ion-margin ion-padding">{elem}</IonText></IonCardContent></IonCard></IonSlide>
            )
        })}
        </IonSlides>
        <br/>
        <div className="ion-text-center">
            <IonButton type="button" shape="round" onClick={sendAnnouncement}>Send</IonButton>
        </div>
    </>
    : null
}
        <IonLoading isOpen={printablesLoading === true || announcementsLoading === true || createAnnouncementLoading === true || downloadingLoader === true} message="Please Wait"></IonLoading>
        <IonToast isOpen={createAnnouncementLoading === false && Boolean(createAnnouncement)} message="Announcement will be sent to all of your app customers in next 10 minutes." duration={500} animated={true}
        />
        </IonContent>
      </div>
    </IonPage>
)}

export default Marketing
