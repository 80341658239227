import {
    CREATE_PAGE_REQUEST, CREATE_PAGE_SUCCESS, CREATE_PAGE_FAIL,
    GET_PAGES_REQUEST, GET_PAGES_SUCCESS, GET_PAGES_FAIL,
    GET_PAGE_BY_ID_REQUEST, GET_PAGE_BY_ID_SUCCESS, GET_PAGE_BY_ID_FAIL,
    UPDATE_PAGE_REQUEST, UPDATE_PAGE_SUCCESS, UPDATE_PAGE_FAIL,
    CHECK_URL_AVAILABILITY_REQUEST, CHECK_URL_AVAILABILITY_SUCCESS, CHECK_URL_AVAILABILITY_FAIL,
    DELETE_PAGE_REQUEST, DELETE_PAGE_SUCCESS, DELETE_PAGE_FAIL
} from "../constants/pageConstants";

const API_ROOT = 'https://api.intelikart.com/';

export const getPages = (storeId: Number| string, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_PAGES_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/stores/${storeId}/pages`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_PAGES_SUCCESS,
            payload: data.data
        })
    }catch(error) {
        dispatch({
            type: GET_PAGES_FAIL,
            payload: error
        })
    }
}

export const getPageById = (pageId: number | string, storeId: Number| string, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : GET_PAGE_BY_ID_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/stores/${storeId}/pages/${pageId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            }
        });
        const data = await response.json();
        dispatch({
            type: GET_PAGE_BY_ID_SUCCESS,
            payload: data.data
        })
    }catch(error) {
        dispatch({
            type: GET_PAGE_BY_ID_FAIL,
            payload: error
        })
    }
}

export const createPage = (pageData: any, storeId: Number| string, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_PAGE_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/stores/${storeId}/pages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            },
            body: JSON.stringify(pageData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_PAGE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: CREATE_PAGE_FAIL,
            payload: error
        })
    }
}

export const editPage = (pageData: any, pageId: Number| string, storeId: Number| string, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : UPDATE_PAGE_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/stores/${storeId}/pages/${pageId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            },
            body: JSON.stringify(pageData)
        });
        const data = await response.json();
        dispatch({
            type: UPDATE_PAGE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: UPDATE_PAGE_FAIL,
            payload: error
        })
    }
}

export const deletePage = (pageId: any, storeId: Number| string, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : DELETE_PAGE_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/stores/${storeId}/pages/${pageId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            },
        });
        const data = await response.json();
        dispatch({
            type: DELETE_PAGE_SUCCESS,
            payload: data
        })
    }catch(error) {
        dispatch({
            type: DELETE_PAGE_FAIL,
            payload: error
        })
    }
}

export const isURLAvailableFunction = (urlData: any, storeId: Number| string, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : CHECK_URL_AVAILABILITY_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/stores/${storeId}/pages-slug-availability`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            },
            body: JSON.stringify(urlData)
        });
        const data = await response.json();
        dispatch({
            type: CHECK_URL_AVAILABILITY_SUCCESS,
            payload: data.slug_availabe
        })
    }catch(error) {
        dispatch({
            type: CHECK_URL_AVAILABILITY_FAIL,
            payload: error
        })
    }
}
