import { 
    LOAD_TIPS_BANNERS_REQUEST, LOAD_TIPS_BANNERS_SUCCESS, LOAD_TIPS_BANNERS_FAIL,
    LOAD_HOME_SCREEN_REQUEST, LOAD_HOME_SCREEN_SUCCESS, LOAD_HOME_SCREEN_FAIL,
    LOAD_HOME_SCREEN_WITH_DAYS_REQUEST, LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS, LOAD_HOME_SCREEN_WITH_DAYS_FAIL,
    LOAD_ACADEMY_CONTENT_REQUEST, LOAD_ACADEMY_CONTENT_SUCCESS, LOAD_ACADEMY_CONTENT_FAIL,
    LOAD_CUSTOMERS_REQUEST, LOAD_CUSTOMERS_SUCCESS, LOAD_CUSTOMERS_FAIL,
    GET_COUPONS_REQUEST, GET_COUPONS_SUCCESS, GET_COUPONS_FAIL,
    CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_FAIL,
    DELETE_COUPON_REQUEST, DELETE_COUPON_SUCCESS, DELETE_COUPON_FAIL,
    GET_BANNERS_REQUEST, GET_BANNERS_SUCCESS, GET_BANNERS_FAIL,
    CREATE_BANNER_REQUEST, CREATE_BANNER_SUCCESS, CREATE_BANNER_FAIL,
    DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS, DELETE_BANNER_FAIL,
    GET_SETTINGS_BASIC_INFO_REQUEST, GET_SETTINGS_BASIC_INFO_SUCCESS, GET_SETTINGS_BASIC_INFO_FAIL,
    SET_SETTINGS_BASIC_INFO_REQUEST, SET_SETTINGS_BASIC_INFO_SUCCESS, SET_SETTINGS_BASIC_INFO_FAIL,
    GET_SETTINGS_LOCALIZATION_REQUEST, GET_SETTINGS_LOCALIZATION_SUCCESS, GET_SETTINGS_LOCALIZATION_FAIL,
    SET_SETTINGS_LOCALIZATION_REQUEST, SET_SETTINGS_LOCALIZATION_SUCCESS, SET_SETTINGS_LOCALIZATION_FAIL,
    GET_SETTINGS_SERVICE_DETAILS_REQUEST, GET_SETTINGS_SERVICE_DETAILS_SUCCESS, GET_SETTINGS_SERVICE_DETAILS_FAIL,
    SET_SETTINGS_SERVICE_DETAILS_REQUEST, SET_SETTINGS_SERVICE_DETAILS_SUCCESS, SET_SETTINGS_SERVICE_DETAILS_FAIL,
    GET_SETTINGS_QUICK_ORDERS_REQUEST, GET_SETTINGS_QUICK_ORDERS_SUCCESS, GET_SETTINGS_QUICK_ORDERS_FAIL,
    SET_SETTINGS_QUICK_ORDERS_REQUEST, SET_SETTINGS_QUICK_ORDERS_SUCCESS, SET_SETTINGS_QUICK_ORDERS_FAIL,
    GET_SETTINGS_PAYMENTS_REQUEST, GET_SETTINGS_PAYMENTS_SUCCESS, GET_SETTINGS_PAYMENTS_FAIL,
    SET_SETTINGS_PAYMENTS_REQUEST, SET_SETTINGS_PAYMENTS_SUCCESS, SET_SETTINGS_PAYMENTS_FAIL,
    GET_TAXES_REQUEST, GET_TAXES_SUCCESS, GET_TAXES_FAIL,
    CREATE_TAX_REQUEST, CREATE_TAX_SUCCESS, CREATE_TAX_FAIL,
    DELETE_TAX_REQUEST, DELETE_TAX_SUCCESS, DELETE_TAX_FAIL,
    UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAIL,
    GET_STAFFS_REQUEST, GET_STAFFS_SUCCESS, GET_STAFFS_FAIL,
    CREATE_STAFF_REQUEST, CREATE_STAFF_SUCCESS, CREATE_STAFF_FAIL,
    DELETE_STAFF_REQUEST, DELETE_STAFF_SUCCESS, DELETE_STAFF_FAIL,
    GET_ORDERS_BY_STATUS_REQUEST, GET_ORDERS_BY_STATUS_SUCCESS, GET_ORDERS_BY_STATUS_FAIL,
    GET_ORDER_BY_ID_REQUEST, GET_ORDER_BY_ID_SUCCESS, GET_ORDER_BY_ID_FAIL,
    GET_INVOICE_REQUEST, GET_INVOICE_SUCCESS, GET_INVOICE_FAIL,
    ORDER_STATUS_CHANGE_REQUEST, ORDER_STATUS_CHANGE_SUCCESS, ORDER_STATUS_CHANGE_FAIL,
    CHANGE_ORDER_STAFF_REQUEST, CHANGE_ORDER_STAFF_SUCCESS, CHANGE_ORDER_STAFF_FAIL,
    GET_ADJUSTMENTS_REQUEST, GET_ADJUSTMENTS_SUCCESS, GET_ADJUSTMENTS_FAIL,
    SET_ADJUSTMENTS_REQUEST, SET_ADJUSTMENTS_SUCCESS, SET_ADJUSTMENTS_FAIL,
    EDIT_ADJUSTMENTS_REQUEST, EDIT_ADJUSTMENTS_SUCCESS, EDIT_ADJUSTMENTS_FAIL,
    DELETE_ADJUSTMENTS_REQUEST, DELETE_ADJUSTMENTS_SUCCESS, DELETE_ADJUSTMENTS_FAIL,
    GET_PRINTABLES_REQUEST, GET_PRINTABLES_SUCCESS, GET_PRINTABLES_FAIL,
    GET_ANNOUNCEMENTS_REQUEST, GET_ANNOUNCEMENTS_SUCCESS, GET_ANNOUNCEMENTS_FAIL,
    CREATE_ANNOUNCEMENT_REQUEST, CREATE_ANNOUNCEMENT_SUCCESS, CREATE_ANNOUNCEMENT_FAIL,
    LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST, LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS, LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL,
    CLEAR_ERRORS
} from '../constants/dashboardConstants';

export const loadTipBannersReducer = (state = { tipBanners: {} }, action: any) =>{
    switch(action.type){
        case LOAD_TIPS_BANNERS_REQUEST:
            return{
                ...state,
                tipBannersLoading: true,
                tipBannersError: null
            }
        case LOAD_TIPS_BANNERS_SUCCESS:
            return{
                ...state,
                tipBannersLoading: false,
                tipBanners: action.payload
            }
        case LOAD_TIPS_BANNERS_FAIL:
            return{
                ...state,
                tipBannersLoading: false,
                tipBannersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadHomeScreenReducer = (state = { homeScreen: {} }, action: any) =>{
    switch(action.type){
        case LOAD_HOME_SCREEN_REQUEST:
            return{
                ...state,
                homeScreenLoading: true,
                homeScreenError: null
            }
        case LOAD_HOME_SCREEN_SUCCESS:
            return{
                ...state,
                homeScreenLoading: false,
                homeScreen: action.payload
            }
        case LOAD_HOME_SCREEN_FAIL:
            return{
                ...state,
                homeScreenLoading: false,
                homeScreenError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadHomeScreenWithDaysReducer = (state = { homeScreenWithDays: {} }, action: any) =>{
    switch(action.type){
        case LOAD_HOME_SCREEN_WITH_DAYS_REQUEST:
            return{
                ...state,
                homeScreenWithDaysLoading: true,
                homeScreenWithDaysError: null
            }
        case LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS:
            return{
                ...state,
                homeScreenWithDaysLoading: false,
                homeScreenWithDays: action.payload
            }
        case LOAD_HOME_SCREEN_WITH_DAYS_FAIL:
            return{
                ...state,
                homeScreenWithDaysLoading: false,
                homeScreenWithDaysError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const loadAcademyContentReducer = (state = { academyContent: {} }, action: any) =>{
    switch(action.type){
        case LOAD_ACADEMY_CONTENT_REQUEST:
            return{
                ...state,
                academyContentLoading: true,
                academyContentError: null
            }
        case LOAD_ACADEMY_CONTENT_SUCCESS:
            return{
                ...state,
                academyContentLoading: false,
                academyContent: action.payload
            }
        case LOAD_ACADEMY_CONTENT_FAIL:
            return{
                ...state,
                academyContentLoading: false,
                academyContentError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadCouponsReducer = (state = { coupons: {} }, action: any) =>{
    switch(action.type){
        case GET_COUPONS_REQUEST:
            return{
                ...state,
                couponsLoading: true,
                couponsError: null
            }
        case GET_COUPONS_SUCCESS:
            return{
                ...state,
                couponsLoading: false,
                coupons: action.payload
            }
        case GET_COUPONS_FAIL:
            return{
                ...state,
                couponsLoading: false,
                couponsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createCouponReducer = (state = { createCouponMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_COUPON_REQUEST:
            return{
                ...state,
                createCouponMessageLoading: true,
                createCouponMessageError: null
            }
        case CREATE_COUPON_SUCCESS:
            return{
                ...state,
                createCouponMessageLoading: false,
                createCouponMessage: action.payload
            }
        case CREATE_COUPON_FAIL:
            return{
                ...state,
                createCouponMessageLoading: false,
                createCouponMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteCouponReducer = (state = { deleteCouponMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_COUPON_REQUEST:
            return{
                ...state,
                deleteCouponMessageLoading: true,
                deleteCouponMessageError: null
            }
        case DELETE_COUPON_SUCCESS:
            return{
                ...state,
                deleteCouponMessageLoading: false,
                deleteCouponMessage: action.payload
            }
        case DELETE_COUPON_FAIL:
            return{
                ...state,
                deleteCouponMessageLoading: false,
                deleteCouponMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadCustomersReducer = (state = { customers: {} }, action: any) =>{
    switch(action.type){
        case LOAD_CUSTOMERS_REQUEST:
            return{
                ...state,
                customersLoading: true,
                customersError: null
            }
        case LOAD_CUSTOMERS_SUCCESS:
            return{
                ...state,
                customersLoading: false,
                customers: action.payload
            }
        case LOAD_CUSTOMERS_FAIL:
            return{
                ...state,
                customersLoading: false,
                customersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadBannersReducer = (state = { banners: {} }, action: any) =>{
    switch(action.type){
        case GET_BANNERS_REQUEST:
            return{
                ...state,
                bannersLoading: true,
                bannersError: null
            }
        case GET_BANNERS_SUCCESS:
            return{
                ...state,
                bannersLoading: false,
                banners: action.payload
            }
        case GET_BANNERS_FAIL:
            return{
                ...state,
                bannersLoading: false,
                bannersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createBannerReducer = (state = { addBanner: {} }, action: any) =>{
    switch(action.type){
        case CREATE_BANNER_REQUEST:
            return{
                ...state,
                addBannerLoading: true,
                addBannerError: null
            }
        case CREATE_BANNER_SUCCESS:
            return{
                ...state,
                addBannerLoading: false,
                addBanner: action.payload
            }
        case CREATE_BANNER_FAIL:
            return{
                ...state,
                addBannerLoading: false,
                addBannerError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteBannerReducer = (state = { deleteBannerMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_BANNER_REQUEST:
            return{
                ...state,
                deleteBannerMessageLoading: true,
                deleteBannerMessageError: null
            }
        case DELETE_BANNER_SUCCESS:
            return{
                ...state,
                deleteBannerMessageLoading: false,
                deleteBannerMessage: action.payload
            }
        case DELETE_BANNER_FAIL:
            return{
                ...state,
                deleteBannerMessageLoading: false,
                deleteBannerMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getBasicInfoReducer = (state = { basicInfo: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_BASIC_INFO_REQUEST:
            return{
                ...state,
                basicInfoLoading: true,
                basicInfoError: null
            }
        case GET_SETTINGS_BASIC_INFO_SUCCESS:
            return{
                ...state,
                basicInfoLoading: false,
                basicInfo: action.payload
            }
        case GET_SETTINGS_BASIC_INFO_FAIL:
            return{
                ...state,
                basicInfoLoading: false,
                basicInfoError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setBasicInfoReducer = (state = { setBasicInfoResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_BASIC_INFO_REQUEST:
            return{
                ...state,
                setBasicInfoResponseLoading: true,
                setBasicInfoResponseError: null
            }
        case SET_SETTINGS_BASIC_INFO_SUCCESS:
            return{
                ...state,
                setBasicInfoResponseLoading: false,
                setBasicInfoResponse: action.payload
            }
        case SET_SETTINGS_BASIC_INFO_FAIL:
            return{
                ...state,
                setBasicInfoResponseLoading: false,
                setBasicInfoResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getQuickOrderReducer = (state = { quickOrder: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_QUICK_ORDERS_REQUEST:
            return{
                ...state,
                quickOrderLoading: true,
                quickOrderError: null
            }
        case GET_SETTINGS_QUICK_ORDERS_SUCCESS:
            return{
                ...state,
                quickOrderLoading: false,
                quickOrder: action.payload
            }
        case GET_SETTINGS_QUICK_ORDERS_FAIL:
            return{
                ...state,
                quickOrderLoading: false,
                quickOrderError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setQuickOrderReducer = (state = { setQuickOrderResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_QUICK_ORDERS_REQUEST:
            return{
                ...state,
                setQuickOrderResponseLoading: true,
                setQuickOrderResponseError: null
            }
        case SET_SETTINGS_QUICK_ORDERS_SUCCESS:
            return{
                ...state,
                setQuickOrderResponseLoading: false,
                setQuickOrderResponse: action.payload
            }
        case SET_SETTINGS_QUICK_ORDERS_FAIL:
            return{
                ...state,
                setQuickOrderResponseLoading: false,
                setQuickOrderResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getPaymentReducer = (state = { payment: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_PAYMENTS_REQUEST:
            return{
                ...state,
                paymentLoading: true,
                paymentError: null
            }
        case GET_SETTINGS_PAYMENTS_SUCCESS:
            return{
                ...state,
                paymentLoading: false,
                payment: action.payload
            }
        case GET_SETTINGS_PAYMENTS_FAIL:
            return{
                ...state,
                paymentLoading: false,
                paymentError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setPaymentReducer = (state = { setPaymentResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_PAYMENTS_REQUEST:
            return{
                ...state,
                setPaymentResponseLoading: true,
                setPaymentResponseError: null
            }
        case SET_SETTINGS_PAYMENTS_SUCCESS:
            return{
                ...state,
                setPaymentResponseLoading: false,
                setPaymentResponse: action.payload
            }
        case SET_SETTINGS_PAYMENTS_FAIL:
            return{
                ...state,
                setPaymentResponseLoading: false,
                setPaymentResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getServiceDetailReducer = (state = { serviceDetail: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_SERVICE_DETAILS_REQUEST:
            return{
                ...state,
                serviceDetailLoading: true,
                serviceDetailError: null
            }
        case GET_SETTINGS_SERVICE_DETAILS_SUCCESS:
            return{
                ...state,
                serviceDetailLoading: false,
                serviceDetail: action.payload
            }
        case GET_SETTINGS_SERVICE_DETAILS_FAIL:
            return{
                ...state,
                serviceDetailLoading: false,
                serviceDetailError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setServiceDetailReducer = (state = { setServiceDetailResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_SERVICE_DETAILS_REQUEST:
            return{
                ...state,
                setServiceDetailResponseLoading: true,
                setServiceDetailResponseError: null
            }
        case SET_SETTINGS_SERVICE_DETAILS_SUCCESS:
            return{
                ...state,
                setServiceDetailResponseLoading: false,
                setServiceDetailResponse: action.payload
            }
        case SET_SETTINGS_SERVICE_DETAILS_FAIL:
            return{
                ...state,
                setServiceDetailResponseLoading: false,
                setServiceDetailResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const getLocalizationReducer = (state = { localization: {} }, action: any) =>{
    switch(action.type){
        case GET_SETTINGS_LOCALIZATION_REQUEST:
            return{
                ...state,
                localizationLoading: true,
                localizationError: null
            }
        case GET_SETTINGS_LOCALIZATION_SUCCESS:
            return{
                ...state,
                localizationLoading: false,
                localization: action.payload
            }
        case GET_SETTINGS_LOCALIZATION_FAIL:
            return{
                ...state,
                localizationLoading: false,
                localizationError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setLocalizationReducer = (state = { setLocalizationResponse: {} }, action: any) =>{
    switch(action.type){
        case SET_SETTINGS_LOCALIZATION_REQUEST:
            return{
                ...state,
                setLocalizationResponseLoading: true,
                setLocalizationResponseError: null
            }
        case SET_SETTINGS_LOCALIZATION_SUCCESS:
            return{
                ...state,
                setLocalizationResponseLoading: false,
                setLocalizationResponse: action.payload
            }
        case SET_SETTINGS_LOCALIZATION_FAIL:
            return{
                ...state,
                setLocalizationResponseLoading: false,
                setLocalizationResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const uploadImageReducer = (state = { uploadResponse: {} }, action: any) =>{
    switch(action.type){
        case UPLOAD_IMAGE_REQUEST:
            return{
                ...state,
                uploadResponseLoading: true,
                uploadResponseError: null
            }
        case UPLOAD_IMAGE_SUCCESS:
            return{
                ...state,
                uploadResponseLoading: false,
                uploadResponse: action.payload
            }
        case UPLOAD_IMAGE_FAIL:
            return{
                ...state,
                uploadResponseLoading: false,
                uploadResponseError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadTaxesReducer = (state = { taxes: {} }, action: any) =>{
    switch(action.type){
        case GET_TAXES_REQUEST:
            return{
                ...state,
                taxesLoading: true,
                taxesError: null
            }
        case GET_TAXES_SUCCESS:
            return{
                ...state,
                taxesLoading: false,
                taxes: action.payload
            }
        case GET_TAXES_FAIL:
            return{
                ...state,
                taxesLoading: false,
                taxesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createTaxReducer = (state = { createTaxMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_TAX_REQUEST:
            return{
                ...state,
                createTaxMessageLoading: true,
                createTaxMessageError: null
            }
        case CREATE_TAX_SUCCESS:
            return{
                ...state,
                createTaxMessageLoading: false,
                createTaxMessage: action.payload
            }
        case CREATE_TAX_FAIL:
            return{
                ...state,
                createTaxMessageLoading: false,
                createTaxMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteTaxReducer = (state = { deleteTaxMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_TAX_REQUEST:
            return{
                ...state,
                deleteTaxMessageLoading: true,
                deleteTaxMessageError: null
            }
        case DELETE_TAX_SUCCESS:
            return{
                ...state,
                deleteTaxMessageLoading: false,
                deleteTaxMessage: action.payload
            }
        case DELETE_TAX_FAIL:
            return{
                ...state,
                deleteTaxMessageLoading: false,
                deleteTaxMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadStaffsReducer = (state = { staffs: {} }, action: any) =>{
    switch(action.type){
        case GET_STAFFS_REQUEST:
            return{
                ...state,
                staffsLoading: true,
                staffsError: null
            }
        case GET_STAFFS_SUCCESS:
            return{
                ...state,
                staffsLoading: false,
                staffs: action.payload
            }
        case GET_STAFFS_FAIL:
            return{
                ...state,
                staffsLoading: false,
                staffsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createStaffReducer = (state = { createStaffMessage: {} }, action: any) =>{
    switch(action.type){
        case CREATE_STAFF_REQUEST:
            return{
                ...state,
                createStaffMessageLoading: true,
                createStaffMessageError: null
            }
        case CREATE_STAFF_SUCCESS:
            return{
                ...state,
                createStaffMessageLoading: false,
                createStaffMessage: action.payload
            }
        case CREATE_STAFF_FAIL:
            return{
                ...state,
                createStaffMessageLoading: false,
                createStaffMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteStaffReducer = (state = { deleteStaffMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_STAFF_REQUEST:
            return{
                ...state,
                deleteStaffMessageLoading: true,
                deleteStaffMessageError: null
            }
        case DELETE_STAFF_SUCCESS:
            return{
                ...state,
                deleteStaffMessageLoading: false,
                deleteStaffMessage: action.payload
            }
        case DELETE_STAFF_FAIL:
            return{
                ...state,
                deleteStaffMessageLoading: false,
                deleteStaffMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

//orders
export const loadOrdersReducer = (state = { orders: {} }, action: any) =>{
    switch(action.type){
        case GET_ORDERS_BY_STATUS_REQUEST:
            return{
                ...state,
                ordersLoading: true,
                ordersError: null
            }
        case GET_ORDERS_BY_STATUS_SUCCESS:
            return{
                ...state,
                ordersLoading: false,
                orders: action.payload
            }
        case GET_ORDERS_BY_STATUS_FAIL:
            return{
                ...state,
                ordersLoading: false,
                ordersError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadOrderByIdReducer = (state = { order: {} }, action: any) =>{
    switch(action.type){
        case GET_ORDER_BY_ID_REQUEST:
            return{
                ...state,
                orderLoading: true,
                orderError: null
            }
        case GET_ORDER_BY_ID_SUCCESS:
            return{
                ...state,
                orderLoading: false,
                order: action.payload
            }
        case GET_ORDER_BY_ID_FAIL:
            return{
                ...state,
                orderLoading: false,
                orderError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadInvoiceReducer = (state = { invoice: {} }, action: any) =>{
    switch(action.type){
        case GET_INVOICE_REQUEST:
            return{
                ...state,
                invoiceLoading: true,
                invoiceError: null
            }
        case GET_INVOICE_SUCCESS:
            return{
                ...state,
                invoiceLoading: false,
                invoice: action.payload
            }
        case GET_INVOICE_FAIL:
            return{
                ...state,
                invoiceLoading: false,
                invoiceError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const changeOrderStatusReducer = (state = { changeOrderStatus: {} }, action: any) =>{
    switch(action.type){
        case ORDER_STATUS_CHANGE_REQUEST:
            return{
                ...state,
                changeOrderStatusLoading: true,
                changeOrderStatusError: null
            }
        case ORDER_STATUS_CHANGE_SUCCESS:
            return{
                ...state,
                changeOrderStatusLoading: false,
                changeOrderStatus: action.payload
            }
        case ORDER_STATUS_CHANGE_FAIL:
            return{
                ...state,
                changeOrderStatusLoading: false,
                changeOrderStatusError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const changeOrderStaffReducer = (state = { changeOrderStaff: {} }, action: any) =>{
    switch(action.type){
        case CHANGE_ORDER_STAFF_REQUEST:
            return{
                ...state,
                changeOrderStaffLoading: true,
                changeOrderStaffError: null
            }
        case CHANGE_ORDER_STAFF_SUCCESS:
            return{
                ...state,
                changeOrderStaffLoading: false,
                changeOrderStaff: action.payload
            }
        case CHANGE_ORDER_STAFF_FAIL:
            return{
                ...state,
                changeOrderStaffLoading: false,
                changeOrderStaffError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadAdjustmentsReducer = (state = { adjustments: {} }, action: any) =>{
    switch(action.type){
        case GET_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                adjustmentsLoading: true,
                adjustmentsError: null
            }
        case GET_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                adjustmentsLoading: false,
                adjustments: action.payload
            }
        case GET_ADJUSTMENTS_FAIL:
            return{
                ...state,
                adjustmentsLoading: false,
                adjustmentsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const setAdjustmentReducer = (state = { setAdjustmentMessage: {} }, action: any) =>{
    switch(action.type){
        case SET_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                setAdjustmentMessageLoading: true,
                setAdjustmentMessageError: null
            }
        case SET_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                setAdjustmentMessageLoading: false,
                setAdjustmentMessage: action.payload
            }
        case SET_ADJUSTMENTS_FAIL:
            return{
                ...state,
                setAdjustmentMessageLoading: false,
                setAdjustmentMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const editAdjustmentReducer = (state = { editAdjustmentMessage: {} }, action: any) =>{
    switch(action.type){
        case EDIT_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                editAdjustmentMessageLoading: true,
                editAdjustmentMessageError: null
            }
        case EDIT_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                editAdjustmentMessageLoading: false,
                editAdjustmentMessage: action.payload
            }
        case EDIT_ADJUSTMENTS_FAIL:
            return{
                ...state,
                editAdjustmentMessageLoading: false,
                editAdjustmentMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const deleteAdjustmentReducer = (state = { deleteAdjustmentMessage: {} }, action: any) =>{
    switch(action.type){
        case DELETE_ADJUSTMENTS_REQUEST:
            return{
                ...state,
                deleteAdjustmentMessageLoading: true,
                deleteAdjustmentMessageError: null
            }
        case DELETE_ADJUSTMENTS_SUCCESS:
            return{
                ...state,
                deleteAdjustmentMessageLoading: false,
                deleteAdjustmentMessage: action.payload
            }
        case DELETE_ADJUSTMENTS_FAIL:
            return{
                ...state,
                deleteAdjustmentMessageLoading: false,
                deleteAdjustmentMessageError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

//marketting
export const loadPrintablesReducer = (state = { printables: {} }, action: any) =>{
    switch(action.type){
        case GET_PRINTABLES_REQUEST:
            return{
                ...state,
                printablesLoading: true,
                printablesError: null
            }
        case GET_PRINTABLES_SUCCESS:
            return{
                ...state,
                printablesLoading: false,
                printables: action.payload
            }
        case GET_PRINTABLES_FAIL:
            return{
                ...state,
                printablesLoading: false,
                printablesError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadAnnouncementsReducer = (state = { announcements: {} }, action: any) =>{
    switch(action.type){
        case GET_ANNOUNCEMENTS_REQUEST:
            return{
                ...state,
                announcementsLoading: true,
                announcementsError: null
            }
        case GET_ANNOUNCEMENTS_SUCCESS:
            return{
                ...state,
                announcementsLoading: false,
                announcements: action.payload
            }
        case GET_ANNOUNCEMENTS_FAIL:
            return{
                ...state,
                announcementsLoading: false,
                announcementsError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const createAnnouncementReducer = (state = { createAnnouncement: {} }, action: any) =>{
    switch(action.type){
        case CREATE_ANNOUNCEMENT_REQUEST:
            return{
                ...state,
                createAnnouncementLoading: true,
                createAnnouncementError: null
            }
        case CREATE_ANNOUNCEMENT_SUCCESS:
            return{
                ...state,
                createAnnouncementLoading: false,
                createAnnouncement: action.payload
            }
        case CREATE_ANNOUNCEMENT_FAIL:
            return{
                ...state,
                createAnnouncementLoading: false,
                createAnnouncementError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}

export const loadProductsBySubcategoryReducer = (state = { productsBySubcategory: {} }, action: any) =>{
    switch(action.type){
        case LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST:
            return{
                ...state,
                productsBySubcategoryLoading: true,
                productsBySubcategoryError: null
            }
        case LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS:
            return{
                ...state,
                productsBySubcategoryLoading: false,
                productsBySubcategory: action.payload
            }
        case LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL:
            return{
                ...state,
                productsBySubcategoryLoading: false,
                productsBySubcategoryError: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state
    }
}