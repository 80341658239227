import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonRadioGroup, IonItem, IonRadio, IonInput, IonButton, IonGrid, IonRow, IonCol, IonPage } from '@ionic/react';
import { createCoupon, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import { authenticateUserWhenLogout } from '../../actions/authActions';

const CreateCouponPage = () => {
    const [couponType, setCouponType] = useState("FLAT");
    const [amountOff, setAmountOff] = useState<string | number | null | undefined>("");
    const [minimumPurchase, setMinimumPurchase] = useState<string | number | null | undefined>("");
    const [couponCode, setCouponCode] = useState<string | null | undefined>(Math.random().toString(36).slice(2, 8).toUpperCase());

    //validation
    const [isAmountOffEmpty, setIsAmountOffEmpty] = useState(false);
    const [isAmountOffValidate, setIsAmountOffValidate] = useState(true);
    const [isMinimumPurchaseEmpty, setIsMinimumPurchaseEmpty] = useState(false);
    const [isCouponCodeEmpty, setIsCouponCodeEmpty] = useState(false);
    const [isCouponCodeValidate, setIsCouponCodeValidate] = useState(true);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { createCouponMessageLoading, createCouponMessage, createCouponMessageError } = useSelector((state : any)=> state.createCouponData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    const amountOffValidation = ()=>{
        let result = true;
        if(!amountOff){
            setIsAmountOffEmpty(true);
            result = false;
        }else{
            setIsAmountOffEmpty(false);
            console.log("run");
            console.log("run");
            console.log(couponType);
            if(couponType !== "FLAT" && (0 > amountOff || amountOff > 100)){
                console.log("thi df")
                setIsAmountOffValidate(false);
                result = false;
            }else{
                setIsAmountOffValidate(true);
            }
        }
        return result;
    }

    const minimumPurchaseValidation = ()=>{
        let result = true;
        if(!minimumPurchase || minimumPurchase === '0'){
            setIsMinimumPurchaseEmpty(true);
            result = false;
        }else{
            setIsMinimumPurchaseEmpty(false);
        }
        return result;
    }

    const couponCodeValidation = ()=>{
        let result = true;
        if(!couponCode){
            setIsCouponCodeEmpty(true);
            result = false;
        }else{
            setIsCouponCodeEmpty(false);
            if(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(couponCode)){
                setIsCouponCodeValidate(false);
                result = false;
            }
            else
                setIsCouponCodeValidate(true);
        }
        return result;
    }

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const accessToken = await getAccessToken();
        const res1 = await amountOffValidation();
        const res2 = await couponCodeValidation();
        const res3 = await minimumPurchaseValidation();
        if( res1 && res2 && res3){
            if(currentUser && currentUser.data){
                if(getAccessToken()){
                    await dispatch(createCoupon(accessToken, {
                        store_id : currentUser.data[0].store_id,
                        code: `${couponCode}`,
                        value: `${amountOff}`,
                        method: `${couponType}`,
                        description: "",
                        min_order_value: minimumPurchase
                    }));
                }else{
                    await setLoading(false);
                    await dispatch(authenticateUserWhenLogout())
                    await localStorage.clear();
                    history.push('/');
                }
            }
            setLoading(false);
            if(loading === false && createCouponMessage){
                history.goBack();
            }
        }
        setLoading(false);
    }

    const generateRandomCode = ()=>{
        setCouponCode(Math.random().toString(36).slice(2, 8).toUpperCase());
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/coupons" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Create Coupon</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <form onSubmit={submitHandler}>
                    <IonItem lines="none">
                        <IonLabel position="stacked"><h1>Select coupon type</h1></IonLabel>
                        <IonGrid className="ion-no-margin ion-no-padding ion-padding-bottom ion-padding-top" style={{width: 100+'%'}}>
                            <IonRadioGroup name="couponType" value={couponType} onIonChange={e => setCouponType(e.detail.value)}>
                                <IonRow>
                                    <IonCol size="5">
                                        <IonItem lines="none">
                                            <IonRadio slot="start" value="FLAT" />
                                            <IonLabel>Flat</IonLabel>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem lines="none">
                                            <IonLabel>Percentage</IonLabel>
                                            <IonRadio slot="start" value="PERCENTAGE" />
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonRadioGroup>
                        </IonGrid>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked"><h1>{`${couponType === "FLAT" ? "Enter amount off" : "Enter percentage off"}`}</h1></IonLabel>
                        <br/>
                        <IonInput placeholder="Promo Value" type="number" value={amountOff} name="amountOff" onIonChange={(e)=>{ setAmountOff(e.detail.value)}}/>
                    </IonItem>
                    <IonText color="danger" className={`${isAmountOffEmpty === false && isAmountOffValidate === true ? "ion-hide": "" }`}>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isAmountOffEmpty ? "" : "ion-hide"}`}>Promo value is required.</h5>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isAmountOffValidate ? "ion-hide" : ""}`}>Percentage off is not greater than 100 or less than 0.</h5>
                    </IonText>
                    <br/>
                    <IonItem>
                        <IonLabel position="stacked"><h1>Minimum purchase for coupon</h1></IonLabel>
                        <br/>
                        <IonInput placeholder="0" type="number" value={minimumPurchase} name="minimumPurchase" onIonChange={(e)=>{ setMinimumPurchase(e.detail.value)}}/>
                    </IonItem>
                    <IonText color="danger" className={`${isMinimumPurchaseEmpty === false ? "ion-hide": "" }`}>
                        <h5 className="ion-margin-horizontal" style={{fontSize:12+'px', marginTop: 0+'px'}}>Minimum Purchase is required or can't be 0.</h5>
                    </IonText>
                    <br/>
                    <IonItem>
                        <IonGrid className="ion-no-padding ion-no-margin" style={{width:100+'%'}}>
                            <IonRow className="ion-align-items-center">
                                <IonCol size="8">
                                    <IonLabel position="stacked">
                                        <h1>Enter coupon code</h1>
                                    </IonLabel>
                                </IonCol>
                                <IonCol className="ion-padding-top">
                                    <IonText color="primary" onClick={e => generateRandomCode()}>GENERATE</IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                            
                            <br/>
                        <IonInput placeholder="PICYHUKA" type="text" value={couponCode} name="couponCode" onIonChange={(e)=>{ setCouponCode(e.detail.value)}}/>
                    </IonItem>
                    <IonText color="danger" className={`${isCouponCodeEmpty === false && isCouponCodeValidate === true ? "ion-hide": "" }`}>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isCouponCodeEmpty ? "" : "ion-hide"}`}>Coupon code is required.</h5>
                        <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isCouponCodeValidate ? "ion-hide" : ""}`}>Coupon code can't contain special characters.</h5>
                    </IonText>
                    <br/>
                    <IonButton type="submit">Create Coupon</IonButton>
                </form>
                <IonLoading isOpen={loading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default CreateCouponPage
