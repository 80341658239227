import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import { useHistory } from 'react-router';
import { authenticateUserWhenLogout } from './actions/authActions';
import { useDispatch } from 'react-redux';

interface RedirectToLoginProps {
  setIsLoggedIn: Function;
  setUsername: Function;
}

const RedirectToLogin = () => {
  const ionRouterContext = useContext(IonRouterContext);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    fun();
  }, [ionRouterContext]);
  const fun = async ()=>{
    await dispatch(authenticateUserWhenLogout())
    await localStorage.clear();
    ionRouterContext.push('/')
  }
  return null;
};

export default RedirectToLogin;
