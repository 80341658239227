import React , { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { 
    IonContent, 
    IonPage, 
    IonText, 
    IonButton,
    IonImg,
    IonLoading,
    IonIcon, 
} from '@ionic/react';
import LogoImage from '../components/auth/LogoImage';
import GoogleLogo from '../images/auth/GoogleLogo.png';
import FacebookLogo from '../images/auth/FacebookLogo.png';
import OTPEmail from '../images/auth/OTPEmail.png';
import LoginFooter from '../components/auth/LoginFooter';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
//import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
import { getUserTokens, getCurrentUser, clearErrors, authenticateUserWhenLogin, authenticateUserWhenLogout } from '../actions/authActions';
import { getAccessToken, setTokensObject } from '../services/auth/userLocalStorage';
import { isDesktop } from 'react-device-detect';
import { ellipsisHorizontal, ellipsisVertical, logoGoogle, mail } from 'ionicons/icons';

const Home: React.FC = () => {
    const [doReload, setDoReload] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const { userAPITokensLoading, userAPITokens, loadUserAPITokensError } = useSelector((state : any)=> state.userAPITokens);
    const { currentUserLoading, currentUser, currentUserError } = useSelector((state : any)=> state.currentUser);
    const { isUserAuthenticate } = useSelector((state : any)=> state.userAuthenticate);

    useEffect(() => {
        sessionStorage.setItem('reloadCount', String(1));
        GoogleAuth.init();
    }, []);

    useEffect(() => {
        if(userAPITokensLoading === false){
            if(userAPITokens){
                setTokensObject(userAPITokens);
                loadCurrentUser(userAPITokens.access_token);
            }
        }
    }, [userAPITokens]);

    useEffect(() => {
        if(currentUserLoading === false && getAccessToken()){
            if(currentUser && currentUser.data){
                dispatch(authenticateUserWhenLogin());
                if(currentUser.data[0].store.storetype_id !== 0){
                    history.push('/my/userdashboard', {direction: 'none'});
                }else{
                    history.push('/getbusinessdetails');
                }
            }
        }
    }, [currentUser]);

    const loadCurrentUser = async (token: string)=>{
        await dispatch(getCurrentUser(token));
    }

    useEffect(() => {
        if(isUserAuthenticate === false){
            setDoReload(true);
        }
    }, [isUserAuthenticate])

    useEffect(() => {
        if(doReload){
            window.location.reload();
        }
    }, [doReload])

    const signInWithGoogle = async () => {
        const userFromGoogle = await GoogleAuth.signIn();
        if (userFromGoogle) {
            const formData = new FormData();
            formData.append('username',userFromGoogle.email);
            formData.append('password',userFromGoogle.authentication.accessToken);
            formData.append('grant_type',"password");
            formData.append('client_id', "6");
            formData.append('client_secret',"crm-45eurtdfykg978ilyukt897td");
            formData.append('scope','store')
            await dispatch(getUserTokens(formData,"google"));
        }
    }

    const signInWithFacebook = async () => {
        // const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
        // FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then((user)=>{
        //     console.log(user);
        // })
    }
    let classOfLogin = "";
    let classOfLoginButton = "loginButtonMobile";
    let classOfLogo = "iklogologin";
    if(isDesktop){
        classOfLogin = classOfLogin +  " dtLogin";
        classOfLoginButton = "loginButtonDt";
        classOfLogo = "iklogologindt";
    }
    return (
        <IonPage  className={classOfLogin}>
            <IonContent className="ion-padding ion-text-center">
                <div className={classOfLogo}>
                <LogoImage/>
                </div>
                <IonText>
                    <h5>Login to Proceed</h5>
                </IonText>

                <IonButton  className={classOfLoginButton} shape="round"  color="light" onClick={()=>signInWithGoogle()}>
                <IonIcon  className="iconlogin" slot="start" icon={logoGoogle}/>
                Continue with Google&nbsp;&nbsp;&nbsp;
                    {/* <IonImg src={GoogleLogo}/> */}
                    {/* <IonText color="dark">Continue with Google</IonText> */}
                </IonButton>

                {/* <IonButton expand="block" shape="round" onClick={()=>signInWithFacebook()} style={{marginBottom: 2+'vh', marginLeft: 10+'%', marginRight: 10+'%'}}>
                    <IonImg style={{height:35+'px',width:35+'px'}} src={FacebookLogo}/>&nbsp;
                    <IonText>Continue with Facebook</IonText> 
                </IonButton> */}

                <IonButton className={classOfLoginButton} shape="round"  color="light" onClick={()=>{history.push("/signupwithgmail")}}>
                <IonIcon  slot="start" icon={mail}/>
                Continue with Email OTP
                    {/* <IonImg src={OTPEmail}/> */}
                    {/* <IonText color="dark">Continue with Email OTP</IonText> */}
                </IonButton>
                <IonLoading isOpen={ userAPITokensLoading === true || currentUserLoading === true } message={'Please Wait...'}/>
            </IonContent>
            <LoginFooter/>
        </IonPage>
    )
}

export default Home
