import React from 'react';
import { IonContent, IonApp, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonImg, IonInput, IonGrid, IonRow, IonCol, IonPage, IonFooter, IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonList } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import {
    informationCircle as informationCircleIcon,
    globeOutline as globeOutlineIcon,
    apps as appsIcon,
    logoUsd as logoUsdIcon,
    language as languageIcon,
    walletOutline as walletOutlineIcon,
    newspaperOutline as newspaperOutlineIcon,
    homeOutline,
    cashOutline,
    appsOutline,
    settingsOutline,
    chevronForwardOutline,
    radioButtonOnOutline,
    createOutline
} from 'ionicons/icons';
import Title from '../../components/dashboard/Header';
import { useHistory } from 'react-router';

const Settings = () => {
    const history =useHistory();
    return (
        <IonPage>
                <Title name="Settings"/>
                <IonContent  >
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonCard routerLink="/my/settings/basicinfo" >
                                    <IonItem lines="none"> 
                                        <IonIcon slot="start" icon={informationCircleIcon} size="large"/>
                                        <IonText >
                                            Basic Info
                                            {/* <p>Add or Update Business Name.</p>
                                            <p>Add or Update GSTIN.</p>
                                            <p>Add or Update Logo.</p> */}
                                        </IonText> 
                                        <IonIcon slot="end" icon={chevronForwardOutline} size="large"/>
                                    </IonItem> 
                                </IonCard>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                            <IonList className='ion-padding-horizontal'>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update&nbsp;&nbsp; <IonButton color='light' routerLink="/my/settings/basicinfo">Logo</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/basicinfo">Business Name</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update&nbsp;&nbsp; <IonButton color='light' routerLink="/my/settings/basicinfo">GSTIN</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>  
                            </IonList>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                                <IonCard routerLink="/my/settings/localization">
                                    <IonItem lines="none"> 
                                        <IonIcon slot="start" icon={globeOutlineIcon} size="large"/>
                                        <IonText >
                                            Localization
                                        </IonText>
                                        <IonIcon slot="end" icon={chevronForwardOutline} size="large"/>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                            <IonList className='ion-padding-horizontal'>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update&nbsp;&nbsp; <IonButton color='light' routerLink="/my/settings/localization">Address</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                            </IonList>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                                <IonCard routerLink="/my/settings/servicedetails">
                                    <IonItem lines="none"> 
                                        <IonIcon slot="start" icon={appsIcon} size="large"/>
                                        <IonText >
                                            Service Details
                                        </IonText>
                                        <IonIcon slot="end" icon={chevronForwardOutline} size="large"/>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                            </IonRow>
                            
                            <IonRow>
                            <IonCol>
                            <IonList className='ion-padding-horizontal'>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/servicedetails">Whatsapp number</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/servicedetails">Phone number</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/servicedetails">Minimum Order</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/servicedetails">Store Timings</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem> 
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/servicedetails">Delivery Range</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem> 
                            </IonList>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                                <IonCard routerLink="/my/settings/taxes">
                                    <IonItem lines="none"> 
                                        <IonIcon slot="start" icon={logoUsdIcon} size="large"/>
                                        <IonText  >
                                            Taxes & Charges
                                        </IonText>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                            <IonList className='ion-padding-horizontal'>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/taxes">Taxes & charges</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                            </IonList>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                                <IonCard routerLink="/my/settings/payments">
                                    <IonItem lines="none"> 
                                        <IonIcon slot="start" icon={walletOutlineIcon} size="large"/>
                                        <IonText  >
                                        Order Payments
                                        </IonText>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                            <IonList className='ion-padding-horizontal'>
                                <IonItem > 
                                    <IonIcon slot='start' icon={radioButtonOnOutline} size="small"/> 
                                    Add or update &nbsp;&nbsp;<IonButton color='light' routerLink="/my/settings/payments">Mode of Payments</IonButton>
                                    {/* <IonIcon slot="end" icon={createOutline}  /> */}
                                </IonItem>
                            </IonList>
                            </IonCol>
                            </IonRow>
                            <IonRow>
                            <IonCol>
                                <IonCard routerLink="/my/settings/quickorders">
                                    <IonItem lines="none"> 
                                        <IonIcon slot="start" icon={newspaperOutlineIcon} size="large"/>
                                        <IonText >
                                            Quick Orders
                                        </IonText>
                                    </IonItem>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            <IonFooter>
        
    </IonFooter>
        </IonPage>
    )
}

export default Settings
