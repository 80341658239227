import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonBackButton, IonHeader, IonToolbar, IonButtons, IonTitle, IonItem, IonInput, IonButton, IonPage, IonList,IonCard, IonImg, IonToggle, IonGrid, IonRow, IonCol, isPlatform } from '@ionic/react';
import { clearErrors, setPayment, getPayment } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    arrowBack as arrowBackIcon
} from 'ionicons/icons';
import paymentGatewayImage from '../../images/paymentsettings/paymentGatewayImage.png';
import CODImage from '../../images/paymentsettings/CODImage.png';
import paytmImage from '../../images/paymentsettings/paytmImage.png';
import { Camera, CameraResultType } from '@capacitor/camera';

async function savePicture(file) {
    if(!file)return ""
    try{
        const formData = new FormData();
        formData.append("file",file)
        const response = await fetch(`https://api.intelikart.com/api/upload/store-settings-image`, {
        method: 'POST',
        body:formData
        });
        const data = await response.json();
        return data.platform_path;
    }
    catch(error){
        console.log(error)
        return ""
    }
  }

const Payments = () => {
    const [bankAccount, setBankAccount] = useState<any>();
    const [conBankAccount, setConBankAccount] = useState<any>();
    const [ifscCode, setIfscCode] = useState<any>(0);
    const [accountHolderName, setAccountHolderName] = useState<any>();
    const [paytmNumber, setPaytmNumber] = useState<any>();
    const [gatewayChecked, setGatewayChecked] = useState<any>(false);
    const [codChecked, setCodChecked] = useState<any>(true);
    const [paytmChecked, setPaytmChecked] = useState<any>(false);
    const [pictureUrl, setPictureUrl] = useState('https://cdn.intelikart.com/uploads/new-images/default-store.png');
    const[imageFile,setImageFile]=useState(null)
    const fileInputRef = useRef<HTMLInputElement>();
    const[loading,setLoading]=useState(false)

    //Whatsapp number validation
    const [isBankAccountValid, setIsBankAccountValid] = useState(true);
    const [isConBankAccountValid, setIsConBankAccountValid] = useState(true);
    const [isIfscCodeValid, setIsIfscCodeValid] = useState(true);
    const [isHolderNameValid, setIsHolderNameValid] = useState(true);
    const [isPaytmNumberValid, setIsPaytmNumberValid] = useState(true);

    const [isBankAccountEmpty, setIsBankAccountEmpty] = useState(false);
    const [isIfscCodeEmpty, setIsIfscCodeEmpty] = useState(false);
    const [isHolderNameEmpty, setIsHolderNameEmpty] = useState(false);
    const [isPaytmNumberEmpty, setIsPaytmNumberEmpty] = useState(false);

    const dispatch = useDispatch();
    const { paymentLoading, payment, paymentError } = useSelector((state : any)=> state.payment);
    const { setPaymentResponseLoading, setPaymentResponse, setPaymentResponseError } = useSelector((state : any)=> state.setPayment);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getPayment(getAccessToken(), currentUser.data[0].store_id));
        }
    }, [currentUser]);
    

    useEffect(() => {
        if(paymentLoading === false && payment && payment.data){
            setBankAccount(payment.data.bank_account);
            setConBankAccount(payment.data.bank_account);
            setIfscCode(payment.data.bank_ifsc_code);
            setAccountHolderName(payment.data.bank_account_holder_name);
            setPaytmNumber(payment.data.paytm_phone);
            if(payment.data.paytm_image!=null){
                setPictureUrl(payment.data.paytm_image_display)
            }
            setPaytmChecked(payment.data.enable_pay_via_paytm === 0 ? false : true);
            setGatewayChecked(payment.data.enable_payment_gateway === 0 ? false : true);
            setCodChecked(payment.data.enable_cod === 0 ? false : true);
        }
    }, [payment]);

    const bankAccountValidation = ()=>{
        if(gatewayChecked){
            let result = true;
            if(!bankAccount && gatewayChecked){
                setIsBankAccountEmpty(true);
                result = false;
            }else{
                setIsBankAccountEmpty(false);
                if(/\D/.test(bankAccount) && gatewayChecked){
                    setIsBankAccountValid(false);
                    result = false;
                }
                else{
                    setIsBankAccountValid(true);
                    if((bankAccount.length < 9 || bankAccount.length > 18) && gatewayChecked){
                        setIsBankAccountValid(false);
                        result = false;
                    }else
                        setIsBankAccountValid(true);
                }
            }
            return result;
        }
        return true;
    }

    const conBankAccountValidation = ()=>{
        if(gatewayChecked){
            let result = true;
            if(conBankAccount !== bankAccount){
                setIsConBankAccountValid(false);
                result = false;
            }else{
                setIsConBankAccountValid(true);
            }
            return result;
        }
        return true;
    }

    const ifscValidation = ()=>{
        if(gatewayChecked){
            let result = true;
            if(!ifscCode){
                setIsIfscCodeEmpty(true);
                result = false;
            }else{
                setIsIfscCodeEmpty(false);
                if(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(ifscCode)){
                    setIsIfscCodeValid(false);
                    result = false;
                }else{
                    setIsIfscCodeValid(true);
                    if(ifscCode.length > 11){
                        setIsIfscCodeValid(false);
                        result = false;
                    }else
                        setIsIfscCodeValid(true);
                }
            }
            return result;
        }
        return true;
    }

    const accountHolderNameValidation = ()=>{
        if(gatewayChecked){
            let result = true;
            if(!accountHolderName){
                setIsHolderNameEmpty(true);
                result = false;
            }else{
                setIsHolderNameEmpty(false);
                if(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(accountHolderName)){
                    setIsHolderNameValid(false);
                    result = false;
                }
                else
                    setIsHolderNameValid(true);
            }
            return result;
        }
        return true;
    }

    const paytmNumberValidation = ()=>{
        if(paytmChecked){
            let result = true;
            if(!paytmNumber){
                setIsPaytmNumberEmpty(true);
                result = false;
            }else{
                setIsPaytmNumberEmpty(false);
                if(/\D/.test(paytmNumber)){
                    setIsPaytmNumberValid(false);
                    result = false;
                }
                else{
                    setIsPaytmNumberValid(true);
                    if(paytmNumber.length !== 10){
                        setIsPaytmNumberValid(false);
                        result = false;
                    }else
                        setIsPaytmNumberValid(true);
                }
            }
            return result;
        }
        return true;
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files.length > 0) {
          const file = event.target.files.item(0);
          setImageFile(file)
          const pictureUrl = URL.createObjectURL(file);
          setPictureUrl(pictureUrl);
        }
      };
      const handlePictureClick= async()=>{
        
        if(isPlatform('capacitor')){
          try{
            const photo=await Camera.getPhoto({
              resultType:CameraResultType.Uri,
            })
            setPictureUrl(photo.webPath);
          }
          catch(error){
            console.log(error);
          }
        }
        else{
          fileInputRef.current.click();
        }
        
      }


    const submitHandler = async () => {
        if(gatewayChecked && !paytmChecked){
            setPaytmNumber(null);
        }else if(!gatewayChecked && paytmChecked){
            setBankAccount(null);
            setIfscCode(null);
            setAccountHolderName(null);
        }else if(!gatewayChecked && !paytmChecked){
            setPaytmNumber(null);
            setBankAccount(null);
            setIfscCode(null);
            setAccountHolderName(null);
        }else{}
        const res1 = await bankAccountValidation();
        const res2 = await conBankAccountValidation();
        const res3 = await ifscValidation();
        const res4 = await accountHolderNameValidation();
        const res5 = await paytmNumberValidation();
        if( res1 && res2 && res3 && res4 && res5 && currentUser && currentUser.data ){
            setLoading(true)
            let platform_path= await  savePicture(imageFile)
            if(!platform_path)platform_path=payment.data.paytm_image
            Promise.all([await dispatch(setPayment(getAccessToken(), currentUser.data[0].store_id, {
                    enable_cod: `${codChecked ? 1 : 0}`,
                    enable_pay_via_paytm: `${paytmChecked ? 1 : 0}`,
                    paytm_phone: paytmNumber ? `${paytmNumber}` : null,
                    paytm_image:platform_path,
                    enable_payment_gateway: `${gatewayChecked ? 1 : 0}`,
                    bank_account: bankAccount ? `${bankAccount}` : null,
                    bank_ifsc_code: ifscCode ? `${ifscCode}` : null,
                    bank_account_holder_name: accountHolderName ? `${accountHolderName}` : null,
            })), dispatch(getPayment(getAccessToken(), currentUser.data[0].store_id))]);
            setLoading(false)
            }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/my/settings" icon={arrowBackIcon}/>
                    </IonButtons>
                    <IonTitle>Order Payments</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonList>
                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={paymentGatewayImage} slot="start"></IonImg>
                            <IonText><h5>Payment Gateway</h5></IonText>
                            <IonToggle slot="end" checked={gatewayChecked} onIonChange={e => setGatewayChecked(e.detail.checked)} />
                        </IonItem>
                        {gatewayChecked === true ? 
                        <>
                            <IonText className="ion-text-center">
                                <p>2.5% payment gateway charges and 2 rupees per settlement would be charged</p>
                                <p>Add your account details for settlements</p>
                            </IonText>
                                <IonGrid>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Bank Account</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Bank Account Number" type="tel" value={bankAccount} name="bankAccount" onIonChange={(e)=>{ setBankAccount(e.detail.value)}}/>
                                            </IonItem>
                                            <IonText color="danger" className={`${isBankAccountEmpty === false && isBankAccountValid === true ? "ion-hide": "" }`}>
                                                <h5 style={{fontSize:12+'px'}} className={`ion-margin-horizontal ${isBankAccountEmpty ? "" : "ion-hide"}`}>Bank Account is required.</h5>
                                                <h5 style={{fontSize:12+'px'}} className={`ion-margin-horizontal ${isBankAccountValid ? "ion-hide" : ""}`}>Bank Account is not valid.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Confirm Bank Account</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Bank Account Number" type="tel" value={conBankAccount} name="confirmBankAccount" onIonChange={(e)=>{ setConBankAccount(e.detail.value)}}/>
                                            </IonItem>
                                            <IonText color="danger" className={`${isConBankAccountValid === true ? "ion-hide": "" }`}>
                                                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className="ion-margin-horizontal">Confirm Bank Account is not same.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>IFSC Code</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Enter IFSC Code" type="text" value={ifscCode} name="ifscCode" onIonChange={(e)=>{ setIfscCode(e.detail.value)}}/>
                                            </IonItem>
                                            <IonText color="danger" className={`${isIfscCodeEmpty === false && isIfscCodeValid === true ? "ion-hide": "" }`}>
                                                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isIfscCodeEmpty ? "" : "ion-hide"}`}>IFSC Code is required.</h5>
                                                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isIfscCodeValid ? "ion-hide" : ""}`}>IFSC Code is not correct.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow className="ion-align-items-center">
                                        <IonCol size="4">
                                            <IonLabel>Account Holder Name</IonLabel>
                                        </IonCol>
                                        <IonCol>
                                            <IonItem>
                                                <IonInput placeholder="Enter Account Holder Name" type="text" value={accountHolderName} name="accountHolderName" onIonChange={(e)=>{ setAccountHolderName(e.detail.value)}}/>
                                            </IonItem>
                                            <IonText color="danger" className={`${isHolderNameEmpty === false && isHolderNameValid === true ? "ion-hide": "" }`}>
                                                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isHolderNameEmpty ? "" : "ion-hide"}`}>Account Holder Name is required.</h5>
                                                <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isHolderNameValid ? "ion-hide" : ""}`}>Account Holder Name can't contain special characters.</h5>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                        </>
                        : null
                    }
                    </IonCard>
                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={CODImage} slot="start"></IonImg>
                            <IonText><h5>Cash on Delivery</h5></IonText>
                            <IonToggle slot="end" checked={codChecked} onIonChange={e => setCodChecked(e.detail.checked)} />
                        </IonItem>
                    </IonCard>
                    <IonCard>
                        <IonItem lines="none">
                            <IonImg src={paytmImage} slot="start"></IonImg>
                            <IonText><h5>Pay via Paytm</h5></IonText>
                            <IonToggle slot="end" checked={paytmChecked} onIonChange={e => setPaytmChecked(e.detail.checked)} />
                        </IonItem>
                        {paytmChecked === true ? 
                        <>
                            <IonGrid>
                                <IonRow className="ion-align-items-center">
                                    <IonCol size="4">
                                        <IonLabel>Paytm Number</IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem>
                                            <IonInput placeholder="Enter paytm number" type="text" value={paytmNumber} name="paytmNumber" onIonChange={(e)=>{ setPaytmNumber(e.detail.value)}} onIonBlur={()=>paytmNumberValidation()}/>
                                        </IonItem>
                                        <IonText color="danger" className={`${isPaytmNumberEmpty === false && isPaytmNumberValid === true ? "ion-hide": "" }`}>
                                            <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isPaytmNumberEmpty ? "" : "ion-hide"}`}>Paytm Number is required.</h5>
                                            <h5 style={{fontSize:12+'px', marginTop: 0+'px'}} className={`ion-margin-horizontal ${isPaytmNumberValid ? "ion-hide" : ""}`}>Paytm Number is incorrect.</h5>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel  >Paytm QR Code</IonLabel>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center">
                                    <IonCol size='6'>
                                        <IonItem>
                                        <input hidden type='file' accept="image/*" ref={fileInputRef}
                                        onChange={handleFileChange}/>
                                        { pictureUrl ? <img width="90%" height="225" src={pictureUrl} alt=""></img> : null }
                                        </IonItem>
                                    </IonCol>
                                    <IonCol >
                                        <IonButton  shape="round" size='small'  onClick={handlePictureClick}>Update QR code</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </>
                        : null
                    }
                    </IonCard>
                </IonList>
                <div className="ion-text-center">
                    <IonButton shape="round" type="button" onClick={(e)=> submitHandler()}>Save</IonButton>
                </div>
                <IonLoading isOpen={loading ===true || paymentLoading === true || setPaymentResponseLoading === true} message="Please Wait"></IonLoading>
            </IonContent>
        </IonPage>
    )
}

export default Payments
