import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonText, IonItem, IonIcon, IonLoading, IonCard, IonButton, IonAlert, IonPage } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getStaffs, deleteStaff, clearErrors } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import {
    trash as trashIcon,
    shareSocial as shareSocialIcon
} from 'ionicons/icons';

const Staffs = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const { staffsLoading, staffs, staffsError } = useSelector((state : any)=> state.staffs);
    const { deleteStaffMessageLoading, deleteStaffMessage, deleteStaffMessageError } = useSelector((state : any)=> state.deleteStaffData);
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { createStaffMessageLoading, createStaffMessage, createStaffMessageError } = useSelector((state : any)=> state.createStaffData);

    useEffect(() => {
        if(currentUser && currentUser.data)
            dispatch(getStaffs(getAccessToken(), currentUser.data[0].store_id));
    }, [currentUser, deleteStaffMessageLoading]);

    useEffect(() => {
        if(currentUser && currentUser.data && deleteStaffMessageLoading === false){
            dispatch(getStaffs(getAccessToken(), currentUser.data[0].store_id));
        }
    }, [deleteStaffMessageLoading]);

    useEffect(() => {
        if(currentUser && currentUser.data && createStaffMessageLoading === false){
            dispatch(getStaffs(getAccessToken(), currentUser.data[0].store_id));
        }
    }, [createStaffMessageLoading]);

    useEffect(() => {
        if(currentUser && currentUser.data)
            dispatch(getStaffs(getAccessToken(), currentUser.data[0].store_id));
    }, [currentUser, deleteStaffMessageLoading]);

    const onStaffDeleteClick = async (value: any)=>{
        if(currentUser && currentUser.data && value)
            await dispatch(deleteStaff(getAccessToken(), currentUser.data[0].store_id, value.id));
    }
    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Staff"/>
                <IonContent className="ion-padding">
                    <IonText className="ion-text-center ion-margin-bottom">
                        <h5 className="ion-no-margin">Add staff for your store</h5>
                        <p>Add drivers to assign orders</p>
                    </IonText>
                    {
                        staffsLoading === false && staffs && staffs.data && staffs.data.length !== 0 ? 
                        staffs.data.map((value: any)=>{
                            return (
                                <IonCard key={value.id}>
                                    <IonItem>
                                        <IonText>
                                            <h5>{value.name}</h5>
                                            <p>{value.email}</p>
                                        </IonText>
                                        <IonIcon slot="end" size="large" icon={trashIcon} onClick={(e)=> {setAlertData(value); setShowAlert(true)}}></IonIcon>
                                    </IonItem>
                                </IonCard>
                            );
                        }) : null
                    }
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/my/createstaff">ADD STAFF</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Staff'}
                        message={`You are about to delete staff ${alertData && alertData.name ? alertData.name : ""}`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { onStaffDeleteClick(alertData); }
                            }
                        ]}
                    />

                    <IonLoading isOpen={staffsLoading === true || deleteStaffMessageLoading === true} message="Please Wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default Staffs
