import { CREATE_PRODUCT_FAIL, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, EDIT_PRODUCT_FAIL, EDIT_PRODUCT_REQUEST, EDIT_PRODUCT_SUCCESS, GET_PRODUCT_BY_ID_REQUEST, GET_PRODUCT_BY_ID_SUCCESS, GET_PRODUCT_BY_ID_FAIL,  SEARCH_PRODUCT_QUERY_REQUEST, SEARCH_PRODUCT_QUERY_SUCCESS, SEARCH_PRODUCT_QUERY_FAIL } from "../constants/productConstants";

const API_ROOT = 'https://api.intelikart.com/';

export const getProductById = (productId: string | number) => async (dispatch: any) => {
    try{
        dispatch({type : GET_PRODUCT_BY_ID_REQUEST})
        const response = await fetch(`${ API_ROOT }api/search/products?productId=${productId}`, {
            method: 'GET'
        });
        const data = await response.json();
        dispatch({
            type: GET_PRODUCT_BY_ID_SUCCESS,
            payload: data.data[0]
        })
    }catch(error) {
        dispatch({
            type: GET_PRODUCT_BY_ID_FAIL,
            payload: error
        })
    }
}

export const createProduct = (productData: any, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : CREATE_PRODUCT_REQUEST})
        const response = await fetch(`${ API_ROOT }/api/products`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            },
            body: JSON.stringify(productData)
        });
        const data = await response.json();
        dispatch({
            type: CREATE_PRODUCT_SUCCESS,
            payload: data.data
        })
    }catch(error) {
            dispatch({
                type: CREATE_PRODUCT_FAIL,
                payload: error
            })
    }
}

export const editProduct = (productData: any, accessToken: string) => async (dispatch: any) => {
    try{
        dispatch({type : EDIT_PRODUCT_REQUEST})
        const response = await fetch(`${ API_ROOT }api/products`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`
            },
            body: JSON.stringify(productData)
        });
        const data = await response.json();
        dispatch({
            type: EDIT_PRODUCT_SUCCESS,
            payload: data.data
        })
    }catch(error) {
            dispatch({
                type: EDIT_PRODUCT_FAIL,
                payload: error
            })
    }
}

export const deleteProduct = (accessToken: string,storeId: string,productId) => async (dispatch: any) => {

    try{
        dispatch({type : DELETE_PRODUCT_REQUEST})
        const response = await fetch(`${ API_ROOT }api/stores-products/${productId}?storeId=${storeId}`, {
            method: 'DELETE',
            headers: {
                'authorization': `${accessToken}`
            },
        });
        const data = await response.json();
        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: data
        })
                
    }catch(error) {
            dispatch({
                type: DELETE_PRODUCT_FAIL,
                payload: error
            })
    }
}


export const searchQueryProduct = (query: string, storeId: string | number) => async (dispatch: any) => {

    try{
        dispatch({type : SEARCH_PRODUCT_QUERY_REQUEST})
        const response = await fetch(`${ API_ROOT }api/search/products?query=${query}&storeId=${storeId}`, {
            method: 'GET'
        });
        const data = await response.json();
        dispatch({
            type: SEARCH_PRODUCT_QUERY_SUCCESS,
            payload: data
        })
                
    }catch(error) {
            dispatch({
                type: SEARCH_PRODUCT_QUERY_FAIL,
                payload: error
            })
    }
}
