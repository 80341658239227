import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonLabel, IonText, IonGrid, IonCol,  IonItem, IonInput, IonIcon, IonButton, IonRow, IonLoading, IonToast, IonPage } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'


const Subscriptions = () => {
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Subscription"/>
                <IonContent>
                    {
                        currentUser && currentUser.data ? 
                        <iframe style={{ border: 'none', width: 100+'%', height: 99+'%', overflow: 'hidden', zIndex: 99 }} src={`https://subscriptions.intelikart.com/${currentUser.data[0].store_id}/${getAccessToken()}`}></iframe> : null
                    }
                </IonContent>
            </div>
        </IonPage>
    )
}

export default Subscriptions
