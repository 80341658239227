import React from 'react'
import { IonImg } from '@ionic/react';
import IntelikartLogo from '../../images/IntelikartLogo.png';

const LogoImage = () => {
    return (
        <IonImg src={IntelikartLogo}/>
    )
}

export default LogoImage
