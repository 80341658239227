import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonApp, IonLabel, IonText, IonGrid, IonCol,  IonItem, IonInput, IonIcon, IonButton, IonRow, IonLoading, IonToast, IonPage, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import DashboardMenu from '../../components/dashboard/DashboardMenu';
import { getCustomers } from '../../actions/dashboardActions';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import Header from '../../components/dashboard/Header'
import {
    search as serachIcon,
    logoWhatsapp as whatsappIcon,
    call as phoneIcon
} from 'ionicons/icons';
import { Link, useHistory } from 'react-router-dom';
import { authenticateUserWhenLogout } from '../../actions/authActions';

const Customer = () => {
    const [customer, setcustomer] = useState<undefined | null | string>("");
    const [customersArrayChange, setCustomersArrayChange] = useState(1);
    const [customersArray, setCustomersArray] = useState<any>();
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(true);
    const [page, setPage] = useState<any>(2);

    const dispatch = useDispatch();
    const history = useHistory();
    const { customersLoading, customers, customersError } = useSelector((state : any)=> state.customers);
    const { currentUser } = useSelector((state : any)=> state.currentUser);

    useEffect(() => {}, [customersArrayChange]);

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getCustomers(getAccessToken(), currentUser.data[0].store_id , null));}
    }, [currentUser]);

    useEffect(()=>{
		if(customersLoading === false){
			if(customers && customers.data && customers.data.length > 0){
				setDisableInfiniteScroll(Boolean(customers.data.length < 20));
			}else{
				setDisableInfiniteScroll(true);
			}
		}
        if(customersLoading === false && customers && customers.data){
			setCustomersArrayChange(customersArrayChange+1);
            setCustomersArray(customers.data);
        }
	},[customersLoading]);

    async function searchNext($event: CustomEvent<void>) {
		await fetchNextData();
		($event.target as HTMLIonInfiniteScrollElement).complete();
	}
	const fetchNextData = async()=>{
        if(getAccessToken()){
            const res = await fetch(`https://api.intelikart.com/api/customers?page=${page}&storeId=${currentUser.data[0].store_id}`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json",
                    'authorization': `${getAccessToken()}`
                }
            });
            res.json().then(async (res) => {
                setPage(page+1);
                if (res && res.data && res.data.length > 0) {
                    setCustomersArrayChange(customersArrayChange+1);
                    setCustomersArray([...customersArray, ...res.data]);
                    setDisableInfiniteScroll(res.data.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
            }).catch(err => console.error(err));
        }else{
            await dispatch(authenticateUserWhenLogout())
            await localStorage.clear();
            history.push('/')
        }
	}

    const searchCustomerByName = async () =>  {
        if(currentUser && currentUser.data){
            if(getAccessToken()){
                await dispatch(getCustomers(getAccessToken(), currentUser.data[0].store_id , customer?customer:null));
            }else{
                await dispatch(authenticateUserWhenLogout())
                await localStorage.clear();
                history.push('/')
            }
        }
    }
    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Customer"/>
                <IonContent className="ion-padding">
                    <IonItem className="ion-margin-bottom">
                        <IonLabel><IonIcon icon={serachIcon}></IonIcon></IonLabel>
                        <IonInput placeholder="Enter Input" onIonChange={(e)=> setcustomer(e.detail.value)}></IonInput>
                        <IonButton shape="round"  onClick={e =>searchCustomerByName()}>Search</IonButton>
                    </IonItem>
                    <IonGrid className="ion-margin-bottom">
                        <IonRow className="ion-align-items-center">
                            <IonCol>
                                <IonText>
                                    Total: {customersLoading === false && customers.data && customersArray ? customersArray.length : 0}
                                </IonText>
                            </IonCol>
                            <IonCol>
                                <Link to={{ pathname: "/my/marketing", state: true }} className="ion-margin-end">
                                    <IonButton shape="round" className="ion-float-end">Send Announcement</IonButton>
                                </Link>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <IonGrid>
                        <IonRow>
                            <IonCol size="5"  >
                                <IonText>
                                    <b>
                                    Customers
                                    </b>
                                </IonText>
                            </IonCol>
                            <IonCol size="3" className='ion-text-center'>
                                <IonText>
                                <b>
                                    Orders
                                </b>
                                </IonText>
                            </IonCol>
                            <IonCol size="3" className='ion-text-center'>
                                <IonText >
                                <b>
                                    Contact
                                </b>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    {
                        customersLoading === false && customers && customersArray && customersArray.length !==0 ? 
                        customersArray.map((value: any)=>{
                            return (
                                <IonItem key={value.id}>
                                    <IonGrid className="ion-no-padding">
                                        <IonRow className="ion-align-items-center">
                                            <IonCol size="5">
                                                <IonText>
                                                    {value.name}
                                                </IonText>
                                            </IonCol>
                                            <IonCol size="3" className='ion-text-center'>
                                                <IonText  >
                                                    {value.orders}
                                                </IonText>
                                            </IonCol>
                                            <IonCol size='2' className='ion-text-center'>
                                            <IonButton  fill ='clear' color='secondary' href={value.whatsapp_link}  target="_blank" >
                                                <IonIcon slot="icon-only"  icon={whatsappIcon}  ></IonIcon> 
                                            </IonButton>
                                            </IonCol>
                                            <IonCol size='1' className='ion-text-center' >
                                            <IonButton  fill ='clear' color='secondary' href={ value.calling_link }  target="_blank"  >
                                                <IonIcon slot="icon-only"  icon={phoneIcon}  ></IonIcon> 
                                            </IonButton>
                                                {/* <IonIcon size="large" icon={phoneIcon}></IonIcon> */}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonItem>
                            );
                        }) : null
                    }
                    <IonLoading isOpen={customersLoading === true} message="Please Wait"></IonLoading>
                    <IonToast
                        isOpen={customersLoading === false && customers.data && customers.data.length === 0}
                        message="No customer found"
                        duration={500} animated={true}
                    />
                    <IonInfiniteScroll disabled={disableInfiniteScroll} onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                        <IonInfiniteScrollContent
                            loadingText="Loading more customers...">
                        </IonInfiniteScrollContent>
				    </IonInfiniteScroll>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default Customer
