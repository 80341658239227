import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IonContent, IonLabel, IonText, IonLoading, IonItem, IonInput, IonButton, IonPage, IonRow, IonGrid, IonCol, IonIcon, IonCardContent, IonCard, IonAlert } from '@ionic/react';
import {getAccessToken} from '../../services/auth/userLocalStorage';
import {
    eye as eyeIcon,
    create as createIcon,
    trash as trashIcon
} from 'ionicons/icons';
import { deletePage, getPages } from '../../actions/pageActions';
import Header from '../../components/dashboard/Header';

const PageListing = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState<any>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentUser } = useSelector((state : any)=> state.currentUser);
    const { createPageLoading, createPageMessage, createPageError } = useSelector((state : any)=> state.createPageData);
    const { getPagesLoading, pages, getPagesError } = useSelector((state : any)=> state.pages);
    const { deletePageLoading, deletePageMessage, deletePageError } = useSelector((state : any)=> state.deletePageData);
    const { updatePageLoading, updatePageMessage, updatePageError } = useSelector((state : any)=> state.editPageData);

    useEffect(() => {
        if(currentUser && currentUser.data){
            dispatch(getPages(currentUser.data[0].store_id, getAccessToken()));
        }
    }, [currentUser]);

    useEffect(() => {
        if(createPageLoading === false && createPageMessage && createPageMessage.data ){
            dispatch(getPages(currentUser.data[0].store_id, getAccessToken()));
        }
    }, [createPageLoading, createPageMessage]);

    useEffect(() => {
        if(updatePageLoading === false && updatePageMessage && updatePageMessage.data ){
            dispatch(getPages(currentUser.data[0].store_id, getAccessToken()));
        }
    }, [updatePageLoading, updatePageMessage]);

    useEffect(() => {
        if(deletePageLoading === false && deletePageMessage && deletePageMessage.message ){
            dispatch(getPages(currentUser.data[0].store_id, getAccessToken()));
        }
    }, [deletePageLoading, deletePageMessage]);

    const deletePageFunction = async(pageId)=>{
        if(currentUser && currentUser.data){
            await dispatch(deletePage(pageId, currentUser.data[0].store_id, getAccessToken()));
        }
    }

    return (
        <IonPage>
            <div className="ion-page" id="main-content">
                <Header name="Pages"/>
                <IonContent>
                    {getPagesLoading === false && pages ? 
                        <IonGrid className='ion-padding' style={{backgroundColor: 'rgba(250,250,250,.8)'}}>
                        <IonRow className='ionrow-style' style={{backgroundColor: 'white', padding: 10+'px'}}>
                            <IonCol className='ioncol-style'><b>Title</b></IonCol>
                            <IonCol className='ioncol-style'><b>Status</b></IonCol>
                            <IonCol><b>Preview</b></IonCol>
                        </IonRow>
                        {pages.map((element)=>{
                            return(
                                <IonRow key={element.id} className='ionrow-style ion-align-items-center'>
                                    <IonCol className='ioncol-style'>{element.name}</IonCol>
                                    <IonCol className='ioncol-style'>{`${element.status === 'ACTIVE' ? 'Published' : 'Draft'}`}</IonCol>
                                    <IonCol><a href={currentUser && currentUser.data ? `https://${currentUser.data[0].store[28]}/${element.slug}` : ''} target='_blank'><IonButton shape='round' size='small'><IonIcon icon={eyeIcon}/></IonButton></a> <Link to={{ pathname: `/my/pages/update/${element.id}`}}>
                                        <IonButton shape='round' size='small'><IonIcon icon={createIcon}/> </IonButton>
                                    </Link> <IonButton shape='round' size='small' color='danger' onClick={(e)=> {setAlertData(element.id); setShowAlert(true)}}><IonIcon icon={trashIcon}/></IonButton></IonCol>
                                </IonRow>
                            )
                        })}
                        </IonGrid>
                    : ""}
                    <div className="ion-text-center">
                        <IonButton shape="round" routerLink="/my/pages/create">Add new page</IonButton>
                    </div>
                    <IonAlert
                        isOpen={showAlert === true}
                        onDidDismiss={() => setShowAlert(false)}
                        header={'Delete Page'}
                        message={`Are you sure?`}
                        buttons={[
                            {
                                text: 'CANCEL',
                                role: 'cancel'
                            },
                            {
                                text: 'DELETE',
                                handler: () => { deletePageFunction(alertData); }
                            }
                        ]}
                    />
                    <IonLoading isOpen={getPagesLoading === true 
                    || deletePageLoading === true} message="Please wait"></IonLoading>
                </IonContent>
            </div>
        </IonPage>
    )
}

export default PageListing
